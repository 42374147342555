import React, { useEffect, useState, useRef } from 'react';

import { Grid, MenuItem, Button, IconButton } from '@material-ui/core';

import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { ArrowRightAltOutlined, CloseOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { format, addDays, subDays } from 'date-fns';
import { isReal, ClearString } from '@utils/Masks';


import ButtonComponent from '@components/Shared/Buttons/Button';
import ButtonWithIcon from '@components/Shared/Buttons/ButtonWithIcon';

import InvisibleContent from '@components/Shared/InvisibleContent';

import { mdiArrowTopRight } from '@mdi/js';
import Icon from '@mdi/react';

import Service, {
  IPerfil,
  IRequestSolicitacao,
  ITipoSolicitacao,
  ICampanha,
} from './services';

interface IProps {
  perfil_id: number;
  tipo_perfil_descricao: string;
  finishCreate?: Function;
  functionReload?: Function;
  //tiposPerfil: string[];
  Cancel: Function;
}

const DATA_EMAIL = addDays(new Date(), 7).toISOString().split('T')[0];
const DATA_CORREIO = addDays(new Date(), 30).toISOString().split('T')[0];



const SolicitacaoComponent: React.FC<IProps> = ({
  perfil_id,
  tipo_perfil_descricao,
  finishCreate,
  Cancel,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingCreate, setLoadingCreate] = useState(false);

  const [tiposSolicitacao, setTiposSolicitacao] = useState<ITipoSolicitacao[]>(
    [],
  );
  const [campanhas, setCampanhas] = useState<ICampanha[]>([] || null);

  const [tipoSolicitacaoSelecionada, setTipoSolicitacaoSelecionada] =
    useState('');

  const [loading, setLoading] = useState(true);
  const [loadingCreateSolicitacao, setLoadingCreateSolicitacao] =
    useState(false);

    const [valorModifyValor, setValorModifyValor] =
    useState<string>('');

  useEffect(() => {
    const init = async () => {
      const service = new Service();

      const tipoSolicitacaoResponse = await service.loadTiposSolicitacao(
        perfil_id,
      );
      if (!tipoSolicitacaoResponse.error) {
        setTiposSolicitacao(tipoSolicitacaoResponse.response);
      }
    };

    init();
    setLoading(false);
    loadCampanhas();
  }, []);

  function handleCancel() {
    Cancel();
  }

  async function loadCampanhas() {
    const services = new Service();

    const { error, response } = await services.getCampanhas();
    if (!error && response?.length) {
      setCampanhas(response);
    }
  }

  const filterCheckTipoFormContribuicao = (id: any) => {
    const forma = tiposSolicitacao.filter(x => x.id === id);

    if (forma.length > 0) setTipoSolicitacaoSelecionada(forma[0].descricao);
  };

  async function onSubmit(values: any) {
    setLoadingCreate(true);

    console.log(values);

    const createSolicitacao: IRequestSolicitacao = {
      id_perfil: perfil_id,
      id_tipo_solicitacao: values.id_tipo_solicitacao,
      observacoes: values.observacoes,
      valor: ClearString(valorModifyValor) / 100,
      data_vencimento: values.data_vencimento,
      id_campanha: values.id_campanha,
    };

    const service = new Service();
    const { error, response } = await service.CreateSolicitacao(
      createSolicitacao,
    );

    setLoadingCreate(false);

    if (!error) {
      enqueueSnackbar(`Cadastro finalizado com sucesso!`, {
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateSolicitacao(false);
      if (finishCreate) {
        finishCreate();
      }
    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingCreateSolicitacao(false);
    }
  }

  return (
    <Grid container>
      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingCreate}
          tip={loadingCreate ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Form
              form={formRef}
              scrollToFirstError={true}
              onFinish={onSubmit}
              requiredMark={false}
              style={{ width: '100%' }}
              layout="vertical"
              initialValues={{
                //qtd_cadastros: totalItens,
                //data_vencimento: format(addDays(new Date(), +7), 'yyyy-MM-dd'),
              }}
            >
              <Grid item lg={12} xs={12}>
                <Form.Item
                  label="Perfil atual:"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    disabled
                    name="id_perfil"
                    placeholder="Tipo de perfil"
                    value={tipo_perfil_descricao}
                  />
                </Form.Item>
              </Grid>
              <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                <Form.Item
                  name="id_tipo_solicitacao"
                  label="Tipo de solicitação: "
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <SelectAntd
                    placeholder="Tipo de solicitação"
                    style={{ width: '100%' }}
                    onSelect={(e: any) => filterCheckTipoFormContribuicao(e)}
                  >
                    {tiposSolicitacao.map((option, index) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.descricao}
                      </MenuItem>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
              <InvisibleContent
                visible={tipoSolicitacaoSelecionada
                  .toLocaleUpperCase()
                  .includes('BOLETO')}
              >
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <Form.Item required name="valor" label="Valor">
                      <Input
                        placeholder="Valor"
                        onChange={(e: any) => {
                          formRef.setFieldsValue({
                            valor: isReal(e.target.value),
                          });
                          setValorModifyValor(e.target.value);
                        }} />
                    </Form.Item>
                  </Grid>

                  <InvisibleContent
                    visible={tipoSolicitacaoSelecionada
                      .toLocaleUpperCase()
                      .includes('EMAIL')}
                  >

                    <Grid item sm={6} xs={12}>
                      <Form.Item name="data_vencimento" label="Vencimento"
                        rules={[
                          {
                            required: true,
                            message: 'Selecione um vencimento',
                          },
                        ]}
                      >
                        <Input type="date" placeholder="Data de Vencimento" min={DATA_EMAIL} max={DATA_EMAIL}/>
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>

                  <InvisibleContent
                    visible={tipoSolicitacaoSelecionada
                      .toLocaleUpperCase()
                      .includes('CORREIO')}
                  >

                    <Grid item sm={6} xs={12}>
                      <Form.Item name="data_vencimento" label="Vencimento"
                        rules={[
                          {
                            required: true,
                            message: 'Selecione um vencimento',
                          },
                        ]}
                      >
                        <Input type="date" placeholder="Data de Vencimento" min={DATA_CORREIO} max={DATA_CORREIO}/>
                      </Form.Item>
                    </Grid>
                  </InvisibleContent>

                </Grid>
              </InvisibleContent>

              <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                <Form.Item
                  name="id_campanha"
                  label="Campanha: "
                  rules={[
                    {
                    required: true,
                    message: "Por favor, insira uma campanha"
                    },
                    ]}
                >
                  <SelectAntd placeholder="Campanha" style={{ width: '100%' }}>
                    {campanhas.map((option, index) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.descricao}
                      </MenuItem>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>

              <Grid item lg={12} xs={12}>
                <Form.Item label="Observação:" name="observacoes">
                  <Input name="observacoes" placeholder="Observação" />
                </Form.Item>
              </Grid>

              <Grid
                container
                justify="center"
                alignItems="center"
                item
                md={12}
                xs={12}
              >
                <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="default"
                    fullWidth
                    disabled={loadingCreateSolicitacao}
                    loading={loadingCreateSolicitacao}
                    onClick={handleCancel}
                  >
                    <CloseOutlined style={{ marginRight: 10 }} /> Cancelar
                  </ButtonComponent>
                </Grid>

                <Grid item md={1} xs={12} style={{ paddingTop: 10 }}></Grid>

                <Grid item md={5} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonWithIcon
                    color="primary"
                    disabled={loadingCreateSolicitacao}
                    loading={loadingCreateSolicitacao}
                    fullWidth
                    type="submit"
                    titleButton="Solicitar"
                    Icon={() => (
                      <Icon path={mdiArrowTopRight} size={1} color="White" />
                    )}
                  ></ButtonWithIcon>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default SolicitacaoComponent;
