import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { Empty } from 'antd';
import '@styles/AntStyles.css';

import {
  DivLoading,
  SpinnerLoadingMaterial,
  StyledTableCell,
  StyledTableRow,
  useStyles,
  Container,
  DivVoid,
} from './styles';
import { IconButton } from '@material-ui/core';
import { ForwardToInbox } from '@assets/Icons/';
import { PrintOutlined, CloudDownloadOutlined } from '@material-ui/icons';
import { mdiMicrosoftExcel    } from '@mdi/js';
import Icon from '@mdi/react';


interface ITableProps {
  data: any;
  columns: Object[];
  loading: Boolean;
  Actions?: IActionObj[];
  Selected?: Function;
  FieldsBold?: string[];
  keys: string[];
  handleDownloadArquivoGrafica: Function;
  handleDownloadArquivoCSV: Function;
  ActionHeader?: string;

}

interface IActionObj {
  Button: Function;
}

const TableActions: React.FC<ITableProps> = ({
  keys,
  data,
  columns,
  loading,
  Selected,
  FieldsBold,
  ActionHeader,
  Actions,
  handleDownloadArquivoGrafica,
  handleDownloadArquivoCSV,
}) => {
  const classes = useStyles();
  // const keys = Object.keys(data[0] || data);
  const alterColumns = columns[0] || {};

  const Head = ({ keys, alterColumns }: any) => {
    return (
      <>
        {Selected && (
          <StyledTableCell align="center">Selecionar</StyledTableCell>
        )}

        {keys.map((key: any) => (
          <StyledTableCell key={key} align="left">
            {alterColumns[key] || key}
          </StyledTableCell>
        ))}

        {Actions && <StyledTableCell align="center">{ActionHeader !== null ? ActionHeader : "Opções"}</StyledTableCell>}
      </>
    );
  };

  const Rows = ({ row }: any) => {
    const colunas = Object.keys(row);
    return (
      <StyledTableRow>
        {Selected && (
          <StyledTableCell>
            <div>{Selected(row)}</div>
          </StyledTableCell>
        )}
        {colunas.map(coluna => {
          if (!keys.includes(coluna)) {
            return;
          } else {
            return (
              <StyledTableCell key={coluna} align="left">
                {FieldsBold?.includes(coluna) ? (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <strong
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </strong>
                </div>
              ) : (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </p>
                </div>
              )}

              {coluna === 'link_remessa' && (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <IconButton
                      key={row[coluna]}
                      onClick={() =>
                        handleDownloadArquivoGrafica( row['arquivoId'])
                        }
                    >
                      <CloudDownloadOutlined />
                    </IconButton>



                  </p>
                </div>
              )}

              {coluna === 'link_csv' && (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <IconButton
                      key={row[coluna]}
                      onClick={() =>
                        handleDownloadArquivoCSV( row['arquivoId'])
                        }
                    >
                     <Icon path={mdiMicrosoftExcel  } size={1} />
                    </IconButton>



                  </p>
                </div>
              )}
              </StyledTableCell>
            );
          }
        })}

      {Actions && (
          <StyledTableCell
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {Actions?.map((Item, index) => (
              <div key={index}>{Item.Button(row)}</div>
            ))}
          </StyledTableCell>
        )}


      </StyledTableRow>
    );
  };

  return (
    <Container>
      {loading && (
        <DivLoading>
          <SpinnerLoadingMaterial />
        </DivLoading>
      )}

      {!loading && data.length <= 0 && (
        <DivVoid>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum dado encontrado"
          />
        </DivVoid>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader size="medium">
          {!loading && (
            <TableHead>
              <TableRow>
                <Head keys={keys} alterColumns={alterColumns} />
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, index: number) => {
              return <Rows key={index} row={row} />;
            })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableActions;
