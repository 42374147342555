import api from '@http/api';

export interface ITiposAcoes {
  tipo_acao_id: number;
  tipo_acao_descricao: string;
  tipo_acao_adm_config_status: boolean;
  tipo_acao_config_liberar_cliente: boolean;
}

export interface IEstrategias {
  id: number;
  nome: string;
  campanha_id: number;
  descricao: string;
  data_cadastro: any;
  data_inicio_estrategia: any;
  data_fim_estrategia: any;
  quantidade_perfis: number;
  status_ativo: number;
}

export interface IEstrategiaAcao {
  tipo_acao_id: number;
  tipo_acao_descricao: string;
}

export interface IResponseGetEstrategia {
  estrategia: IEstrategias;
}

export interface IResponseGetEstrategias {
  error: boolean;
  response?: IEstrategias;
}

interface IResponse {
  error: boolean;
  response?: ITiposAcoes[];
}

class ConfigEstrategiasServices {
  public async getTiposAcoes(estrategiaId: number): Promise<IResponse> {
    return await api
      .get<ITiposAcoes[]>(
        `api/TipoAcao/GetTiposAcoesByEstrategiaId?estrategiaId=${estrategiaId}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getEstrategia(id: number): Promise<any> {
    return await api
      .get(`api/Estrategia/GetEstrategiaResumidaById?id=${id}`)
      .then(response => {
        return {
          error: false,
          response: { estrategia: response.data },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createListaLigacao(id_estrategia: number): Promise<any> {
    return await api
      .post('/api/Telefonia/CreateListaLigacaoEstrategiaAcao', {
        id_estrategia,
      })
      .then(response => {
        return {
          error: false,
          response: { message: 'ok' },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ConfigEstrategiasServices;
