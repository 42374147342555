import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, IconButton } from '@material-ui/core';

import TableCustomKeysNoPagination from '@src/components/Shared/Tables/TableCustomKeysNoPagination';
import { TitlePage, SubTitleBold } from '@styles/styles.global';
import Services, { IDetalhesDebitoTable } from './services';

import * as S from './styles';
import { Visibility } from '@material-ui/icons';

import { useAuth } from '@hooks/AuthenticateContext';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DetalhesRemessa: React.FC = () => {
  const query = useQuery();

  const [data, setData] = useState('');

  const [codigoMovimento, setCodigoMovimento] = useState('');
  const [convenioDescricao, setConvenioDescricao] = useState('');
  const [loading, setLoading] = useState(false);
  const [detalhesDebito, setDetalhesDebito] = useState<IDetalhesDebitoTable[]>(
    [],
  );

  const handleDetalhesDebito = async (
    dataSelecionada: string,
    codigoMovimento: string | number,
    convenio: string | number,
  ) => {
    const dataSplit = dataSelecionada.split('/');

    const service = new Services();

    const { error, response } = await service.DetalhesDebito(
      1,
      1,
      codigoMovimento,
      convenio,
      `${dataSplit[1]}/${dataSplit[0]}/${dataSplit[2]}`,
      // dataSelecionada,
    );

    if (!error && response) {
      setDetalhesDebito(response);
    }
  };

  useEffect(() => {
    const getParams = query.get('params');
    if (getParams) {
      const { data, codigoMovimento, convenio } = JSON.parse(getParams);
      console.log({
        data,
        codigoMovimento,
        convenio,
      });

      setConvenioDescricao(convenio.descricao);
      setData(data.data);
      setCodigoMovimento(codigoMovimento.descricao);

      handleDetalhesDebito(data.data, codigoMovimento.id, convenio.id);
    }
  }, []);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };
  
        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };
  
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
  
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <S.Container>
      <TitlePage style={{ marginBottom: 30 }}>
        Detalhes de remessa
      </TitlePage>
      <div style={{ marginBottom: 30 }}>
        <p style={{ fontSize: 16, fontWeight: 'bold' }}>Data: {data}</p>

        <p style={{ fontSize: 16, fontWeight: 'bold' }}>
          Tipo Remessa : {codigoMovimento}
        </p>

        <p style={{ fontSize: 16, fontWeight: 'bold' }}>
          Convenio : {convenioDescricao}
        </p>
      </div>

      <Grid container>
        <Grid item lg={8} xs={12}>
          <TableCustomKeysNoPagination
            data={detalhesDebito}
            keys={['Nome', 'documento', 'tipo_perfil', 'valor', 'data']}
            loading={loading}
            columns={[
              {
                Nome: 'Nome',
                documento: 'Documento',
                valor: 'Valor',
                tipo_perfil: 'Tipo Perfil',
                data: 'Data',
              },
            ]}
            Actions={[
              {
                Button: (row: IDetalhesDebitoTable) => {
                  return (
                    <IconButton
                      onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </S.Container>
  );
};

export default DetalhesRemessa;
