import api from '@http/api';


export interface ISolicitacoes {
  modelo: ISolicitacaoResultTable[];
  IDSolicitacao: number;
  Data: string;
  IDCadastro: number;
  NomeCadastro: string;
  Campanha: string;
  Perfil: string;
  SolicitadoPor: string;
  Banco: string;
  Observacao:[{
      coluna: string;
      valor: string;
  }]
}

export interface ISolicitacaoResultTable {
  IDSolicitacao: string;
}

export interface ITipoSolicitacao {
    id: number;
    descricao: string;
  }

  interface IResponseTipoSolicitacao {
    error: boolean;
    response?: ITipoSolicitacao[];
  }

  interface IRequestData {
    id_tipo_solicitacao?: number;
  }

  interface IResponseLoadSolicitacoesTipoSolicitacao {
    error: boolean;
    response?: ISolicitacoes[];
  }


class Services {

  public async getTipoSolicitacao(): Promise<IResponseTipoSolicitacao> {
    return await api
      .get(`/api/Solicitacao/getAllTipoSolicitacao`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadTipoSolicitacao({
    id_tipo_solicitacao,
  }: IRequestData): Promise<IResponseLoadSolicitacoesTipoSolicitacao> {
    return await api
      .get(`/api/Solicitacao/GetSolicitacoesByTipoSolicitacao/${id_tipo_solicitacao}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

}


export default Services;
