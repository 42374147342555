import React, { useLayoutEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { CloseOutlined } from '@material-ui/icons';
import { Grid, useMediaQuery } from '@material-ui/core';
import { getColors } from '@styles/colorsTheme';

interface IPropsModal {
  size: 'pequeno' | 'medio' | 'grande' | 'gigante';
  openModal: boolean;
  controlModal: Function;
  title: string;
  closeOutsideClick?: boolean;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SimpleModal: React.FC<IPropsModal> = ({
  children,
  size,
  controlModal,
  openModal,
  title,
  closeOutsideClick,
}) => {
  const [sizeModal, setSizeModal] = useState('');
  const matches = useMediaQuery('(max-width: 800px)');

  useLayoutEffect(() => {
    if (matches) {
      setSizeModal('100%');
    } else {
      if (size === 'gigante') {
        setSizeModal('80%');
      } else if (size === 'grande') {
        setSizeModal('60%');
      } else if (size === 'pequeno') {
        setSizeModal('30%');
      } else {
        setSizeModal('45%');
      }
    }
  }, [matches, size]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        width: sizeModal,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
        maxHeight: 500,
        overflowY: 'scroll',
      },
    }),
  );

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  function handleModalClose() {
    if (!closeOutsideClick) {
      return;
    }
    controlModal(false);
  }

  return (
    <>
      <Modal
        open={openModal}
        // onClose={handleModalClose}
        disableEscapeKeyDown={true}
        onClose={() => {
          handleModalClose();
        }}
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item sm={12} style={{ textAlign: 'end' }}>
              <button
                style={{ border: 'none', backgroundColor: getColors()?.branco }}
                onClick={() => {
                  controlModal(false);
                }}
              >
                <CloseOutlined
                  fontSize="large"
                  htmlColor={getColors()?.azulEscuro}
                />
              </button>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              paddingLeft: '10%',
              paddingRight: '10%',
              paddingBottom: 30,
            }}
          >
            <Grid item lg={12} xs={12} sm={12}>
              {' '}
              <h3>{title}</h3>
            </Grid>
            <Grid item lg={12} xs={12} sm={12}>
              {children}
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};

export default SimpleModal;
