import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { Empty } from 'antd';
import '@styles/AntStyles.css';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import {
  DivLoading,
  SpinnerLoadingMaterial,
  StyledTableCell,
  StyledTableCell2,
  StyledTableCell1,
  StyledTableCell3,
  StyledTableRow,
  useStyles,
  Container,
  DivVoid,
  StyledTableRow2,
} from './styles';
import { Box, Collapse, IconButton } from '@material-ui/core';

interface ITableProps {
  data: object[];
  columns: Object[];
  loading: Boolean;
  ActionHeader?: string;
  Actions?: IActionObj[];
  Selected?: Function;
  FieldsBold?: string[];
  keys: string[];
}

interface IActionObj {
  Button: Function;
}

const TableColapse: React.FC<ITableProps> = ({
  data,
  columns,
  loading,
  Actions,
  ActionHeader,
  Selected,
  FieldsBold,
  keys,
}) => {
  const classes = useStyles();
  // const keys = Object.keys(data[0] || data);
  const alterColumns = columns[0] || {};

  const Head = ({ keys, alterColumns }: any) => {
    return (
      <>
        {Selected && (
          <StyledTableCell align="center">Selecionar</StyledTableCell>
        )}

        {keys.map((key: any) => (
          <StyledTableCell key={key} align="center">
            {alterColumns[key] || key}
          </StyledTableCell>
        ))}

        {Actions && (
          <StyledTableCell align="center">
            {ActionHeader !== null ? ActionHeader : 'Opções'}
          </StyledTableCell>
        )}
      </>
    );
  };

  const Rows = ({ row }: any) => {
    const colunas = Object.keys(row);
    const [open, setOpen] = React.useState(false);

    return (
      <>
        <StyledTableRow>
          {Selected && (
            <StyledTableCell>
              <div>{Selected(row)}</div>
            </StyledTableCell>
          )}

          <StyledTableCell> {row.Data} </StyledTableCell>
          <StyledTableCell> {row.IDCadastro} </StyledTableCell>
          <StyledTableCell> <strong> {row.NomeCadastro}</strong> </StyledTableCell>
          <StyledTableCell> <strong> {row.Perfil} </strong></StyledTableCell>
          <StyledTableCell> {row.Campanha} </StyledTableCell>
          <StyledTableCell> {row.SolicitadoPor} </StyledTableCell>
          <StyledTableCell> {row.Banco} </StyledTableCell>
          <StyledTableCell1>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </StyledTableCell1>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
            colSpan={7}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table className={classes.table}>
                <TableBody>
                  {row.Observacao.map((observacao: any) => (
                    <StyledTableRow2 key={observacao.coluna}>
                      <StyledTableCell2>{observacao.coluna}</StyledTableCell2>
                      <StyledTableCell3>{observacao.valor}</StyledTableCell3>
                    </StyledTableRow2>
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  };

  return (
    <Container>
      {loading && (
        <DivLoading>
          <SpinnerLoadingMaterial />
        </DivLoading>
      )}

      {loading && data.length <= 0 && (
        <DivVoid>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum dado encontrado"
          />
        </DivVoid>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader size="medium">
          {!loading && (
            <TableHead>
              <TableRow>
                <Head keys={keys} alterColumns={alterColumns} />
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, index: number) => {
              return <Rows key={index} row={row} />;
            })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableColapse;
