import api from '@http/api';

export interface IApproveSolicitacao {
  id_solicitacao: number[];
}

class ComponentSolicitacaoServices {
  public async ApproveSolicitacao({ id_solicitacao }: IApproveSolicitacao) {
    return await api
      .patch('/api/Solicitacao/AutorizarSolicitacao', {
        id_solicitacao,
      })

      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ComponentSolicitacaoServices;
