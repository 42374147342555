import React, { useEffect, useState } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Select as SelectAntd, Form, Upload, Input } from 'antd';
import { TitlePage } from '@styles/styles.global';
import ButtonComponent from '@components/Shared/Buttons/Button';
import { FindInPageOutlined, CheckCircleOutlined } from '@material-ui/icons';
import DatePicker2 from '@components/Shared/DatePicker2';
import { addDays } from 'date-fns';
import { useSnackbar } from 'notistack';
import { Spin, Space } from 'antd';

import TableCustomKeysNoPagination from '@components/Shared/Tables/TableCustomKeysNoPagination';

import Services, {
  IBanco,
  IResultImporte,

} from './services';

import * as S from './styles';

import { useAuth } from '@hooks/AuthenticateContext';

const DATA_NORMAL_DATA = addDays(new Date(), 0);

const ImportarRetorno: React.FC = () => {

  const columnsLabel = {
    //id: 'ID',
    data_importe: 'Importado em',
    arquivo: 'Nome arquivo',
    sequencial_arquivo: 'Número sequencial',
    banco: 'Banco',
    total_registro: 'Total de registro',
    usuario_importe: 'Importado por',
  };

  const [formRef] = Form.useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [bancos, setBancos] = useState<IBanco[]>([]);
  const [importe, setImporte] = useState<IResultImporte[]>([]);
  const [rangeData, setRangeData] = useState<Date[]>([
    DATA_NORMAL_DATA,
    DATA_NORMAL_DATA,
  ]);

  const onFinish = async (values: any) => {
    setLoading(true);

    const services = new Services();

    const extensionFileOne = values.upload[0].name.slice(
      ((values.upload[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
    );

    // if (
    //   extensionFileOne.length === 0 ||
    //   extensionFileOne.toLowerCase() !== 'ret' ||
    //   extensionFileOne.toLowerCase() !== 'old' ||
    //   extensionFileOne.toLowerCase() !== 'icx'
    // ) {
    //   setLoading(false);
    //   alert(
    //     'Extensão do arquivo inválida, extensões permitidas .ret, .old, .icx',
    //   );
    //   return;
    // }

    let formData = new FormData();
    formData.append('ArquivoRetorno', values.upload[0].originFileObj);

    const { error, response } = await services.ImportRetorno(formData);

    if (error) {
      enqueueSnackbar(`Ocorreu um erro no importe`, {
        title: 'Ocorreu um erro no importe',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
      return;
     } else {
      enqueueSnackbar(`Importe concluído com sucesso`, {
        title: 'Importe concluído com sucesso',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(true);
      //alert('Importe concluído com sucesso');
    }

    window.location.reload();
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

    const handleChangeDates = (dates: Date[]) => {
      setRangeData(dates);
      // setDatasSelecionadas
      loadImporte(undefined, dates);
    };


  const firstLoadDebitos = async () => {
    const bancoId = await loadBancos();

    await loadImporte(bancoId);
  };

  async function loadBancos() {
    const services = new Services();
    const { error, response } = await services.getBancos();

    if (!error && response) {
      setBancos(response);
      formRef.setFieldsValue({ banco: response[0]?.id });

      return response[0]?.id;
    } else {

      formRef.setFieldsValue({ banco: null });
      return 0;
    }
  };

  const loadImporte = async (
    idBanco?: number,
    rangeDatas?: Date[],
  ) => {
    setLoading(true);

    const services = new Services();

    const { error, response } = await services.getListImporte(

      rangeDatas
        ? `${rangeDatas[0].toLocaleDateString('pt-BR')}`
        : `${rangeData[0].toLocaleDateString('pt-BR')}`,
      rangeDatas
        ? `${rangeDatas[1].toLocaleDateString('pt-BR')}`
        : `${rangeData[1].toLocaleDateString('pt-BR')}`,

        idBanco ? idBanco : formRef.getFieldValue('banco'),
    );

    if (!error) {
      setImporte(response?.modelo || []);
    }

    setLoading(false);
  };

  useEffect(() => {
    window.document.title = 'CRM - Importar Retorno';
    setLoading(true);
    firstLoadDebitos();
    setLoading(false)
  }, []);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };
  
        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };
  
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
  
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <S.Container>
      <TitlePage> Importar retorno</TitlePage>

      {/* <Grid style={{}}>
        {formasContribuicao.length > 0 &&
          formasContribuicao.map(forma => {
            if (
              forma.descricao.toLowerCase().includes(DESCRICAO_BOLETO) ||
              forma.descricao.toLowerCase().includes(DESCRICAO_DEBITO)
            ) {
              return (
                <ButtonComponent
                  key={forma.id}
                  style={{ margin: 5 }}
                  color={
                    formaContribuicaoSelected != forma.id
                      ? 'default'
                      : 'secondary'
                  }
                  disabledColor="#ccc"
                  onClick={() => {
                    setFormaContruibuicaoSelected(Number(forma.id));
                  }}
                >
                  {forma.descricao.toLowerCase()}
                </ButtonComponent>
              );
            }
          })}
        </Grid>*/}

      <Form
        onFinish={onFinish}
        className="teste2 "
        layout={'vertical'}
        form={formRef}
      >
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item md={4} lg={4} xs={12} >
            <Form.Item
              name="upload"
              //label="Arquivo retorno: "
              valuePropName="fileList"
              getValueFromEvent={normFile}
              extra=""
              rules={[
                {
                  required: true,
                  message: 'Selecione um arquivo',
                },
              ]}
            >
              <Upload maxCount={1} beforeUpload={() => false} listType="text">
                <ButtonComponent color={'secondary'} fullWidth>
                  <FindInPageOutlined style={{ paddingRight: 5 }} /> Selecionar
                  arquivo
                </ButtonComponent>
              </Upload>
            </Form.Item>
          </Grid>

          <Grid item lg={2} xs={12}>
            <Spin size="large" spinning={loading} tip={'Loading...'}>
              <ButtonComponent
                type="submit"
                color={'secondary'}
                loading={loading}
                fullWidth
              >
                <CheckCircleOutlined style={{ paddingRight: 3 }}/>
                Importar
              </ButtonComponent>
              </Spin>
          </Grid>

        </Grid>



         <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item lg={3}  style={{ padding: 5 }}>
              <DatePicker2
                cleanable={false}
                notUseDefaultValue={false}
                title="Período: "
                color={'secondary'}
                setValor={handleChangeDates}
                placement={'bottomStart'}
                notViewRanges={true}
                rangeInitial={rangeData}
              />
            </Grid>

            <Grid
              item
              lg={3}
              style={{
                padding: 5,
              }}
            >
              <Form.Item name={'banco'}>
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Banco"
                  onChange={(e: string) => {
                    loadImporte();
                  }}
                >
                  {bancos.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao}
                    </SelectAntd.Option>
                  ))}

                </SelectAntd>
              </Form.Item>
            </Grid>
          </Grid>
                    </Form>

      <Grid container>
        <Grid item lg={10}>
          <TableCustomKeysNoPagination
            loading={loading}
            columns={[columnsLabel]}
            data={importe}
            keys={['data_importe', 'arquivo', 'sequencial_arquivo', 'banco', 'total_registro', 'usuario_importe']}
            FieldsBold={['arquivo']}
            Actions={[ /*
              {
                Button: (values: IResultImporte) => {
                  return (
                    <IconButton
                      onClick={() => {
                        const obj = {
                          arquivoId: values,
                        }

                        window.open(
                          `/retorno/detalhes'?params=${JSON.stringify(
                            obj,
                          )}`,
                        );
                      }}
                    >
                      <FindInPageOutlined />
                    </IconButton>
                  );
                },
              },
          */  ]}
          />
        </Grid>
      </Grid>
    </S.Container>
  );
};

export default ImportarRetorno;
