import React, { useState } from 'react';
import Tour from 'reactour';
import { getColors } from '@styles/colorsTheme';
import InfoIcon from '@material-ui/icons/Info';
import Page from './Page';
// import { Container } from './styles';

import { Switch, useDarkreader } from 'react-darkreader';

const steps = [
  {
    selector: '#page-inserir-doacoes-step1',
    content: 'Selecione uma Forma de contribuição',
  },
  {
    selector: '#page-inserir-doacoes-step2',
    content: 'Selecione uma Estratégia',
    // action: () => {
    //   console.log({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step3',
    content: 'Busque por um documento',
    // action: () => {
    //   console.log({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step4',
    content: 'Selecione um Tipo de Perfil',
    // action: () => {
    //   console.log({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step5',
    content: 'Digite o valor da Doação',
    // action: () => {
    //   console.log({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step6',
    content: 'Digite a data da Doação',
    // action: () => {
    //   console.log({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step7',
    content: 'Importar',
    // action: () => {
    //   console.log({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step8',
    content:
      'Confira os dados, se estiver ok clique em confirmar ou Cancelar caso estiver algo errado',
    // action: () => {
    //   alert({ toggle: true });
    // },
  },
  {
    selector: '#page-inserir-doacoes-step9',
    content:
      'Confira os dados, se estiver ok clique em confirmar ou Cancelar caso estiver algo errado',
    // action: () => {
    //   alert({ toggle: true });
    // },
  },
];

const InsercaoDoacoes: React.FC = () => {
  const [isDark, { toggle }] = useDarkreader();
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [startAt, setStartAt] = useState(0);
  const [hover, setHover] = useState(false);

  const nextStep = () => {
    const nextStep = step + 1;
    setStep(nextStep);
    if (nextStep >= steps.length) {
      setIsTourOpen(false);
      setStep(0);
    }
  };

  return (
    <>
      {/* <button
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          // background: hover ? getColors().cinzaEscuro : getColors().cinzaClaro,
          color: hover ? getColors().azulClaro : getColors().azulEscuro,
          width: 50,
          height: 50,
          borderRadius: '50%',
        }}
        onClick={() => {
          toggle();
          localStorage.setItem('@amex:DarkMode', 'false');

          setStep(0);
          setIsTourOpen(!isTourOpen);
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <InfoIcon style={{ width: 50, height: 50 }} />
      </button> */}

      <Tour
        startAt={startAt}
        goToStep={step}
        steps={steps}
        isOpen={isTourOpen}
        className="helper"
        accentColor={'#5cb7b7'}
        onRequestClose={() => setIsTourOpen(false)}
        disableFocusLock={true}
        closeWithMask={false}
        getCurrentStep={stepCurrent => {
          console.log(stepCurrent);
          setStep(stepCurrent);
          if (stepCurrent >= steps.length) {
            setIsTourOpen(false);
            setStep(0);
          }
        }}
        // nextButton={
        //   <button style={{ display: 'none' }} id="_tour_next_btn">
        //     Próximo
        //   </button>
        // }
      />

      <Page nextStep={nextStep} />
    </>
  );
};

export default InsercaoDoacoes;
