import styled from 'styled-components';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { getColors } from '@styles/colorsTheme';

import SpinnerLoading from '@material-ui/core/CircularProgress';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import InputBase from '@material-ui/core/InputBase';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  min-height: 350px;
`;

export const BottomPagination = styled.div`
  display: flex;
  flex: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  flex-wrap: wrap;
`;

export const DivLoading = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 500;
`;

export const DivVoid = styled.div`
  position: absolute;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 500;
`;

export const ItemPaginate = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      fontSize: 16,
      padding: '10px 26px 10px 12px',
    },
  }),
)(InputBase);

export const useStyles = makeStyles({
  table: {
    minWidth: 320,
    maxHeight: 200,
    textAlign: 'left',
  },
  container: {
    minHeight: 350,
    maxHeight: 200,
  },
});

export const SpinnerLoadingMaterial = withStyles((theme: Theme) =>
  createStyles({}),
)(SpinnerLoading);

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: getColors()?.azulClaro,
      color: theme.palette.common.white,
      paddingRight: 10,
      paddingLeft: 10,
      fontSize: 14,
      textAlign: 'left',
    },
    body: {
      fontSize: 14,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'left',
    },
  }),
)(TableCell);

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#f5f6f9',
      },
    },
  }),
)(TableRow);

export const StyledTableRow2 = withStyles((theme: Theme) =>
  createStyles({
    root: {
    
        backgroundColor: '#f1f4f1',
        innerWidth: '100%',
        padding: 0,     
    },
  }),
)(TableRow);

export const StyledTableCell2 = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 13,
      paddingRight: 0,
      textAlign: 'left',
      maxWidth: 10,
      borderColor: 'transparent',

    },
  }),
)(TableCell);

export const StyledTableCell3 = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 13,
      paddingLeft: 0,
      paddingRight: 60,
      textAlign: 'left',
      maxWidth: 10,
      borderColor: 'transparent',
    },
  }),
)(TableCell);
export const StyledTableCell0 = withStyles((theme: Theme) =>
  createStyles({
    body: {
      fontSize: 14,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'center',
    },
  }),
)(TableCell);
