import api from '@http/api';
import { IPerfil } from '@interfaces/UserComplete';

interface IInserirDoacao {
  perfil_id: string;
  estrategia_id: string;
  origem_doacao_id: string;
  valor_doacao: string;
  data_doacao: string;
  observacao: string;
}

export interface IFormaContribuicao {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IEstrategias {
  id: string;
  nome: string;
  campanha_id: string;
  descricao: string;
}

export interface IResponseRequestPessoaByCPF {
  pessoa: {
    id: string;
    documento: string;
    nome: string;
    perfis: IPerfil[];
    tipos_perfis: string[];
  };
}

export interface IResponsePessoaByCPF {
  response?: {
    pessoa: {
      id: string;
      nome: string;
      documento: string;
      perfis: IPerfil[];
      tipos_perfis: string[];
    };
  };
  error: boolean;
}

export interface IResponse {
  response?: IPerfil[];
  error: boolean;
}

class Services {
  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/Doacao/GetOrigensDoacao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadEstrategias() {
    return await api
      .get<IEstrategias[]>('/api/Estrategia/GetEstrategiasFiltradas')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async getPessoaByID(id: number): Promise<IResponsePessoaByCPF> {
    return await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoaByDocumento(
    documento: string,
  ): Promise<IResponsePessoaByCPF> {
    return await api
      .post<IResponseRequestPessoaByCPF>(`api/Pessoa/FindPessoaByCpf`, {
        documento,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async inserirDoacao({
    estrategia_id,
    origem_doacao_id,
    perfil_id,
    valor_doacao,
    data_doacao,
    observacao,
  }: IInserirDoacao): Promise<IResponse> {
    return await api
      .post(`api/Doacao/InserirDoacao`, {
        estrategia_id,
        origem_doacao_id,
        perfil_id,
        valor_doacao,
        data_doacao,
        observacao,
      })
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default Services;
