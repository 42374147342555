import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Visibility } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import Button from '@components/Shared/Buttons/Button';

import { TitlePage } from '@styles/styles.global';
import Tag from '@components/Shared/Tag';
import TableActions from '@components/Shared/Tables/TableActions';

import Services, { ICampanha, IModelo } from './services';
import { FormatDate } from '@utils/formats';
import { Form, Select as SelectAntd } from 'antd';

import { useAuth } from '@hooks/AuthenticateContext';

const columnsLabel = {
  id: 'ID',
  nome: 'Estratégia',
  quantidade_perfis: 'Cadastros',
  data_fim_doacao: 'Encerra em: ',
};

const ListEstrategias: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [status_ativo, setStatus_ativo] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IModelo[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [, setTotalItems] = useState(0);
  const [campanhaSelected, setCampanhaSelected] = useState(0);
  const [campanhas, setCampanhas] = useState<ICampanha[]>([] || null);

  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      let modelo: any = [];

      response.modelo.map((item: any) => {
        modelo.push({
          id: item.id,
          nome: item.nome !== null ? item.nome : 'Estratégia sem nome',
          quantidade_perfis: item.quantidade_perfis,
          data_fim_doacao: FormatDate(item.data_fim_estrategia),
        });
      });

      setData(modelo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  const HandleStatusAtivo = async (value: number) => {
    await setStatus_ativo(value);

    loadEstrategiasCampanhaID(page, itemsPerPage, value, campanhaSelected);
  };

  const loadEstrategias = useCallback(
    async (page: number, itemsPerPage: number, status_ativo: number) => {
      setLoading(true);

      const services = new Services();
      const { error, response } = await services.getEstrategias({
        pageNumber: page,
        pageSize: itemsPerPage,
        status_ativo: status_ativo,
      });
      setValuesOrError(error, response);
    },
    [],
  );

  const loadEstrategiasCampanhaID = useCallback(
    async (
      page: number,
      itemsPerPage: number,
      status_ativo: number,
      campanha_id: number,
    ) => {
      setLoading(true);

      const services = new Services();
      const { error, response } = await services.getEstrategiasCampanha({
        pageNumber: page,
        pageSize: itemsPerPage,
        status_ativo: status_ativo,
        campanha_id: campanha_id,
      });

      setValuesOrError(error, response);
    },
    [],
  );

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setPage(page);
    setItemsPerPage(itemsPage);

    loadEstrategiasCampanhaID(page, itemsPage, status_ativo, campanhaSelected);
  }

  async function loadCampanhas() {
    const services = new Services();

    const { error, response } = await services.getCampanhas();
    if (!error && response?.length) {
      setCampanhas(response);
      setCampanhaSelected(response[0].id);
      loadEstrategiasCampanhaID(
        page,
        itemsPerPage,
        status_ativo,
        response[0].id,
      );
    }
  }

  useEffect(() => {
    // loadEstrategias(page, itemsPerPage, status_ativo);
    loadCampanhas();
    window.document.title = 'CRM - Lista de Estratégias';
  }, []);


  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
  useEffect(() => {
    if (user.role !== 'ADM AMEX') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [user.role]);

  return (
    <Grid container>
      <Grid item md={4} xs={12}>
        <TitlePage style={{ margin: 5 }}>Estratégias</TitlePage>
      </Grid>
      <Grid item md={4} xs={12} style={{ margin: 5 }}>
        <Form.Item label={<label style={{ fontSize: 20 }}>Campanha</label>}>
          <SelectAntd
            style={{ width: '100%' }}
            placeholder="Campanha"
            onChange={(e: number) => {
              setCampanhaSelected(e);
              loadEstrategiasCampanhaID(page, itemsPerPage, status_ativo, e);
            }}
            value={campanhas[0] ? campanhaSelected : 0}
          >
            {campanhas.map((option, index) => (
              <SelectAntd.Option key={option.id} value={option.id}>
                {option.id + ' - ' + option.descricao}
              </SelectAntd.Option>
            ))}
          </SelectAntd>
        </Form.Item>
      </Grid>
      <Grid container>
        <Grid
          item
          sm={2}
          xs={6}
          style={{
            alignSelf: 'center',
            padding: 5,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Button
            color={status_ativo === 1 ? 'secondary' : 'default'}
            style={{ width: '100%' }}
            onClick={() => HandleStatusAtivo(1)}
          >
            Em andamento
          </Button>
        </Grid>
        <Grid
          item
          sm={2}
          xs={6}
          style={{
            alignSelf: 'center',
            padding: 5,
          }}
        >
          <Button
            color={status_ativo === 0 ? 'secondary' : 'default'}
            style={{ width: '100%' }}
            onClick={() => HandleStatusAtivo(0)}
          >
            Finalizadas
          </Button>
        </Grid>
      </Grid>
      <Grid item md={10}></Grid>
      <Grid item xs={12} md={10} style={{ padding: 5 }}>
        <TableActions
          loading={loading}
          FieldsBold={['nome']}
          columns={[columnsLabel]}
          data={data}
          itemsPerPage={itemsPerPage}
          totalPages={totalPages}
          pageCurrent={page}
          fetchData={setPagination}
          Actions={[
            {
              Button: (row: IModelo) => {
                return (
                  <IconButton
                    onClick={() => window.open(`/estrategias/${row.id}/`)}
                  >
                    <Visibility />
                  </IconButton>
                );
              },
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default ListEstrategias;
