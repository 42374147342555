import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useLocation } from 'react-router-dom';

import Modal from '@src/components/Shared/Modal/ModalPure';
import api from '@http/api';
import { IUser, IPerfil } from '@interfaces/UserComplete';
import { MaskCPFeCNPJ } from '@utils/Masks';

import UpdateResponsavel from '@src/Pages/Private/shared/EditCadastro/EditResponsavel';
import UpdateIndicador from '@src/Pages/Private/shared/EditCadastro/EditIndicador';
import DeleteResponsavel from '@src/Pages/Private/shared/EditCadastro/DeleteResponsavel';
import DeleteIndicador from '@src/Pages/Private/shared/EditCadastro/DeleteIndicador';

import { Grid, CircularProgress, Checkbox } from '@material-ui/core';
import { Form, Input, Spin, Radio } from 'antd';

import ButtonComponent from '@components/Shared/Buttons/Button';
import NewComponentPerfil from '@src/components/Shared/Perfil/update';

import { LabelHeaderInput, DivLoading, CustomInput } from './styles';
import { TitlePage } from '@styles/styles.global';

import CreatePerfil from '@src/components/Shared/Perfil/create';

import PageEditCadastroPessoaServices from './services';
import { validaCPFandCNPJ } from '@src/utils/Validators';

import { useAuth } from '@hooks/AuthenticateContext';
import { useLoading } from '@hooks/LoadingContext';
import { SaveOutlined, Visibility, Edit, ClearRounded} from '@material-ui/icons';
import CheckExistsDocumentService from '@src/utils/CheckExistsDocumentService';
import useDebounce from '@src/hooks/useDebounce';
import { LinkRoute } from '../../Cliente/shared/NovoCadastro/styles';
// import FooterTelefonia from '@components/Shared/FooterTelefonia';
import { useFooterTelefonia } from '@hooks/FooterTelefonia';
import { useFooterTelefonia as useFooterTelefoniaSemWebPhone } from '@hooks/FooterTelefoniaSemWebPhone';
import { useFooterTelefonia as useFooterTelefoniaAtibaia } from '@hooks/FooterTelefoniaAtibaia';
import  {useFooterTelefoniaOSID} from '@hooks/FooterTelefoniaOSID';


import InvisibleContent from '@components/Shared/InvisibleContent';

import { isEmptyObject } from '@src/utils/Validators';
import serviceSocket from '@src/utils/SendSocket';
import { format, addDays, subDays } from 'date-fns';


import Service from './services';



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const DATA = addDays(new Date(), 0).toISOString().split('T')[0];

interface Iprops {
  functionReload?: Function;
}

const EditCadastro: React.FC<Iprops> = ({

  functionReload,

}) => {
  const { nameFunction } = useFooterTelefonia();
  const { nameFunction2 } = useFooterTelefoniaSemWebPhone();
  const { nameFunction3 } = useFooterTelefoniaAtibaia();
  const { nameFunction4 } = useFooterTelefoniaOSID();


  const auth = useAuth();
  const query = useQuery();

  const [formRef] = Form.useForm();
  const { removeLoading, setValues } = useLoading();
  const { enqueueSnackbar } = useSnackbar();
  const [perfil, setPerfil] = useState<IPerfil[]>([]);
  const [tiposPerfis, setTiposPerfis] = useState<string[]>([]);
  const { idCadastro }: { idCadastro: string } = useParams();
  const [user, setUser] = useState<IUser>({} as IUser);
  const [user2, setUser2] = useState<IUser>({} as IUser);
  const [testando, setTestando] = useState<IUser[]>([]);
  const [pessoaEdit, setPessoaEdit] = useState(false);
  const [type, setType] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingEditPessoa, setLoadingEditPessoa] = useState(false);
  const [tipoDocumento, setTipoDocumento] = useState<'pf' | 'pj'>('pf');
  const [idExists, setIdExists] = useState(0);
  const [showButtonViewPessoa, setShowButtonViewPessoa] = useState(false);
  const [perfilInit, setPerfilInit] = useState<number | undefined>();
  const [usuarioCadastro, setUsuarioCadastro] = useState('');

  const [dataNasc, setDataNasc] = useState(false);
  const [dataNasc2, setDataNasc2] = useState(false);
  const [dataNasc3, setDataNasc3] = useState(true);



  const [openModalUpdateResponsavel, setOpenModalUpdateResponsavel] = useState(false);
  const [openModalUpdateIndicador, setOpenModalUpdateIndicador] = useState(false);

  const [openModalDeleteResponsavel, setOpenModalDeleteResponsavel] = useState(false);
  const [openModalDeleteIndicador, setOpenModalDeleteIndicador] = useState(false);

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };

  const [queryTelefonia, setQueryTelefonia] = useState<{
    id_ligacao_interacao: number;
    id_estrategia: number;
    estrategia_nome: string;
    id_pessoa: number;
    id_perfil: number;
    id_estrategia_ligacao: number;
    nomeEstrategia: string;
    contatos_perfil: string[];
  } | null>();

  const checkDocument = (documento: string) => {
    const checkExistsDocumentService = new CheckExistsDocumentService();
    checkExistsDocumentService
      .checkDocument(documento)
      .then(({ error, response }) => {
        if (!error && response.id) {
          if (idCadastro === response.id.toString()) {
            return;
          }

          enqueueSnackbar(
            `Já existe uma pessoa cadastrada com esse documento`,
            {
              title: 'Já existe uma pessoa cadastrada com esse documento!',
              variant: 'warning',
              anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            },
          );

          formRef.setFields([
            {
              name: 'documento',
              errors: ['Já existe uma pessoa cadastrada com esse documento'],
            },
          ]);
          setIdExists(response.id);
          setShowButtonViewPessoa(true);
          return;
        }
      });
    setShowButtonViewPessoa(false);
  };

  const debounceCheckDocument = useDebounce(checkDocument, 800);



  async function loadPessoa(id: string) {
    setValues(true);
    await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(async response => {
        setTiposPerfis(response.data.pessoa.tipos_perfis);

        setUser(response.data.pessoa);

        const { typeDocument, valueModify } = MaskCPFeCNPJ(
          response.data.pessoa.documento,
        );


        var date1 = new Date(response.data.pessoa.data_nascimento);
    var date2 = new Date();

    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays < 6574 ){
      setDataNasc2(true);

    }else{
      setDataNasc2(false);
    }

        setTipoDocumento(typeDocument);
        response.data.pessoa.documento = valueModify;

        if (response.data.pessoa.documento_indicador) {
          const values = MaskCPFeCNPJ(response.data.pessoa.documento_indicador);
          response.data.pessoa.documento_indicador = values.valueModify;
        }

        setPerfil(response.data.pessoa.perfis);

        const NomePessoa = response.data.pessoa.nome;
        setUsuarioCadastro(response.data.pessoa.UsuarioCadastro);
        const queryTelefonia = query.get('telefonia');

        if (queryTelefonia !== null) {
          let JSONPARSED = JSON.parse(queryTelefonia) as {
            id_ligacao_interacao: number;

            id_pessoa: number;
          };

          const service = new Service();

          const { error, response } = await service.FindDadosLigacao(
            JSONPARSED.id_ligacao_interacao,
            JSONPARSED.id_pessoa,
          );

          console.log(' teste...', response);

          if (!error && response) {
            if (response.id_perfil !== null) {
              setPerfilInit(
                response.id_perfil > 0 ? response.id_perfil : undefined,
              );
            }

            if (!isEmptyObject(response)) {
              // alert('Enviou socket');
              const socket = new serviceSocket();
              await socket.sendEventSocket({
                eventName: `dados-para-telefonia-${auth.user.id}`,
                message: JSON.stringify({
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa,
                  contatos_perfil: response.contatos_perfil,
                }),
              });
            }
            if (auth.user.telefonia === 'Zenvia') {
              nameFunction({
                example: {
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa,
                  contatos_perfil: response.contatos_perfil,
                },
              });
            } else if (auth.user.telefonia === 'Asterisk') {
              nameFunction3({
                example: {
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa,
                  contatos_perfil: response.contatos_perfil,
                },
              });
            } else if (auth.user.telefonia === 'Leucotron') {
              nameFunction4({
                example: {
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa,
                  contatos_perfil: response.contatos_perfil,
                },
              });
            }
            else {
              console.log('ENVIOU');
              nameFunction2({
                example: {
                  id_ligacao_interacao: response.id_ligacao_interacao,
                  id_estrategia: response.id_estrategia,
                  estrategia_nome: response.estrategia_nome,
                  id_pessoa: response.id_pessoa,
                  id_perfil: response.id_perfil,
                  id_estrategia_ligacao: null,
                  nomeEstrategia: response.estrategia_nome,
                  nomePessoa: NomePessoa,
                  contatos_perfil: response.contatos_perfil,
                },
              });
            }
          }
        } else {
          setQueryTelefonia(null);
        }

        removeLoading();
        setLoading(false);
      })
      .catch(error => {
        console.log(error);

        enqueueSnackbar('Ocorreu um erro.', {
          title: 'Erro de autenticação',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      });
    await formRef.validateFields();
  }

  const validaDocumento = (_: any, val: any) => {
    const documentoExiste = validaCPFandCNPJ(val);

    if (documentoExiste) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Digite um documento válido'));
  };

//Bloqueio inspecionar
useEffect(() => {
  if (auth.user.role !== 'ADM AMEX') {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'F12') {
        event.preventDefault();
        alert('F12 está desativado.');
      }
      if (event.ctrlKey && event.shiftKey && event.key === 'I') {
        event.preventDefault();
        alert('Ctrl+Shift+I está desativado.');
      }
      if (event.ctrlKey && event.shiftKey && event.key === 'J') {
        event.preventDefault();
        alert('Ctrl+Shift+J está desativado.');
      }
      if (event.ctrlKey && event.key === 'U') {
        event.preventDefault();
        alert('Ctrl+U está desativado.');
      }
    };

    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      alert('O botão direito do mouse está desativado.');
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }
}, [auth.user.role]);

  useEffect(() => {
    loadPessoa(idCadastro);
    window.document.title = 'CRM - Edição de Cadastro';
  }, []);

  if (loading) {
    return (
      <DivLoading container item md={11} xs={12}>
        <CircularProgress color="secondary" size={20}></CircularProgress>
      </DivLoading>
    );
  }

  if (!loading && !user) {
    return (
      <DivLoading container item md={11} xs={12}>
        <p>Nenhum cadastro encontrado.</p>
      </DivLoading>
    );
  }

  const validaAlteracao = () => {
    if (user != user2){
      setPessoaEdit (true);
    }else if (user === user2){
    setPessoaEdit (false);
    }
  }

  async function onSubmit(values: any) {
    if (showButtonViewPessoa) {
      formRef.setFields([
        {
          name: 'documento',
          errors: ['Já existe uma pessoa cadastrada com esse documento'],
        },
      ]);
      return;
    }

    setLoadingEditPessoa(true);
    const pageEditCadastroPessoaServices = new PageEditCadastroPessoaServices();
    console.log();
    const updatePessoaPerfilByPessoaIdResponse =
      await pageEditCadastroPessoaServices.UpdatePessoaPerfilByPessoaId({
        pessoa: {
          data_nascimento: new Date(values.data_nascimento).toISOString(),
          documento: values.documento,
          id: user.id,
          nome: values.nome,
          nome_fantasia: values.nome_fantasia,
          sexo: values.sexo,
          id_pessoa_responsavel: values.id_pessoa_responsavel,
          id_pessoa_indicador: values.id_pessoa_indicador,
          nome_pessoa_responsavel: values.nome_pessoa_responsavel,
          nome_pessoa_indicador: values.nome_pessoa_indicador,
          //documento_indicador: values.documento_indicador,
        },
      });

    if (!updatePessoaPerfilByPessoaIdResponse.error) {
      enqueueSnackbar(`Cadastro atualizado com sucesso!`, {
        title: 'Cadastro atualizado com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

    } else {
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      enqueueSnackbar(`Caso haja responsável verifique se o mesmo não é menor de idade`, {
        title: 'Caso haja responsável verifique se o mesmo não é menor de idade',
        variant: 'warning',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      enqueueSnackbar(`Caso haja indicador verifique se o mesmo possui perfil indicador`, {
        title: 'Caso haja indicador verifique se o mesmo possui perfil indicador',
        variant: 'warning',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }

    setLoadingEditPessoa(false);

  }

  const onChangeDocumento = (e: any) => {
    const value = e.target.value;

    const { typeDocument, valueModify } = MaskCPFeCNPJ(value);

    setTipoDocumento(typeDocument);

    formRef.setFieldsValue({
      documento: valueModify,
    });

    if (typeDocument === 'pj') {
      formRef.setFieldsValue({
        sexo: 1,
      });
    }
  };

  const desativarPessoa = (value: boolean) => {
    const service = new Service();
    setUser({ ...user, status_ativo: value ? 1 : 0 });

    service
      .PutPessoaPerfilByPessoaId(
        { status_ativo: value === false ? 0 : 1 },
        user.id,
      )
      .catch(() => {
        enqueueSnackbar(`Ocorreu um erro`, {
          title: 'Ocorreu um erro ',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      });
  };

  const finishUpdateResponsavel = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalUpdateResponsavel(false);
    window.location.reload();
  };

  const finishUpdateIndicador = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalUpdateIndicador(false);
    window.location.reload();
  };

  const finishDeleteResponsavel = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalDeleteResponsavel(false);
    window.location.reload();
  };

  const finishDeleteIndicador = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalDeleteIndicador(false);
    window.location.reload();
  };

  async function teste2(data_nascimento: Date){
    //console.log('DATA DE NASCIMENTO', data_nascimento);
    var date1 = new Date(data_nascimento);
    var date2 = new Date();

    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    //console.log(date1+ " diafsdfsdfsdfsdfsas");
    if (diffDays < 6574 ){
      setDataNasc(true);
      setDataNasc3(true)


    }else{

      setDataNasc(false);
      setDataNasc3(false)

    }

   // console.log(diffDays+ " dias");
  }

  
  return (
    <Grid container>
      <Modal
        title="Editar responsável"
        size="medio"
        openModal={openModalUpdateResponsavel}
        controlModal={(value: boolean) => setOpenModalUpdateResponsavel(value)}
      >
        <UpdateResponsavel
          pessoa_id={parseInt(user.id)}
          id_responsavel={user.id_pessoa_responsavel}
          nome_responsavel={user.nome_pessoa_responsavel}
          id_indicador={user.id_pessoa_indicador}
          nome_indicador={user.nome_pessoa_indicador}
          finishUpdate={finishUpdateResponsavel}
        />
      </Modal>

      <Modal
        title="Editar indicador"
        size="medio"
        openModal={openModalUpdateIndicador}
        controlModal={(value: boolean) => setOpenModalUpdateIndicador(value)}
      >
        <UpdateIndicador
        pessoa_id={parseInt(user.id)}
        id_indicador={user.id_pessoa_indicador}
        nome_indicador={user.nome_pessoa_indicador}
        id_responsavel={user.id_pessoa_responsavel}
        nome_responsavel={user.nome_pessoa_responsavel}
        finishUpdate={finishUpdateIndicador}
        />
      </Modal>

      <Modal
        title="Deletar responsável"
        size="medio"
        openModal={openModalDeleteResponsavel}
        controlModal={(value: boolean) => setOpenModalDeleteResponsavel(value)}
      >
        <DeleteResponsavel
        pessoa_id={parseInt(user.id)}
        id_indicador={user.id_pessoa_indicador}
        nome_indicador={user.nome_pessoa_indicador}
        id_responsavel={user.id_pessoa_responsavel}
        nome_responsavel={user.nome_pessoa_responsavel}
        finishDelete={finishDeleteResponsavel}
        />
      </Modal>

      <Modal
        title="Deletar indicador"
        size="medio"
        openModal={openModalDeleteIndicador}
        controlModal={(value: boolean) => setOpenModalDeleteIndicador(value)}
      >
        <DeleteIndicador
        pessoa_id={parseInt(user.id)}
        id_indicador={user.id_pessoa_indicador}
        nome_indicador={user.nome_pessoa_indicador}
        id_responsavel={user.id_pessoa_responsavel}
        nome_responsavel={user.nome_pessoa_responsavel}
        finishDelete={finishDeleteIndicador}
        />
      </Modal>
      {/* // HEADER PAGE */}
      <Grid
        container
        alignItems="center"
        alignContent="center"
        justify="flex-start"
      >
        <Grid container item sm={12}>
          <Grid item sm={2} xs={12} style={{ alignContent: 'center' }}>
            <TitlePage style={{ marginRight: 20, paddingTop: 2 }}>
              Cadastro{' '}
            </TitlePage>
          </Grid>

          <Grid container item alignContent="center" sm={4}>
            <Form size="middle" layout="inline" labelAlign="left">
              <Grid item sm={4} xs={12}>
                <Form.Item
                  label="ID: "
                  name="idCadastro"
                  style={{ paddingLeft: 10, paddingRight: 10, margin: 0 }}
                >
                  <Input placeholder="ID" defaultValue={user.id} disabled />
                </Form.Item>
              </Grid>
              {/* <Grid item sm={8} xs={10}>
              {idCadastro}
            </Grid> */}

              <Grid item sm={6} xs={12}>
                <Form.Item
                  label="Cadastrado por: "
                  name="cadastro_por"
                  style={{ paddingLeft: 10, paddingRight: 10, margin: 0 }}
                >
                  <Input
                    placeholder="Nome do usuário"
                    defaultValue={usuarioCadastro}
                    disabled
                  />
                </Form.Item>
              </Grid>
            </Form>
          </Grid>

          <Grid item sm={2} xs={12}>
            <Checkbox
              inputProps={{ 'aria-label': 'Inativar pessoa' }}
              size="medium"
              onChange={e => desativarPessoa(e.target.checked)}
              checked={user.status_ativo === 1 ? true : false}
            />
            {user.status_ativo === 1 ? 'Ativo' : 'Inativo'}
          </Grid>
        </Grid>
      </Grid>
      {/* // HEADER PAGE */}
      <Grid
        container
        item
        sm={12}
        justify="space-around"
        xs={12}
        style={{ marginTop: 20 }}
      >
        {/* FORMULARIO  */}
        <Grid item sm={5} xs={12}>
          <Spin
            size="large"
            spinning={loadingEditPessoa}
            tip="Enviando dados..."
          >
            <Form
              form={formRef}
              size="middle"
              layout="vertical"
              scrollToFirstError={true}
              //onChange={teste3}
              onFinish={onSubmit}

              labelAlign="left"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                // cidade: { suggestion: suggestions[0] },
                documento: user.documento,
                nome: user.nome,
                nome_fantasia: user.nome_fantasia,
                data_nascimento: new Date(user.data_nascimento)
                  .toISOString()
                  .substr(0, 10),

                id_pessoa_indicador: user.id_pessoa_indicador,
                motivo_inicial: user.motivo_inicial,
                origem_inicial: user.origem_inicial,
                id_pessoa_responsavel: user.id_pessoa_responsavel,
                nome_pessoa_responsavel: user.nome_pessoa_responsavel,
                nome_pessoa_indicador: user.nome_pessoa_indicador,
                sexo: user.sexo,
                tipoDocumento: type,
              }}
              requiredMark={false}
            >
              <Grid container>
              <InvisibleContent
                  visible={tipoDocumento === 'pf'}
                >
                  <InvisibleContent
                  visible={dataNasc === false && dataNasc2 === true && dataNasc3 === true
                    || dataNasc === true && dataNasc2 === true && dataNasc3 === true
                    || dataNasc === true && dataNasc2 === false && dataNasc3 === true  }
                >
                <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10 }}
                  >
                    <Form.Item
                      label="Documento"
                      hasFeedback
                      name="documento"
                      rules={[
                        { required: false, message: 'Campo obrigatorio' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        //{ validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          debounceCheckDocument(e.target.value);
                          validaAlteracao();
                        }}
                        placeholder="Documento"
                      />
                    </Form.Item>
                    {showButtonViewPessoa && (
                      <Grid item xs={12} lg={12} md={12}>
                        <LinkRoute
                          onClick={() => window.open(`/cadastro/${idExists}/`)}
                        >
                          Visualizar cadastro
                          <Visibility style={{ marginLeft: 10 }} />
                        </LinkRoute>
                      </Grid>
                    )}
                  </Grid>
                </InvisibleContent>

                <InvisibleContent
                  visible={dataNasc === false && dataNasc2 === false && dataNasc3 === true
                    || dataNasc === false && dataNasc2 === false && dataNasc3 === false
                    || dataNasc === false && dataNasc2 === true && dataNasc3 === false  }
                >
                <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10 }}
                  >
                    <Form.Item
                      label="Documento"
                      hasFeedback
                      name="documento"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        { validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          debounceCheckDocument(e.target.value);
                          validaAlteracao();
                        }}
                        placeholder="Documento"
                      />
                    </Form.Item>
                    {showButtonViewPessoa && (
                      <Grid item xs={12} lg={12} md={12}>
                        <LinkRoute
                          onClick={() => window.open(`/cadastro/${idExists}/`)}
                        >
                          Visualizar cadastro
                          <Visibility style={{ marginLeft: 10 }} />
                        </LinkRoute>
                      </Grid>
                    )}
                  </Grid>
                </InvisibleContent>

                </InvisibleContent>

                <InvisibleContent
                  visible={tipoDocumento === 'pj'}
                >
                <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10 }}
                  >
                    <Form.Item
                      label="Documento"
                      hasFeedback
                      name="documento"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        { validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          debounceCheckDocument(e.target.value);
                          validaAlteracao();
                        }}
                        placeholder="Documento"
                      />
                    </Form.Item>
                    {showButtonViewPessoa && (
                      <Grid item xs={12} lg={12} md={12}>
                        <LinkRoute
                          onClick={() => window.open(`/cadastro/${idExists}/`)}
                        >
                          Visualizar cadastro
                          <Visibility style={{ marginLeft: 10 }} />
                        </LinkRoute>
                      </Grid>
                    )}
                  </Grid>
                </InvisibleContent>

                <InvisibleContent
                  visible={tipoDocumento === 'pf'}
                >
                  <Grid item lg={12} xs={12}>
                    <Form.Item
                      label='Nome Completo'

                      hasFeedback
                      name="nome"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 3, message: 'Minimo de 3 letras' },
                        {
                          pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                          message: 'Somente letras'
                        }
                      ]}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Input
                        onInput={handleInput}
                        placeholder='Nome Completo'
                        onChange = {validaAlteracao}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        label= 'Nome Social'

                        hasFeedback
                        name="nome_fantasia"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },
                          {
                            pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                            message: 'Somente letras'
                          }

                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          onInput={handleInput}
                          placeholder= 'Nome Social'
                          onChange = {validaAlteracao}
                        />
                      </Form.Item>
                   </Grid>

                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="data_nascimento"
                        label='Data de Nascimento'
                        hasFeedback
                        //rules={[
                          //{
                            //required: true,
                          // message: 'campo obrigatorio',
                        // },
                        //]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          type="date"
                          placeholder= 'Data de Nascimento'
                          //onChange = {validaAlteracao}
                          onChange={(e: any) => {

                            teste2(e.target.value);
                            validaAlteracao();
                          }}

                          max={DATA}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent
                  visible={tipoDocumento === 'pj'}
                >
                  <Grid item lg={12} xs={12}>

                    <Form.Item
                      label='Razão Social'

                      hasFeedback
                      name="nome"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 3, message: 'Minimo de 3 letras' },
                        {
                         // pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                        }
                      ]}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Input
                        onInput={handleInput}
                        placeholder='Razão Social'
                        onChange = {validaAlteracao}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        label= 'Nome Fantasia'

                        hasFeedback
                        name="nome_fantasia"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },
                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          onInput={handleInput}
                          placeholder= 'Nome Fantasia'
                          onChange = {validaAlteracao}
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="data_nascimento"
                        label='Data de Fundação'
                        hasFeedback
                        //rules={[
                          //{
                            //required: true,
                          // message: 'campo obrigatorio',
                        // },
                        //]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          type="date"
                          placeholder= 'Data de Fundação'
                          onChange = {validaAlteracao}
                          max={DATA}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                </InvisibleContent>

                <Grid item container justify="space-around" sm={12} xs={12}>
                  <Form.Item name="sexo">
                    <Radio.Group disabled={tipoDocumento === 'pj'}>
                      <Radio value={1} onChange = {validaAlteracao} >Feminino</Radio >
                      <Radio value={0} onChange = {validaAlteracao}>Masculino</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Grid>

                <Grid container item lg={12} sm={12} xs={12}>
                  <Grid item lg={3} xs={3} sm={3}>
                    <Form.Item
                      label="Cód. responsável"
                      hasFeedback
                      name="id_pessoa_responsavel"
                      rules={[{ required: false, message: 'Campo obrigatorio' }]}
                      style={{ paddingLeft: 10, paddingRight: 5 }}
                    >
                      <Input
                        disabled
                        placeholder="Código responsável"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={7} xs={7} sm={7}>
                    <Form.Item
                      label="Nome do responsável"
                      hasFeedback
                      name="nome_pessoa_responsavel"
                      rules={[{ required: false, message: 'Campo obrigatorio' }]}
                      style={{ paddingLeft: 2, paddingRight: 5 }}
                    >
                      <Input
                        disabled
                        placeholder="Nome do responsável"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item xs={1} sm={1} lg={1} style={{paddingTop: 25}}>
                    <ButtonComponent
                      color="default"
                      disabled={tipoDocumento === 'pj'}
                      onClick={() => setOpenModalUpdateResponsavel(true)}
                      style={{ minWidth: 10, height: 30,  paddingRight: 8, paddingLeft: 8}}
                    >
                      <Edit />
                    </ButtonComponent>
                  </Grid>

                  <InvisibleContent
                  visible={tipoDocumento === 'pf'}
                >
                    <Grid item xs={1} sm={1} lg={1} style={{paddingTop: 25}}>
                      <ButtonComponent
                        color="default"
                        disabled={dataNasc === false && dataNasc2 === true && dataNasc3 === true
                          || dataNasc === true && dataNasc2 === true && dataNasc3 === true
                          || dataNasc === true && dataNasc2 === false && dataNasc3 === true}
                        onClick={() => setOpenModalDeleteResponsavel(true)}
                        style={{ minWidth: 10, height: 30,  paddingRight: 7, paddingLeft: 4}}
                      >
                        <ClearRounded />
                    </ButtonComponent>

                  </Grid>
                  </InvisibleContent>

                  <InvisibleContent
                  visible={tipoDocumento === 'pj'}
                >

                  <Grid item xs={1} sm={1} lg={1} style={{paddingTop: 25}}>
                      <ButtonComponent
                        color="default"
                        disabled={tipoDocumento === 'pj'}
                        onClick={() => setOpenModalDeleteResponsavel(true)}
                        style={{ minWidth: 10, height: 30,  paddingRight: 7, paddingLeft: 4}}
                      >
                        <ClearRounded />
                    </ButtonComponent>

                  </Grid>
                  </InvisibleContent>

                </Grid>

                <Grid container item lg={12} xs={12}>
                  <Grid item lg={3} xs={3} md={3}>
                    <Form.Item
                      label="Cód. indicador"
                      hasFeedback
                      name="id_pessoa_indicador"
                      rules={[{ required: false, message: 'Campo obrigatorio' }]}
                      style={{ paddingLeft: 10, paddingRight: 5 }}
                    >
                      <Input
                        disabled
                        placeholder="Código indicador"
                      />
                    </Form.Item>
                  </Grid>

                  <Grid item lg={7} xs={7} md={7}>
                    <Form.Item
                      label="Nome do indicador"
                      hasFeedback
                      name="nome_pessoa_indicador"
                      rules={[{ required: false, message: 'Campo obrigatorio' }]}
                      style={{ paddingLeft: 2, paddingRight: 5 }}
                    >
                      <Input
                        disabled
                        placeholder="Nome do indicador"
                      />
                    </Form.Item>
                  </Grid>

                <Grid item xs={1} md={1} lg={1} style={{paddingTop: 25}}>
                  <ButtonComponent
                    color="default"
                    onClick={() => setOpenModalUpdateIndicador(true)}
                    style={{ minWidth: 10, height: 30, paddingRight: 8, paddingLeft: 8 }}
                  >
                    <Edit />
                  </ButtonComponent>
                </Grid>
                <Grid item xs={1} sm={1} lg={1} style={{paddingTop: 25}}>
                  <ButtonComponent
                    color="default"
                    onClick={() => setOpenModalDeleteIndicador(true)}
                    style={{ minWidth: 10, height: 30, paddingRight: 7, paddingLeft: 4}}
                  >
                    <ClearRounded />
                  </ButtonComponent>

                </Grid>
                </Grid>

                {/* <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="Origem inicial"
                    hasFeedback
                    name="origem_inicial"
                    rules={
                      [
                        // { required: true, message: 'Campo obrigatorio' },
                        // { min: 3, message: 'Minimo de 3 letras' },
                      ]
                    }
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    <Input disabled placeholder="Origem inicial" />
                  </Form.Item>
                </Grid>

                <Grid item lg={12} xs={12}>
                  <Form.Item
                    label="Motivo inicial"
                    hasFeedback
                    name="motivo_inicial"
                    rules={
                      [
                        // { required: true, message: 'Campo obrigatorio' },
                        // { min: 3, message: 'Minimo de 3 letras' },
                      ]
                    }
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    <Input disabled placeholder="Motivo inicial" />
                  </Form.Item>
                </Grid> */}

                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ marginTop: 10, paddingRight: 10, paddingLeft: 10 }}
                >
                  <ButtonComponent
                    color="primary"
                    disabled={loadingEditPessoa || !pessoaEdit}
                    loading={loadingEditPessoa}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 20 }} /> Atualizar
                    cadastro
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Spin>
        </Grid>
        {/* FORMULARIO  */}

        {/* Component Perfil */}
        <Grid item sm={7} style={{ paddingLeft: 20, paddingRight: 20 }}>
          {perfil.length <= 0 ? (
            <Grid item sm={8}>
              <CreatePerfil
                idUsuario={parseInt(user.id)}
                finishCreate={() => loadPessoa(idCadastro)}
                data={perfil}
              />
            </Grid>
          ) : (
            <NewComponentPerfil
              data={perfil}
              tiposPerfil={tiposPerfis}
              pessoaID={parseInt(user.id)}
              functionReload={() => loadPessoa(idCadastro)}
              initPerfil={perfilInit}
            />
          )}
        </Grid>
        {/* Component Perfil */}
      </Grid>
    </Grid>
  );
};

export default EditCadastro;
