import api from '@http/api';

interface IRequestData {
  pageNumber: number;
  pageSize: number;
  status_ativo: number;
  campanha_id?: number;
}

export interface IModelo {
  id: number;
  nome: string;
  descricao: string;
  data_cadastro: string;
}

export interface ICampanha {
  id: number;
  descricao: string;
}

export interface IResponseGetEstrategias {
  total_itens: number;
  total_pagina: number;
  modelo: IModelo[];
}

interface IResponse {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    modelo: IModelo[];
  };
}

interface IResponseCampanha {
  error: boolean;
  response?: ICampanha[];
}

class Services {
  public async getEstrategias({
    pageNumber,
    pageSize,
    status_ativo,
  }: IRequestData): Promise<IResponse> {
    return await api
      .get<IResponseGetEstrategias>(
        `api/Estrategia/GetEstrategiasPaginadas?pageNumber=${pageNumber}&pageSize=${pageSize}&statusAtivo=${status_ativo}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getEstrategiasCampanha({
    campanha_id,
    pageNumber,
    pageSize,
    status_ativo,
  }: IRequestData): Promise<IResponse> {
    return await api
      .get<IResponseGetEstrategias>(
        `api/Estrategia/GetEstrategiasByCampanhaIdPaginadas?campanhaId=${campanha_id}&pageNumber=${pageNumber}&pageSize=${pageSize}&statusAtivo=${status_ativo}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getCampanhas(): Promise<IResponseCampanha> {
    return await api
      .get(`/api/Campanha/GetCampanhas`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default Services;
