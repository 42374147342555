import React, { useEffect, useState, useRef } from 'react';

import { Grid, MenuItem, Button } from '@material-ui/core';

import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import ButtonComponent from '@components/Shared/Buttons/Button';

import Service, { IPerfil } from './services';
import { SubTitleBold } from '@src/@styles/styles.global';

interface IProps {
  perfil_id: number;
  tipo_perfil_descricao: string;
  finishUpdate?: Function;
}

const PerfilComponent: React.FC<IProps> = ({
  perfil_id,
  tipo_perfil_descricao,
  finishUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [open, setOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const [tiposPerfil, setTiposPerfil] = useState<IPerfil[]>([]);
  const [perfilSelected, setPerfilSelected] = useState<IPerfil>({} as IPerfil);

  const [tipoPerfilSelected, setTipoPerfilSelected] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [loadingUpdatePerfil, setLoadingUpdatePerfil] = useState(false);

  const handleMenuItemClick = (_: any, option: any) => {
    console.log(option);
    setTipoPerfilSelected(option.key);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  useEffect(() => {
    const init = async () => {
      const service = new Service();

      const tiposPerfilResponse = await service.GetTiposPerfil();

      if (!tiposPerfilResponse.error && tiposPerfilResponse.response) {
        setTiposPerfil(tiposPerfilResponse.response);
        setPerfilSelected(tiposPerfilResponse.response[0]);
      }
    };

    init();
    setLoading(false);
  }, []);

  async function onSubmit(values: any) {
    const componentPerfilServices = new Service();
    setLoadingUpdatePerfil(true);

    const updateTipoPerfil: any = {
      tipo_perfil_id: values.id,
    };

    const updatePerfilResponse = await componentPerfilServices.UpdateTipoPerfil(
      { perfil_id, tipo_perfil_id: tipoPerfilSelected },
    );

    if (!updatePerfilResponse.error) {
      setLoadingUpdate(false);
      setLoading(false);
      enqueueSnackbar(`Perfil salvo com sucesso!`, {
        title: 'Perfil salvo com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      if (finishUpdate) {
        setLoadingUpdate(false);
        await finishUpdate();
      }
    } else {
      setLoadingUpdate(false);
      setLoading(false);
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  }

  return (
    <Grid container>
      <SubTitleBold> ALTERAÇÃO DE PERFIL </SubTitleBold>
      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingUpdatePerfil}
          tip={loadingUpdatePerfil ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Form
              form={formRef}
              scrollToFirstError={true}
              onFinish={onSubmit}
              requiredMark={false}
              style={{ width: '100%' }}
              layout="vertical"
            >
              <Grid item lg={12} xs={12}>
                <Form.Item label="Perfil atual:">
                  <Input
                    name="tipo_perfil_id"
                    disabled
                    placeholder="Tipo de perfil"
                    value={tipo_perfil_descricao}
                  />
                </Form.Item>
              </Grid>
              <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                <Form.Item name="id" label="Substituir por: ">
                  <SelectAntd
                    placeholder="Tipo de Perfil"
                    style={{ width: '100%' }}
                    onChange={(value: any) => {
                      setTipoPerfilSelected(value);
                    }}
                  >
                    {tiposPerfil.map((option, index) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option?.descricao}
                      </MenuItem>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
              <Grid container justify="center" alignItems="center">
                <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={loadingUpdate}
                    loading={loadingUpdate}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default PerfilComponent;
