import api from '@http/api';

interface IResponseReqImpressaoLocal {
  boletoURL: string;
}

interface IResponse {
  error: boolean;
  response?: {
    boletoURL: string;
  };
}

interface IResponseGrafica {
  error: boolean;
  response?: [{ url: string }];
}

interface IviewModelEstrategiaAcaoDTOs {
  estrategia_acao_id: string;
  estrategia_acao_acao_descricao: string;
  status: number;
}

export interface IEnvios {
  estrategia_id: number;
  estrategia_descricao: string;
  tipo_acao_descricao: string;
  estrategia_nome: string;
  status: number;
  campanha_id: number;
  viewModelEstrategiaAcaoDTOs: IviewModelEstrategiaAcaoDTOs[];
}

interface IResponseLoadEnvios {
  error: boolean;
  response?: IEnvios[];
}

class Services {
  public async loadEnvios(): Promise<IResponseLoadEnvios> {
    return await api
      .get<IEnvios[]>('/api/EstrategiaAcao/ListaEnvio')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: false,
        };
      });
  }

  public async impressaoLocal(estrategiaAcaoId: string): Promise<IResponse> {
    return await api
      .get<IResponseReqImpressaoLocal>(
        `api/Envio/GetMergedPDF?estrategiaAcaoId=${estrategiaAcaoId}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async envioEmail(estrategiaAcaoId: string): Promise<IResponse> {
    return await api
      .get(`/api/Envio/EnvioEmail?estrategiaAcaoId=${estrategiaAcaoId}`)
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getArquivoGrafica(
    estrategiaAcaoId: string,
  ): Promise<IResponseGrafica> {
    return await api
      .get<[{ url: string }]>(
        `/api/Envio/GetArquivoGrafica?estrategiaAcaoId=${estrategiaAcaoId}`,
      )
      .then(response => {
        return {
          response: response.data,
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getCampanhas(): Promise<IResponseCampanha> {
    return await api
      .get(`/api/Campanha/GetCampanhas`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadEnviosCampanha({
    campanha_id,
  }: IRequestData): Promise<IResponseLoadEnviosCampanha> {
    return await api
      .get(`/api/EstrategiaAcao/ListaEnvioCampanhaById?campanhaId=${campanha_id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

}

export interface ICampanha {
  id: number;
  descricao: string;
}

interface IResponseCampanha {
  error: boolean;
  response?: ICampanha[];
}

interface IRequestData {
  campanha_id?: number;
}

interface IResponseLoadEnviosCampanha {
  error: boolean;
  response?: IEnvios[];
}

export default Services;