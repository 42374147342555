import api from '@http/api';

export interface IDetalhesDebitoTable {
    vencimento: string,
    id_pessoa: number,
    documento: string,
    nome: string,
    tipo_perfil: string,
    valor: string,
    nome_arquivo: string,
    banco_convenio: string,
    id_arquivo: number,
    usuario_cadastro: string,
    link_remessa: string,
}

export interface IDetalhesDebito {
  modelo: IDetalhesDebitoTable[];
  nome_aquivo: string,
}

class Services {
  public async DetalhesDebito(
    pageNumber: number,
    pageSize: number,
    arquivoId: number,
    convenioId: string | number,

  ) {
    return await api
      .get<IDetalhesDebito>(
        `api/Financeiro/GetFinanceiroDetalhesRemessa?pageNumber=${pageNumber}&pageSize=${pageSize}&arquivoId=${arquivoId}&convenioId=${convenioId}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data.modelo,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

  public async arquivoRemessa(boletoId: string): Promise<IResponse> {
    return await api
      .get<IResponseReqImpressaoLocal>(`/api/Financeiro/GetRemessaDetalhesLinkBoleto?boletoId=${boletoId}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

interface IResponse {
  error: boolean;
  response?: {
    link_remessa: string;
  };
}

interface IResponseReqImpressaoLocal {
  link_remessa: string;
}

export default Services;
