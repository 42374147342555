import api from '@http/api';

export interface IObjectDefault {
  label: string;
  value: string;
}

interface IResponse {
  error: boolean;
  response?: IObjectDefault[];
}

interface IEntidade {
  entidade_id: string;
  entidade_descricao: string;
}

class Services {
  public async getEntidades(): Promise<IResponse> {
    return await api
      .get<IEntidade[]>('/api/Home/entidade')
      .then(response => {
        let objetoResponse: IObjectDefault[] = [];

        response.data.map(item => {
          return objetoResponse.push({
            label: item.entidade_descricao,
            value: item.entidade_id,
          });
        });

        return {
          error: false,
          response: objetoResponse,
        };
      })
      .catch(error => {
        return {
          error: false,
        };
      });
  }
}

export default Services;
