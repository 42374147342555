import React from 'react';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { MenuOpenOutlined } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { getColors } from '@styles/colorsTheme';

import MenuHeader from '../MenuHeader';
import MenuList from '../ListMenuSelected';
import { useStyles } from './styles';

const Menu: React.FC = ({ children }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleDrawerOpen = () => {
    setOpen(!open ? true : false);
  };

  const openTopMenu = () => {
    setOpen(true);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerOpen}>
              {!open ? (
                <MenuIcon htmlColor={getColors()?.branco} />
              ) : (
                <MenuOpenOutlined htmlColor={getColors()?.branco} />
              )}
            </IconButton>
          </div>
          <Divider />
          <MenuList
            menuOpen={open}
            isDesktop
            openToMenu={() => openTopMenu()}
          />
          <Divider />
        </Grid>
      </Drawer>
      <main className={classes.content} style={{ padding: 0 }}>
        <div className={classes.toolbar} style={{ padding: 0 }}>
          <MenuHeader />
        </div>
        {children}
      </main>
    </div>
  );
};

export default Menu;
