import api from '@http/api';
import { FormatDate } from '@src/utils/formats';

export interface ICorrespDevolvidaTable {
  modelo: ISolicitacaoResultTable[];
  id_correspondencia_devolvida: number;
  id_pessoa: number;
  nome: string;
  mala_mensal: string;
  data_envio: string;
  data_devolucao: string;
  motivo_devolucao: string;

}

export interface ISolicitacaoResultTable {
  id_correspondencia_devolvida: string;
}

export interface IDetalhesCorrespDevolvida {

  error: boolean;
  response?: ICorrespDevolvidaTable[];
  //modelo: ICorrespDevolvidaTable[];
}

class Services {
  public async DetalhesCorrespDevolvida(): Promise<IDetalhesCorrespDevolvida> {
    return await api
      .get(`/api/CorrespondenciaDevolvida/GetListaPendenteCorrespondenciaDevolvida`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }



}

export default Services;
