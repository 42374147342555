import api from '@http/api';
import api2 from '@http/telefonia';


export interface IRequestGetListasLigacao {
  entidadeID: string;
}

interface IResponseNameFunction {
  error: boolean;
  response?: IListaEstrategia[];
}
interface IResponseGetPessoaFunction {
  error: boolean;
  messageError?: any;
  response?: IGetPessoa;
}

export interface IListaEstrategia {
  id: number;
  nome: string;
  listasPrimeira: { id: number; quantidade: number };
  listasRetorno: { id: number; quantidade: number };

  // quantidade: number;
  // quantidadeRetornos: number;
}

export interface IGetPessoa {
  id_ligacao_interacao: number;
  id_estrategia: number;
  estrategia_nome: string;
  id_pessoa: number;
  id_perfil: number;
  contatos_perfil: string[];
}

interface IResponseAxiosGetListasLigacao {
  listaLigacaoEstrategia: IListaEstrategia[];
}

class Services {
  public async getListasLigacao(): Promise<IResponseNameFunction> {
    return await api
      .get<IResponseAxiosGetListasLigacao>(`/api/Telefonia/GetListasLigacao`)
      .then(response => {
        return {
          error: false,
          response: response.data.listaLigacaoEstrategia,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoa(
    estrategiaID: string,
    retorno: boolean,
  ): Promise<IResponseGetPessoaFunction> {
    return await api
      .post<IGetPessoa>(`api/Telefonia/GetLigacaoEstrategia`, {
        id_estrategia: estrategiaID,
        retorno,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        console.log(error);
        return {
          error: true,
          messageError: {
            status: error.status,
            data: error.data,
          },
        };
      });
  }


  public async teste(
    usuario: string,
    ramal: string,
  ) {
    return await api2
      .get(
        `/Telefonia/Autenticar?usuario=${usuario}&ramal=${ramal}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

}

export default Services;
