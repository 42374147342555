import React from 'react';
import { startOfDay, endOfDay, subDays } from 'date-fns';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getColors } from '@styles/colorsTheme';

import { DateRangePicker } from 'rsuite';
import { Grid, useMediaQuery } from '@material-ui/core';

import './styles.css';

interface IProps {
  color: 'secondary' | 'default' | 'primary';
  title: string;
  setValor: Function;
  notUseDefaultValue?: boolean;
  cleanable?: boolean;
  disable?: boolean;
  showOneCalendar?: boolean;
  disableDateBefore?: Date;
  notViewRanges?: boolean;
  rangeInitial?: Date[];
  placement?:
    | 'bottomStart'
    | 'bottomEnd'
    | 'topStart'
    | 'topEnd'
    | 'leftStart'
    | 'rightStart'
    | 'leftEnd'
    | 'rightEnd';
}

const { before } = DateRangePicker;

const DatePicker: React.FC<IProps> = ({
  color,
  title,
  setValor,
  notUseDefaultValue,
  cleanable,
  disable,
  showOneCalendar,
  placement,
  disableDateBefore,
  notViewRanges,
  rangeInitial,
}) => {
  const matches = useMediaQuery('(max-width: 800px)');

  const useStyles = makeStyles((style: Theme) =>
    createStyles({
      background: {
        backgroundColor:
          color === 'primary'
            ? getColors()?.azulClaro
            : color === 'secondary'
            ? getColors()?.azulEscuro
            : getColors()?.cinzaEscuro,
        opacity: color === 'default' ? 0.5 : 1,

        paddingTop: 6,
        paddingBottom: 5,
        paddingLeft: 15,
        paddingRight: 15,
        color: getColors()?.branco,
        width: '100%',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
      },
    }),
  );

  const classes = useStyles();

  return (
    <div color={color} className={classes.background}>
      <Grid container justifyContent="space-around" alignItems="center">
        {title && <p style={{}}>{title}</p>}
        <DateRangePicker
          disabledDate={
            disableDateBefore ? before(disableDateBefore) : undefined
          }
          format="DD-MM-YYYY"
          cleanable={cleanable}
          showOneCalendar={showOneCalendar ? showOneCalendar : matches}
          appearance="subtle"
          onOk={dates => setValor(dates)}
          onChange={dates => setValor(dates)}
          disabled={disable}
          placement={placement ? placement : undefined}
          locale={{
            ok: 'Atualizar',
            sunday: 'Dom',
            monday: 'Seg',
            tuesday: 'Ter',
            wednesday: 'Qua',
            thursday: 'Qui',
            friday: 'Sex',
            saturday: 'Sab',
          }}
          defaultValue={
            !notUseDefaultValue
              ? [
                  startOfDay(
                    rangeInitial ? rangeInitial[0] : subDays(new Date(), 14),
                  ),
                  endOfDay(rangeInitial ? rangeInitial[1] : new Date()),
                ]
              : undefined
          }
          ranges={
            !notViewRanges
              ? [
                  {
                    label: 'Últimos 14 dias',
                    value: [
                      startOfDay(subDays(new Date(), 14)),
                      endOfDay(new Date()),
                    ],
                  },
                  {
                    label: 'Últimos 7 dias',
                    value: [
                      startOfDay(subDays(new Date(), 7)),
                      endOfDay(new Date()),
                    ],
                  },
                ]
              : []
          }
        />
      </Grid>
    </div>
  );
};

export default DatePicker;
