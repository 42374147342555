import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';


import { 
    Container, 
    ChartContainer,
    Header,
    LegendContainer,
    Legend,
}  from './styles';


interface IHistoryBoxProps {
    data: {
        mes: string,
        doadores_mes: number,
        doacoes_mes: number,
        valor_doacoes_mes: number,
        cadastros_mes: number,
        percentual_doadores: number,
        media_per_capta: number,
    }[],
}


const formatValue = (value: number): string => {
    return value.toLocaleString( 
        'pt-br', 
        {
            style: 'percent', 
        });
};


const HistoryBox: React.FC<IHistoryBoxProps> =({data}) => (
    <Container>
        <span>Conversão para Doadores (Pencentual no mês) </span>
        <ChartContainer>
            <ResponsiveContainer>
                <LineChart data={data} margin={{ top: 5, right: 20, left: 20 }}>
                    <CartesianGrid strokeDasharray="3 3" stroke="#36316a" />
                    <XAxis dataKey="mes" stroke="#36316a" />
                    <Tooltip formatter={(value) => formatValue(Number(value))} /> 
                    <Line 
                        type="monotone"                
                        dataKey="percentual_doadores"
                        name="Conversão para Doadores"
                        stroke="#6D6A93"
                        strokeWidth={3}
                        dot={{ r: 5}}
                        activeDot={{ r: 8}}
                    />
                </LineChart>
            </ResponsiveContainer>
        </ChartContainer>
    </Container>
)

export default HistoryBox;