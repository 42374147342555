import ButtonComponent from '@src/components/Shared/Buttons/Button';
import styled from 'styled-components';

export const Container = styled.div``;

/*export const Button = styled(ButtonComponent)`
display:${props => props.disabled && 'none' };
`;*/

export const Title = styled.div``;
