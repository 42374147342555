import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Container = styled.div`
    /* >select{
        padding: 7px 10px;
        border-radius: 5px;
    } */
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const DivLoading = styled(Grid)`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 450px;
  justify-content: center;
  align-items: center;
  z-index: 500;
`;
