import styled from "styled-components";


// export const Container = styled.div`
//     width: 30%;
//     height: 250px;

//     /* margin: 10px 0; */

//     border-radius: 7px;
//     background: #EFEEF2;
    
//     color:#36316a;

//     display: flex;

//     >span{
//         padding: 15px;
//         font-size: 14px;
//         font-weight: bold;
//         position: absolute;
//     }
// `;


export const Container = styled.div`
    width: 48%;
    min-height: 260px;

    margin: 10px 0;

    background-color:#EFEEF2;
    color:#36316a;
       
    border-radius: 7px;

    display: flex;
    

    >span{
        padding: 15px;
        font-size: 14px;
        font-weight: bold;
        position: absolute;
    }

     @media(max-width: 1200px){
        display: flex;
        flex-direction: column;

        width: 100%;
        height: auto;
    }
`;

export const ChartContainer = styled.div`
     flex: 1;
     height: 90%; 
     width: 100%;   
     
    margin-top: 35px; 
`;



export const LegendContainer = styled.ul`
    list-style: none;
    height: 135px;
    padding-right: 20px;
    padding-bottom: 10px;
    overflow-y: scroll;    


    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: '#FFF';
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: '#F5F5F5';
    }
`;
