import api from '@http/api';

export interface IRecuseSolicitacao {
  id_solicitacao: number[];
}

class ComponentSolicitacaoServices {
  public async RecuseSolicitacao({
    id_solicitacao,
  }: IRecuseSolicitacao) {
    return await api
      .patch('api/Solicitacao/RecusaSolicitacao', {
        id_solicitacao,
      })

      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ComponentSolicitacaoServices;
