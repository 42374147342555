import api from '@http/api';
import ImportarRetorno from '.';
 
export interface IResultImporte {
  id: number;
  data_importe: string;
  arquivo: string;
  sequencial_arquivo: number;
  banco: string;
  total_registro: string;
  usuario_importe: string;
}
 
interface IImporte {
  modelo: IResultImporte[];
}
 
export interface IBanco {
  id: number;
  descricao: string;
}
 
interface IResponseBanco {
  error: boolean;
  response?: IBanco[];
}


class Services {
  public async ImportRetorno(data: any) {
    return await api
      .post<any>('/api/Financeiro/ImporteRetornos', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }
 
  public async getListImporte(
    dataInicial: string,
    dataFinal: string,
    bancoId: string | number,
    //arquivoRetornoId: string | number,
   
  ){
    return await api
      .get<IImporte>(
        `/api/Financeiro/GetFinanceiroImporteRetornos?dataInicial=${dataInicial}&dataFinal=${dataFinal}&bancoId=${bancoId}`
      ).then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }
 
 
  public async getBancos(): Promise<IResponseBanco>{
    return await api
      .get(
        `api/Financeiro/GetFinanceiroBancos`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }
}
export default Services;