import api from '@http/api';
import Financeiro from '.';

export interface IRequestNameFunction {
  data: {
    example: string;
  };
}

interface IResponseNameFunction {
  error: boolean;
  response?: any;
}

interface IResponseAxiosNameFunction {
  example: any;
}

export interface IFormaContribuicao {
  id: string | number;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface ITipoRemessa {
  id: number;
  codigo_movimento: string;
  descricao: string;
  tipo_registro: number;
}

export interface IConvenio {
  id: number;
  numero_convenio: string;
  nome_cedente: number | string | null;
  numero_agencia: number | string | null;
  digito_agencia: number | string | null;
  numero_conta: number | string | null;
  digito_cedente: number | string | null;
  id_banco: number | string;
  descricao: string;
}

export interface IDebitoResultTable {
  arquivoId: number;
  data_exportacao: string;
  nome_arquivo: string;
  total_registros: number;
  valor_total: string;
  usuario_cadastro: string;
  link_remessa: string;
  link_csv: string;
}

export interface IDebitos {
  modelo: IDebitoResultTable[];
  id: number;
  quantidade_item_pagina: number;
  total_pagina: number;
  total_itens: number;
  pagina_anterior_existe: boolean;
  pagina_seguinte_existe: boolean;
  quantidade_cadastros_atualizar: number;
}

class Services {
  public async nameFunction({
    data,
  }: IRequestNameFunction): Promise<IResponseNameFunction> {
    return await api
      .get<IResponseAxiosNameFunction[]>('api/...')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetTipoRemessa(formaContribuicaoID: string | number) {
    return await api
      .get<ITipoRemessa[]>(
        `/api/Financeiro/GetFinanceiroCodMovimentoByFormaContribuicao?formaContribuicaoId=${formaContribuicaoID}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetConvenios(formaContribuicaoID: string | number) {
    return await api
      .get<IConvenio[]>(
        `api/Financeiro/GetFinanceiroConveniosByFormaContribuicao?formaContribuicaoId=${formaContribuicaoID}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetListDebitosAgrupados(
    pageNumber: number,
    pageSize: number,
    convenioId: string | number,
    tipoRemessa: string | number,
    dataInicial: string,
    dataFinal: string,
  ) {
    return await api
      .get<IDebitos>(
        `/api/Financeiro/GetFinanceiroHistoricoRemessa?pageNumber=${pageNumber}&pageSize=${pageSize}&convenioId=${convenioId}&tipoRemessa=${tipoRemessa}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }


  public async arquivoRemessaBoleto(arquivoId: string): Promise<IResponse> {
    return await api
      .get<IResponseReqImpressaoLocal>(`/api/Financeiro/GetRemessaBoletoLink?arquivoId=${arquivoId}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async arquivoRemessaDebito(arquivoId: string): Promise<IResponse> {
    return await api
      .get<IResponseReqImpressaoLocal>(`/api/Financeiro/GetRemessaDebitoAutomaticoLink?arquivoId=${arquivoId}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async arquivoRemessaCSV(arquivoId: string, id_tipo_remessa: string){
    return await api
      .get(`/api/Financeiro/GetCreateExcelDetalheRemessa?arquivoId=${arquivoId}&id_tipo_remessa=${id_tipo_remessa}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }
}

interface IResponse {
    error: boolean;
    response?: {
      link_remessa: string;
    };
  }

  interface IResponseReqImpressaoLocal {
    link_remessa: string;
  }

  interface IResponseCSV {
    error: boolean;
    response?: {
      link_csv: string;
    };
  }

  interface IResponseReqCSV {
    link_csv: string;
  }

export default Services;
