import React, { useEffect, useState } from 'react';

import * as S from './styles';
import serviceSocket from '@src/utils/SendSocket';
import Service from '@src/hooks/FooterTelefonia/services';
import { useAuth } from '@hooks/AuthenticateContext';
import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';

import { Beforeunload } from 'react-beforeunload';
import { UrlServerAPI } from '@src/Configs/app';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const WebPhoneAtivo: React.FC = () => {
  const query = useQuery();
  const { user } = useAuth();
  const [urlIframe, setUrlIframe] = useState('');
  const [connection, setConnection] = useState<any>();
  const [id_ligacao_interacao, setId_ligacao_interacao] = useState('');

  useEffect(() => {
    const id_ligacao_interacao = query.get('id_ligacao_interacao');
    if (id_ligacao_interacao) {
      setId_ligacao_interacao(id_ligacao_interacao);
    }

    const newConnection = new HubConnectionBuilder()
      .withUrl(UrlServerAPI + '/socket')
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result: any) => {
          console.log('Connected!');
        })
        .catch((e: any) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  useEffect(() => {
    initWebPhone();
  }, []);

  const initWebPhone = async () => {
    // const id_estrategia = localStorage.getItem('@amex:id_estrategia');
    const contato_perfil = localStorage.getItem('@amex:contato_perfil');

    const service = new Service();

    if (contato_perfil) {
      const { error, response } = await service.createWebPhone(
        contato_perfil.replace(/[^0-9\.]+/g, ''),
        true,
      );

      if (!error && response) {
        setUrlIframe(response.url);

        const socket = new serviceSocket();
        await socket.sendEventSocket({
          eventName: `em-ligacao-telefonia-${user.id}`,
          message: JSON.stringify({ emLigacao: true }),
        });
      }
    }
  };

  return (
    <Beforeunload
      onBeforeunload={() => {
        const socket = new serviceSocket();
        socket.sendEventSocket({
          eventName: `disconnect-webphone-ativo-${user.id}`,
          message: JSON.stringify({ emLigacao: false }),
        });

        connection.send(
          'DisconnectWebPhoneAtivo',
          JSON.stringify({
            usuarioId: user.id,
            id_ligacao_interacao: id_ligacao_interacao,
          }),
        );
      }}
    >
      <S.Container>
        {urlIframe !== '' && (
          <iframe
            title="webphone"
            allow="microphone; autoplay"
            height="575"
            width="344"
            src={urlIframe}
          ></iframe>
        )}
      </S.Container>
    </Beforeunload>
  );
};

export default WebPhoneAtivo;
