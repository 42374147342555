import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Collapse } from '@material-ui/core';
import { useStyles } from './styles';
import { getColors } from '@styles/colorsTheme';
import LinkMenu from './LinkActiveMenu';
import ListItem from './ListItemActive';
import { useAuth } from '@hooks/AuthenticateContext';

import {
  routesAdminMenu,
  IRouteMenu,
} from '@router/routes/Cliente.admin.config.routes';
import { routesOperacionalMenu } from '@router/routes/Cliente.operacional.config.routes';
import { routesAdminMenu as routesAMEXAdminMenu } from '@router/routes/Amex.admin.config.routes';

interface IListProps {
  menuOpen: boolean;
  isDesktop: boolean;
  openToMenu?: Function;
}

const SelectedListItem: React.FC<IListProps> = ({
  menuOpen,
  isDesktop,
  openToMenu,
}) => {
  const { user } = useAuth();
  const classes = useStyles();
  const [, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(0);
  const [linkRoutes, setLinkRoutes] = useState<IRouteMenu[]>([]);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
    const value = index === open ? 0 : index;
    setOpen(value);

    isDesktop && openToMenu && openToMenu();
  };

  useEffect(() => {
    if (user.role === 'ADM CLIENTE I') {
      setLinkRoutes(routesAdminMenu);
    } else if (user.role === 'OPERACIONAL CLIENTE I') {
      setLinkRoutes(routesOperacionalMenu);
    } else if (user.role === 'ADM AMEX') {
      setLinkRoutes(routesAMEXAdminMenu);
    }
  }, [user]);

  return (
    <div className={classes.root}>
      <List component="nav">
        {linkRoutes
          .filter(item => item.menu === 'lateral')
          .map((Item: any) => {
            if (!Item.roles.includes(user.role)) {
              return <></>;
            } else {
              return !Item.subMenu ? (
                <LinkMenu key={Item.index} to={Item.path} label={Item.label}>
                  <Item.Icon htmlColor={getColors()?.branco + '80'} />
                </LinkMenu>
              ) : (
                <List key={Item.index}>
                  <ListItem
                    to={Item.path}
                    onClick={() => handleListItemClick(Item.index)}
                  >
                    <div className={classes.itemList}>
                      <ListItemIcon>
                        <Item.Icon htmlColor={getColors()?.branco + '80'} />
                      </ListItemIcon>
                      <ListItemText
                        style={{ color: getColors()?.branco + '80' }}
                        primary={Item.label}
                      />
                    </div>
                  </ListItem>
                  <Collapse
                    in={menuOpen && open === Item.index}
                    timeout="auto"
                    unmountOnExit
                    style={{ backgroundColor: '#BE638D 33' }}
                  >
                    <List component="div" disablePadding>
                      {Item.subMenu.map(
                        (subMenu: any, keySubMenu: number) =>
                          subMenu.roles.includes(user.role) && (
                            <LinkMenu
                              key={keySubMenu}
                              label={subMenu.label}
                              to={subMenu.path}
                            >
                              <ListItemIcon />
                            </LinkMenu>
                          ),
                      )}
                    </List>
                  </Collapse>
                </List>
              );
            }
          })}
      </List>
    </div>
  );
};

export default SelectedListItem;
