import React, { useCallback, useState, useEffect } from "react";

import DashboardServices, { IItemsDashboard } from './services';
import { useSnackbar } from 'notistack';

import BarChartBoxComparativoDoa from "@src/components/Shared/BarChartBoxComparativoDoa";
import BarChartBoxComparativo from "@src/components/Shared/BarChartBoxComparativo";
import { useAuth } from '@hooks/AuthenticateContext';

import {
    Container,
    Content,
} from "./styles";



const Comparativo: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [doacoes, setDoacoes] = useState<IItemsDashboard[]>([]);
    const [cadastros, setCadastros] = useState<IItemsDashboard[]>([]);
    const [doadores, setDoadores] = useState<IItemsDashboard[]>([]);
    const [media_per_capta, setMediaPerCapta] = useState<IItemsDashboard[]>([]);
    const { user, signOut } = useAuth();


    const setValuesOrErrorDashboard = (error: boolean, response: any) => {
        if (!error && response) {
            setDoacoes(response.doacoes);
            setCadastros(response.cadastros);
            setDoadores(response.doadores);
            setMediaPerCapta(response.media_per_capta);

            setLoading(false);
        } else {
            enqueueSnackbar('Ocorreu um erro.', {
                variant: 'error',
                anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            });
            setLoading(false);
        }
    };


    const loadTipoDashboard = async () => {
        setLoading(true);
        const dashboardServices = new DashboardServices();
        const { error, response } = await dashboardServices.loadDashboardComparativo();
        setValuesOrErrorDashboard(error, response);

    };

    //Iniciando os dados no grafico
    useEffect(() => {
        loadTipoDashboard();
        window.document.title = 'CRM - Dashboard';
    }, []);


      //Bloqueio inspecionar
  useEffect(() => {
    if (user.role !== 'ADM AMEX') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [user.role]);

    return (
        <Container>
            {/* COMPONENTES DO GRÁFICO */}
            <Content>
                <BarChartBoxComparativoDoa
                    title="Doações"
                    data={doacoes}
                />

                <BarChartBoxComparativo
                    title="Cadastros"
                    data={cadastros}
                />

                <BarChartBoxComparativo
                    title="Doadores"
                    data={doadores}
                />

                <BarChartBoxComparativoDoa
                    title="Média Per Capta"
                    data={media_per_capta}
                />

            </Content>
        </Container>
    );
}

export default Comparativo;
