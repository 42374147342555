import api from '@http/api';

interface IResponse {
  error: boolean;
  response: {
    id?: number;
  };
}

class CheckExistsDocumentService {
  public async checkDocument(document: string): Promise<IResponse> {
    return await api
      .post('/api/Pessoa/FindPessoaByCpf', {
        documento: document,
      })
      .then(response => {
        console.log(response.data.pessoa.id);
        if (response.data.pessoa.id) {
          return {
            error: false,
            response: { id: response.data.pessoa.id },
          };
        } else {
          return {
            error: true,
            response: {},
          };
        }
      })
      .catch(error => {
        return {
          error: true,
          response: {},
        };
      });
  }
}

export default CheckExistsDocumentService;
