import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { getColors } from '@styles/colorsTheme';

// import { Container } from './styles';
interface IPropsTag {
  color: 'primary' | 'secondary' | 'default';
}

const Tag: React.FC<IPropsTag> = ({ color, children, ...rest }) => {
  const useStyles = makeStyles((style: Theme) =>
    createStyles({
      background: {
        backgroundColor:
          color === 'primary'
            ? style.palette.primary.main
            : color === 'secondary'
            ? style.palette.secondary.main
            : '#616161',
        paddingTop: '12.5px',
        paddingBottom: 5,
        color: getColors()?.branco,
        width: '100%',
        height: '46px',
        alignContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
      },
    }),
  );

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={classes.background}
      style={{ textAlign: 'center' }}
    >
      {children}
    </div>
  );
};

export default Tag;
