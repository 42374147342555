import React, { useEffect, useState, useRef } from 'react';

import { Grid, MenuItem, Button } from '@material-ui/core';

import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { SaveOutlined, CloseOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import { TitlePage } from '@src/@styles/styles.global';


import Service, { IDeleteCorrespDevolvida } from './services';

interface IProps {
  id_correspondencia: number;
  finishDelete?: Function;
  nome: string;
}

const CorrespondenciaComponent: React.FC<IProps> = ({
  id_correspondencia,
  finishDelete,
  nome,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingCreate, setLoadingCreate] = useState(false);

  const [tipoPerfilSelected, setTipoPerfilSelected] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [loadingDeleteCorrespondencia, setLoadingDeleteCorrespondencia] =
    useState(false);

  useEffect(() => {
    const init = async () => {
      const service = new Service();
    };
    init();
    setLoading(false);
  }, []);

  const deleteCorrespondencia = async () => {
    const componentDeleteCorrespondenciaServices = new Service();
    setLoadingDeleteCorrespondencia(true);

    let id: number;

    id = id_correspondencia;

    const deleteCorrespondenciaResponse =
      await componentDeleteCorrespondenciaServices.DeleteCorrespDevolvida({
        id,
      });

    if (!deleteCorrespondenciaResponse.error) {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Correspondência excluída com sucesso!`, {
        title: 'Solicitação recusada com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      if (finishDelete) {
        setLoadingCreate(false);
        await finishDelete();
      }
    } else {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    window.location.reload();
  };

  return (
    <Grid container>
      <TitlePage style={{ margin: 5 }}>Excluir Correspondência</TitlePage>
      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingDeleteCorrespondencia}
          tip={loadingDeleteCorrespondencia ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Form form={formRef} style={{ width: '100%' }} layout="vertical">

            <Grid item lg={12} xs={12} justify="center" alignItems="center">
            <Form.Item>
                      <Input
                        disabled

                        value={nome}
                      />
                    </Form.Item>
              </Grid>

              <Grid item lg={12} xs={12} justify="center" alignItems="center">
              Clique em confirmar para excluir a correspondência devolvida.
              </Grid>

              <Grid item lg={12} xs={12}></Grid>

              <Grid container justify="center" alignItems="center">
                <Grid item sm={6} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="tertiary"
                    fullWidth
                    onClick={deleteCorrespondencia}
                  >
                    Confirmar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default CorrespondenciaComponent;
