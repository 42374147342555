import React from 'react';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis
} from 'recharts';

import { Container, ChartContainer } from './styles';

interface IBarChartProps {
    title: string;
    data: {
        descricao: string,
        janeiro: number,
        fevereiro: number,
        marco: number,
        abril: number,
        maio: number,
        junho: number,
        julho: number,
        agosto: number,
        setembro: number,
        outubro: number,
        novembro: number,
        dezembro: number,
    }[],
}


const BarChartBox: React.FC<IBarChartProps> = ({ title, data }) => (
    <Container>
        <span>{title}</span>
        <ChartContainer>
            <ResponsiveContainer>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="descricao" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="janeiro" fill="#6D6A93" />
                    <Bar dataKey="fevereiro" fill="#D49EB9" />
                    <Bar dataKey="marco" fill="#6D6A93" />
                    <Bar dataKey="abril" fill="#D49EB9" />
                    <Bar dataKey="maio" fill="#6D6A93" />
                    <Bar dataKey="junho" fill="#D49EB9" />
                    <Bar dataKey="julho" fill="#6D6A93" />
                    <Bar dataKey="agosto" fill="#D49EB9" />
                    <Bar dataKey="setembro" fill="#6D6A93" />
                    <Bar dataKey="outubro" fill="#D49EB9" />
                    <Bar dataKey="novembro" fill="#6D6A93" />
                    <Bar dataKey="dezembro" fill="#D49EB9" />
                </BarChart>
            </ResponsiveContainer>
        </ChartContainer>
    </Container>
);

export default BarChartBox;