import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Select, Form } from 'antd';
import Services from './services';
import { useEntidade } from '@hooks/EntidadeContext';
import { useMediaQuery } from '@material-ui/core';

const SelectEntidade: React.FC = () => {
  const [mobile, setMobile] = useState(false);
  const matches = useMediaQuery('(max-width: 800px)');
  const [formRef] = Form.useForm();
  const { Option } = Select;
  const { entidade, updateEntidade } = useEntidade();
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [values, setValues] = useState<any[]>(suggestions);

  function onChange(valor: any) {
    formRef.setFieldsValue({
      entidade: valor,
    });

    updateEntidade(SearchGetObjEntidade(valor));

    const { descricao, id } = SearchGetObjEntidade(valor);

    if (descricao != entidade.descricao && id != entidade.id) {
      window.location.reload();
    }
  }

  const load = async () => {
    const services = new Services();

    const { error, response } = await services.getEntidades();

    const entidadeStorage = localStorage.getItem('@amex:entidade');

    if (!error && response) {
      setSuggestions(response);
      setValues(response);

      if (entidadeStorage) {
        const entidadeStorageJSON = JSON.parse(entidadeStorage);

        updateEntidade(entidadeStorageJSON);

        formRef.setFieldsValue({
          entidade: entidadeStorageJSON.id.toString(),
        });
      } else {
        formRef.setFieldsValue({
          entidade: response[0].value,
        });
      }
    }
  };

  useEffect(() => {
    if (entidade && entidade?.id && entidade?.descricao) {
      formRef.setFieldsValue({
        entidade: entidade.id,
      });
    }
  }, [entidade]);

  useEffect(() => {
    load();
  }, []);

  const handleSearch = (value: string) => {
    if (value) {
      setValues(
        suggestions.filter(suggestion =>
          JSON.stringify(suggestion)
            .toLowerCase()
            .includes(value.toLowerCase()),
        ),
      );
    } else {
      setValues(suggestions);
    }
  };

  const SearchGetObjEntidade = (value: string) => {
    const values = suggestions.filter(suggestion =>
      JSON.stringify(suggestion)
        .toLowerCase()
        .includes(value.toString().toLowerCase()),
    );

    return { descricao: values[0].label, id: values[0].value };
  };

  useLayoutEffect(() => {
    setMobile(matches);
  }, [matches]);

  const options = values.map(d => (
    <Option
      key={d.value}
      value={d.value}
      style={{
        textAlign: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      {d.label}
    </Option>
  ));

  return (
    <>
      <Form
        style={{
          width: mobile ? '200%' : 300,
          padding: 0,
        }}
        form={formRef}
        size="middle"
        layout="vertical"
        scrollToFirstError={true}
        onFinish={() => {}}
        requiredMark={false}
      >
        <Form.Item name="entidade" label="Cliente">
          <Select
            showSearch
            style={{
              width: mobile ? '200%' : 280,
              textAlign: 'center',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '12px',
            }}
            placeholder="Select a person"
            filterOption={false}
            onChange={onChange}
            onSearch={handleSearch}
          >
            {options}
          </Select>
        </Form.Item>
      </Form>
    </>
  );
};

export default SelectEntidade;
