import React, { useLayoutEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { CloseOutlined } from '@material-ui/icons';
import { Grid, useMediaQuery } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
// import { CssButton } from '../Button/styles';
import { getColors } from '@styles/colorsTheme';

interface IPropsModal {
  size: 'pequeno' | 'medio' | 'grande' | 'gigante';
  color?: 'primary' | 'secondary';
  titleButton: string;
  Icon?: React.ComponentType<{}>;
}

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SimpleModal: React.FC<IPropsModal> = ({
  children,
  size,
  color,
  titleButton,
  Icon,
}) => {
  const [sizeModal, setSizeModal] = useState('');
  const matches = useMediaQuery('(max-width: 600px)');

  useLayoutEffect(() => {
    if (matches) {
      setSizeModal('100%');
    } else {
      if (size === 'gigante') {
        setSizeModal('56%');
      } else if (size === 'grande') {
        setSizeModal('45%');
      } else if (size === 'pequeno') {
        setSizeModal('15%');
      } else {
        setSizeModal('30%');
      }
    }
  }, [matches, size]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      paper: {
        position: 'absolute',
        width: sizeModal,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
      },
    }),
  );

  const CssButton = withStyles({
    root: {
      maxWidth: '97%',
      height: 50,
      fontSize: 15,
      textTransform: 'none',
      borderRadius: 0,
      '&:disabled': {
        backgroundColor: '#BE638D 80',
      },
    },
  })(Button);

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [modalOpen, setModalOpen] = React.useState(false);

  function handleModalClose() {
    setModalOpen(false);
  }
  function handleModalOpen() {
    setModalOpen(true);
  }

  return (
    <>
      <CssButton
        variant="contained"
        color={!color ? 'primary' : color}
        fullWidth
        onClick={handleModalOpen}
        style={{ margin: 5 }}
      >
        <Grid container justify={'center'} alignItems="center">
          {Icon && (
            <Grid
              container
              item
              sm={6}
              xs={6}
              justify="center"
              alignItems="center"
            >
              {Icon && <Icon />}
            </Grid>
          )}

          <Grid
            container
            item
            sm={Icon ? 6 : 12}
            xs={Icon ? 6 : 12}
            justify={Icon ? 'flex-start' : 'center'}
            alignItems="center"
          >
            <p style={{ textAlign: Icon ? 'left' : 'center' }}>{titleButton}</p>
          </Grid>
        </Grid>

        {/* <div style={{ position: 'absolute', left: 20, marginTop: 2 }}>

        </div>

        {titleButton} */}
      </CssButton>

      <Modal open={modalOpen} onClose={handleModalClose}>
        <div style={modalStyle} className={classes.paper}>
          <Grid container direction="row" justify="flex-end">
            <button
              style={{ border: 'none', backgroundColor: getColors()?.branco }}
              onClick={handleModalClose}
            >
              <CloseOutlined
                fontSize="large"
                htmlColor={getColors()?.azulEscuro}
              />
            </button>
          </Grid>
          <div style={{ paddingLeft: 20, paddingRight: 20 }}>{children}</div>
        </div>
      </Modal>
    </>
  );
};

export default SimpleModal;
