import React, { useState } from 'react';
import Tour from 'reactour';
import Cidades from '@components/Shared/EstadoCidadeInputs';
import { getColors } from '@styles/colorsTheme';
import { Form } from 'antd';

const steps = [
  {
    selector: '.first-page-example-step',
    content: 'Primeiro selecione um estado',
  },
  {
    selector: '.first-page-example-step2',
    content: 'Agora podemos selecionar uma cidade ',
    action: () => {
      console.log({ toggle: true });
    },
  },
  {
    selector: '#first-page-example-step3',
    content: 'TESTE ',
    action: () => {
      console.log({ toggle: true });
    },
  },
];

const ControlTour: React.FC = () => {
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [step, setStep] = useState(0);

  const nextStep = () => {
    const nextStep = step + 1;
    setStep(nextStep);
    if (nextStep >= steps.length) {
      setIsTourOpen(false);
    }
  };

  return (
    <>
      <button
        style={{
          position: 'fixed',
          right: 50,
          bottom: 50,
          background: '#000',
          color: getColors()?.branco,
          padding: 10,
          width: 50,
          height: 50,
          borderRadius: '50%',
        }}
        onClick={() => {
          setStep(0);
          setIsTourOpen(!isTourOpen);
        }}
      >
        ?
      </button>

      <Tour
        goToStep={step}
        steps={steps}
        isOpen={isTourOpen}
        className="helper"
        accentColor={'#5cb7b7'}
        onRequestClose={() => setIsTourOpen(false)}
        disableFocusLock={true}
        closeWithMask={false}
        // nextButton={
        //   <button style={{ display: 'none' }} id="_tour_next_btn">
        //     Próximo
        //   </button>
        // }
        // getCurrentStep={teste => console.log(teste)}
      />

      <div id="first-page-example-step3">TESTE</div>

      <Form>
        <Cidades setStep={nextStep} />
      </Form>
    </>
  );
};

export default ControlTour;
