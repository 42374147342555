import api from '@http/api';

interface IRequestData {
  id: number;
  role: string;
  nome: string;
}

export interface IUser {
  id: number;
  role: string;
  nome: string;
}

export interface IListUser {
  usuario: IUser;
}

interface IResponse {
  error: boolean;
  response?: IUser[];
}
class PageUsersServices {
  public async ListUsuarios(): Promise<IResponse> {
    return await api
      .get('/api/Home/usuarios')
      .then(response => {
        const usuarios = response.data;

        var itemUsuario: IUser[] = [];

        usuarios.map((item: IRequestData) => {
          return itemUsuario.push({
            id: item.id,
            role: item.role,
            nome: item.nome,
          });
        });

        return {
          error: false,
          response: itemUsuario,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default PageUsersServices;
