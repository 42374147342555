import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';




export interface IPerfil2 {
  id: string;
  descricao: string;
  status_ativo: boolean;
}




export interface IRequestCreatePessoa {
  nome: string;
  nome_fantasia: string;
  data_nascimento: string;
  documento: string;
  sexo: string;
  documento_indicador?: string;
  status_ativo?: number;
  id_pessoa_responsavel: number;
  id_pessoa_indicador: number;
  nome_pessoa_responsavel: string;
  nome_pessoa_indicador: string;
}




export interface IResponseRequestPessoaByCPF {
  pessoa: {
    id: string;
    documento: string;
    nome: string;
    perfis: IPerfil[];
    tipos_perfis: string[];
  };
}

export interface IResponsePessoaByCPF {
  response?: {
    pessoa: {
      id: string;
      nome: string;
      documento: string;
      perfis: IPerfil[];
      tipos_perfis: string[];
    };
  };
  error: boolean;
}








export interface IResponseCreatePessoa {
  id: number;
  messageError?: any;

}








class PageEditCadastroPessoaServices {
  public async GetTiposPerfil() {
    return await api
      .get<IPerfil2[]>('api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }




  public async CreatePessoa(pessoa: IRequestCreatePessoa) {
    return await api
      .post<IResponseCreatePessoa>('/api/Pessoa/CreatePessoa', pessoa)
      .then(response => {
        //const id = response.data.id;




        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {


        return {
          error: true,
          response: null,
          messageError: {
            data: error.data,
          },
        };
      });
  }




  public async getPessoaByID(id: number): Promise<IResponsePessoaByCPF> {
    return await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }








  public async getPessoaByDocumento(
    documento: string,
  ): Promise<IResponsePessoaByCPF> {
    return await api
      .post<IResponseRequestPessoaByCPF>(`api/Pessoa/FindPessoaByCpf`, {
        documento,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}




export default PageEditCadastroPessoaServices;
