import styled, {keyframes} from 'styled-components';

interface ILegendProps {
    color: string;
}

const animate = keyframes`
    0% {
        transform: translateX(-100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div`
    width: 48%;
    height: 200px;

    display: flex;
    flex-direction: column;

    border-radius: 7px;
    background: #EFEEF2;
    color:#36316a;

    margin: 10px 0;

    >span{
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
    }

    animation: ${animate} .5s;
`;

export const ChartContainer = styled.div`
     flex: 1;
     height: 300px;   
     margin-top: 10px; 
     padding-top: 30px;
`;

export const Header = styled.header`
    width: 100%;
   
    display: flex;
    justify-content: space-between;

    > h2 {
        margin-bottom: 20px;
        padding-left: 16px;
    }


    @media(max-width: 1200px){
        flex-direction: column;
    }
`;
