import React, { useMemo } from 'react';
import { Container } from './styles';
import CountUp from 'react-countup';


import cadastroImg from '@src/Assets/cadastro.svg';
import doacaoImg from '@src/Assets/doacao.svg';
import totalDoacaoImg from '@src/Assets/totalDoacao.svg';
import mediaPerCapta from '@src/Assets/media.svg';
import porcentagemImg from '@src/Assets/porcentagem.svg';

import { size } from 'polished';




interface IWalletBoxProps {
  title: string;
  amount: any;
  icon: 'porcentagem' | 'doacao' | 'totalDoacao' | 'mediaPerCapta';
  color: string;
  style?: React.CSSProperties;
}


const WalletBox: React.FC<IWalletBoxProps> = ({
  title,
  amount,
  icon,
  style,
  color }) => {


  const iconSelected = useMemo(() => {
    switch (icon) {
      case 'porcentagem':
        return porcentagemImg;
      case 'doacao':
        return doacaoImg;
      case 'totalDoacao':
        return totalDoacaoImg;
      case 'mediaPerCapta':
        return mediaPerCapta;
      default:
        return undefined;
    }
  }, [icon]);


  const moeda = useMemo(() => {
    if (title == "Valor total das doações" || title == "Meta de doação" || title == 'Para atingir a meta faltam,' || title == "Valor excedente da meta") {
      return "R$";
    } else {
      return "";
    }
  }, [title]);

  const decimal = useMemo(() => {
    if (title == "Valor total das doações" || title == "Meta de doação"  || title == "% das doações"
    || 'Para atingir a meta faltam,' || title == "Valor excedente da meta") {
      return ",";
    } else {
      return "";
    }
  }, [title]);

  const casasDecimais = useMemo(() => {
    if (title == "Valor total das doações" || title == "Valor total das doações" || title == "% das doações" || 'Para atingir a meta faltam,' || title == "Valor excedente da meta") {
      return 2;
    } else {
      return 0;
    }
  }, [title]);

   return (
    <Container color={color}>
      <span style={{fontSize: '18px', paddingBottom: 1}}>{title}</span>
      <h1>
        <strong style={{paddingTop: 5}}>{moeda}
          <CountUp style={{ fontSize: '18px'}}
            end={amount}
            separator="."
            decimal={decimal}
            decimals={casasDecimais}
          />
        </strong>
      </h1>

      <img src={iconSelected} alt={title} />

    </Container>
  )
}

export default WalletBox;
