import React from 'react';

import {
    ResponsiveContainer,
    BarChart,
    Bar,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend
} from 'recharts';

import { Container, ChartContainer } from './styles';

import { CurrencyFormat } from '@utils/formats';

interface IBarChartProps {
    title: string;
    data: {
        mes: string,
        ano_atual: number;
        ano_passado: number;
        ano_retrasado: number;
    }[],
}


const formatYears = () => {
    const anoAtual = new Date().getFullYear();
    const anoPassado = anoAtual - 1;
    const anoRetrasado = anoAtual - 2;

    return {
      anoAtual,
      anoPassado,
      anoRetrasado,
    };
  };

const BarChartBoxComparativoDoa: React.FC<IBarChartProps> = ({ title, data}) => (
    <Container>
        <span>{title}</span>
        <ChartContainer>
            <ResponsiveContainer>
                <BarChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="mes" />
                    <YAxis />
                    <Tooltip formatter={data => CurrencyFormat(data)} />
                    <Legend />
                    <Bar dataKey="ano_retrasado" name={formatYears().anoRetrasado} fill="#6D6A93" />
                    <Bar dataKey="ano_passado" name={formatYears().anoPassado} fill="#D49EB9" />
                    <Bar dataKey="ano_atual" name={formatYears().anoAtual} fill="#CF4D6D" />
                </BarChart>
            </ResponsiveContainer>
        </ChartContainer>
    </Container>
);

export default BarChartBoxComparativoDoa;
