import api from '@http/api';

export interface IDetalhesDebitoTable {
  id_pessoa: number | string;
  Nome: String;
  documento: String;
  tipo_perfil: String;
  valor: String;
  data: String;
}

export interface IDetalhesDebito {
  modelo: IDetalhesDebitoTable[];
}

class Services {
  public async DetalhesDebito(
    pageNumber: number,
    pageSize: number,
    codigoMovimentoId: string | number,
    convenioId: string | number,
    data: string,
  ) {
    return await api
      .get<IDetalhesDebito>(
        `api/Financeiro/GetFinanceiroDetalhesPaginados?pageNumber=${pageNumber}&pageSize=${pageSize}&codigoMovimentoId=${codigoMovimentoId}&data=${data}&convenioId=${convenioId}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data.modelo,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }
}

export default Services;
