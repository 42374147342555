import api from '@http/api';
import { FormatDate } from '@utils/formats';
import { MaskCPFeCNPJ } from '@utils/Masks';

interface IRequestData {
  data: {
    id: number;
    sara_id: number;
    id_perfil: number;
    nome: string;
    cpf: string;
    email: string;
    telefone: string;
    page_number: number;
    page_size: number;
  };
}

export interface IPessoa {
  id: number;
  nome: string;
  data_cadastro: string;
  documento: string;
}

export interface IRequestPessoas {
  pessoa: IPessoa;
}

export interface IOrigemEspecifica {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IFormaContribuicao {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IMotivo {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface IPerfil {
  id: number;
  descricao: string;
  status_ativo: boolean;
}

export interface IBuscaDetalhada {
  forma_contribuicao_id: number;
  tipo_perfil_id: number;
  origem_especifica_id: number;
  motivo_id: number;
  estado_sigla: string;
  cidade_id: number;
  data_inicial: string;
  data_final: string;
  pageNumber: number;
  pageSize: number;
}

export interface IResponseGetCidadesByUf {
  cidade_id: string;
  cidade_nome: string;
}

export interface ICidade {
  id: string | number;
  label: string;
}

interface IResponse {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    pessoas: IPessoa[];
  };
}

class PageListPessoasServices {
  public async loadPessoas({ data }: IRequestData): Promise<IResponse> {
    return await api
      .post('api/Pessoa/FindPessoaByIdCpfNome', data)
      .then(response => {
        const pessoas = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemPessoas: IPessoa[] = [];

        pessoas.map((item: IRequestPessoas) => {
          return itemPessoas.push({
            id: item.pessoa.id,
            documento: MaskCPFeCNPJ(item.pessoa.documento).valueModify,
            nome: item.pessoa.nome,
            data_cadastro: FormatDate(item.pessoa.data_cadastro),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, pessoas: itemPessoas },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadOrigemEspecifica() {
    return await api
      .get<IOrigemEspecifica[]>('/api/OrigemEspecifica/GetOrigensEspecificas')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadMotivos() {
    return await api
      .get<IMotivo[]>('/api/MotivoCadastro/GetMotivosCadastros')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetTiposPerfil() {
    return await api
      .get<IPerfil[]>('api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async getCidadesByUF(estado: string) {
    return await api
      .post<IResponseGetCidadesByUf[]>('/api/ContatoOffline/FindCidades', {
        uf: estado,
      })
      .then(response => {
        let reescrito: ICidade[] = [];

        response.data.map(cidade => {
          const obj: ICidade = {
            id: cidade.cidade_id,
            label: cidade.cidade_nome,
          };

          return reescrito.push(obj);
        });

        return {
          error: false,
          response: reescrito,
        };
      })
      .catch(() => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async buscaDetalhada({
    cidade_id,
    data_final,
    data_inicial,
    motivo_id,
    estado_sigla,
    forma_contribuicao_id,
    origem_especifica_id,
    tipo_perfil_id,
    pageNumber,
    pageSize,
  }: IBuscaDetalhada) {
    return await api
      .post('api/RelatorioConsulta/CadastrosFiltrosPersonalizado', {
        cidade_id,
        data_final,
        data_inicial,
        motivo_id,
        estado_sigla,
        forma_contribuicao_id,
        origem_especifica_id,
        tipo_perfil_id,
        pageNumber,
        pageSize,
      })
      .then(response => {
        const pessoas = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemPessoas: IPessoa[] = [];

        pessoas.map((item: IPessoa) => {
          return itemPessoas.push({
            id: item.id,
            documento: MaskCPFeCNPJ(item.documento).valueModify,
            nome: item.nome,
            data_cadastro: FormatDate(item.data_cadastro),
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, pessoas: itemPessoas },
        };
      })
      .catch(error => {
        console.log('caiu aqui', error);

        return {
          error: true,
          response: [],
        };
      });
  }
}

export default PageListPessoasServices;
