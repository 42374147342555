import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';

import Tag from '@components/Shared/Tag';
import ButtonComponent from '@components/Shared/Buttons/Button';
import { ClearOutlined, SaveOutlined } from '@material-ui/icons';

import { Spin, Form, Input, Select as SelectAntd } from 'antd';
import { useSnackbar } from 'notistack';
import Services from './services';

interface IProps {
  Cancel: Function;
  Done: Function;
  id: number;
}

interface IUserType {
  id: number;
  descricao: string;
}

interface IUsuarioOld {
  id?: number;
  nome?: string;
  email?: string;
  senha?: string;
  nova_senha?: string;
  confirmacao_nova_senha?: string;
  status_ativo?: number;
  entidade_id?: number;
  tipo_usuario_id?: number;
  role?: string;
}

const senhaforte = RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
);

const EditUserForm: React.FC<IProps> = ({ Cancel, id, Done }) => {
  const [formRef] = Form.useForm();
  const [loadingCadastro, setLoadingCadastro] = useState(false);
  const [userType, setUserType] = useState<IUserType[]>([]);
  const [user, setUser] = useState<IUsuarioOld>();
  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit(values: any) {
    if (
      (await updateUserPassword(values)) &&
      (await updateUserNameEmail(values)) &&
      (await updateUserRole(values))
    ) {
      Done();
    }
  }

  function handleCancel() {
    Cancel();
  }

  async function updateUserPassword(values: any) {
    const services = new Services();
    if (
      !(values.senha === undefined) &&
      !(values.confirmaSenha === undefined)
    ) {
      const user: IUsuarioOld = {
        id: id,
        nova_senha: values.senha,
        confirmacao_nova_senha: values.confirmaSenha,
      };

      const { error, response, messageError } = await services.UpdateUserPassword(user);

      if (!error) {
        enqueueSnackbar('Senha alterada com sucesso!', {
          title: 'Senha alterada com sucesso!',
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return true;
      } else if (error && messageError.data) {
        enqueueSnackbar(
          `A Nova Senha não pode ser igual a senha Antiga.`,
          {
            title: `A Nova Senha não pode ser igual a senha Antiga. `,
            variant: 'warning',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          },
        );
      }
       else {
        enqueueSnackbar('Erro ao alterar senha!', {
          title: 'Erro ao alterar senha!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return false;
      }
    } else {
      return true;
    }
  }

  async function updateUserNameEmail(values: any) {
    const services = new Services();
    if (
      (!(values.nome === user?.nome) && values.nome) ||
      (!(values.email === user?.email) && values.email)
    ) {
      const user: any = {
        id: id,
        nome: values.nome,
        email: values.email,
      };

      const { error, response } = await services.UpdateUserNomeEmail(user);

      if (!error) {
        enqueueSnackbar('Nome/E-mail alterado com sucesso!', {
          title: 'Nome/E-mail alterado com sucesso!',
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return true;
      } else {
        enqueueSnackbar('Erro ao alterar Nome/E-mail!', {
          title: 'Erro ao alterar Nome/E-mail!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return false;
      }
    } else {
      return true;
    }
  }

  async function updateUserRole(values: any) {
    const services = new Services();
    if (!(values.tipo_usuario_id === user?.tipo_usuario_id)) {
      const user: any = {
        id: id,
        status_ativo: 1,
        tipo_usuario_id: values.tipo_usuario_id,
      };

      const { error, response } = await services.UpdateUserRole(user);

      if (!error) {
        enqueueSnackbar('Tipo de usuário alterado com sucesso!', {
          title: 'Tipo de usuário alterado com sucesso!',
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return true;
      } else {
        enqueueSnackbar('Erro ao alterar tipo de usuário!', {
          title: 'Erro ao alterar tipo de usuário!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return false;
      }
    } else {
      return true;
    }
  }

  const firstLoad = useCallback(async () => {
    const services = new Services();
    const { error, response } = await services.GetTiposUsuarios();

    if (!error && response) {
      setUserType(response);
    } else {
      console.log('error', error);
    }
  }, []);

  const loadUsuario = useCallback(async id => {
    setLoadingCadastro(true);
    const services = new Services();
    const { error, response } = await services.GetUserByID({ userID: id });

    if (!error && response) {
      const usuario: IUsuarioOld = {
        id: response?.id,
        nome: response?.nome,
        email: response?.email,
        tipo_usuario_id: response?.tipo_usuario_id,
        role: response?.role,
      };

      setUser(usuario);

      formRef.setFieldsValue({
        nome: usuario?.nome,
        email: usuario?.email,
        tipo_usuario_id: usuario?.tipo_usuario_id,
        role: usuario?.role,
      });
      setLoadingCadastro(false);
    } else {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    firstLoad();
    loadUsuario(id);
  }, [id]);

  return (
    <>
      <Grid item sm={7} xs={12}>
        <Tag color="default">Cadastro: {id}</Tag>
      </Grid>
      <Spin size="large" spinning={loadingCadastro} tip="Enviando dados...">
        <Form
          size="middle"
          layout="vertical"
          scrollToFirstError={true}
          onFinish={onSubmit}
          labelAlign="left"
          form={formRef}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          requiredMark={false}
        >
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Form.Item
                name="tipo_usuario_id"
                label="Tipo de usuário"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione um Tipo de usuário',
                  },
                ]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <SelectAntd placeholder="Tipo de usuário">
                  {userType?.map(type => (
                    <SelectAntd.Option key={type.id} value={type.id}>
                      {type.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Nome"
                hasFeedback
                name="nome"
                rules={[{ required: true, message: 'Campo obrigatorio' }]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Nome" />
              </Form.Item>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Form.Item
                label="E-mail"
                hasFeedback
                name="email"
                rules={[{ required: true, message: 'Campo obrigatorio' }]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
            </Grid>

            {/*<Grid item lg={12} xs={12}>
              <Form.Item
                label="Senha Atual"
                hasFeedback
                name="senhaOld"
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Senha Atual" type="password" />
              </Form.Item>
            </Grid>*/}
            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Nova senha"
                hasFeedback
                name="senha"
                rules={[
                  {
                    required: false,
                    pattern: senhaforte,
                    message: 'Senha forte necessária',
                  },
                ]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Nova senha" type="password" />
              </Form.Item>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Confirmação de Senha"
                hasFeedback
                name="confirmaSenha"
                rules={[
                  { required: false },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('senha') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('As senhas não batem!'));
                    },
                  }),
                ]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Confirmação de senha" type="password" />
              </Form.Item>
            </Grid>

            <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
              <ButtonComponent
                color="primary"
                disabled={loadingCadastro}
                loading={loadingCadastro}
                fullWidth
                type="submit"
              >
                <SaveOutlined style={{ marginRight: 10 }} /> Salvar
              </ButtonComponent>
            </Grid>
            <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
              <ButtonComponent
                color="default"
                disabled={loadingCadastro}
                loading={loadingCadastro}
                fullWidth
                onClick={handleCancel}
              >
                <ClearOutlined style={{ marginRight: 10 }} />
                Cancelar
              </ButtonComponent>
            </Grid>
          </Grid>
        </Form>
      </Spin>
    </>
  );
};

export default EditUserForm;
