import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { SubTitleBold } from '@src/@styles/styles.global';
import { MaskCPFeCNPJ } from '@utils/Masks';
import { AddOutlined, Visibility } from '@material-ui/icons';

import { IPerfil } from '@interfaces/UserComplete';
import useDebounce from '@hooks/useDebounce';
import Services from './services';

interface IProps {
  pessoa_id: number;
  id_responsavel: number;
  nome_responsavel: string;
  id_indicador: number;
  nome_indicador: string;
  finishDelete?: Function;
}

const optionsFilter = ['ID', 'CPF'];

const EditCadastro:React.FC<IProps> = ({
  pessoa_id,
  finishDelete,
  id_responsavel,
  id_indicador,
  nome_indicador,
  nome_responsavel,
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDeletePerfil, setLoadingDeletePerfil] = useState(false);


async function onSubmit(values: any) {
  const componentPerfilServices = new Services();
  setLoadingDeletePerfil(true);

  //const updateTipoPerfil: any = {
  //  tipo_perfil_id: values.id,
  //};

  const updatePerfilResponse = await componentPerfilServices.DeleteIndicador({
    pessoa: {
      id: pessoa_id,
      id_pessoa_responsavel: id_responsavel ,
      id_pessoa_indicador: 0,
      nome_pessoa_responsavel: nome_responsavel,
      nome_pessoa_indicador: '',
    },

  });

  if (!updatePerfilResponse.error) {
    setLoadingDelete(false);
    setLoading(false);
    enqueueSnackbar(`Responsável deletado com sucesso!`, {
      title: 'Responsável deletado com sucesso!',
      variant: 'success',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });

    if (finishDelete) {
      setLoadingDelete(false);
      await finishDelete();
    }
  } else {
    setLoadingDelete(false);
    setLoading(false);
    enqueueSnackbar(`Ocorreu um erro`, {
      title: 'Ocorreu um erro ',
      variant: 'error',
      anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
    });
  }
}

  return (
    <Grid container>

      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingDeletePerfil}
          tip={loadingDeletePerfil ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Form
              form={formRef}
              scrollToFirstError={true}
              onFinish={onSubmit}
              requiredMark={false}
              style={{ width: '100%' }}
              layout="vertical"
            >

              <Grid item container justify="space-around" sm={12} xs={12} lg={12}>

                <Grid container>
                  <Grid
                    item
                    md={3}
                    lg={3}
                    xs={12}
                    style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                  >
                    <Form.Item>
                      <Input
                        disabled
                        placeholder="Código do responsável"
                        value={id_indicador}
                      />
                    </Form.Item>
                  </Grid>

                  <Grid
                    item
                    md={9}
                    lg={9}
                    xs={12}
                    style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                  >
                    <Form.Item>
                      <Input
                        disabled
                        placeholder="Nome do responsável"
                        value={nome_indicador}
                      />
                    </Form.Item>

                  </Grid>



                </Grid>

              </Grid>

              <Grid item container justify="space-around" sm={12} xs={12} lg={12}>

                <Grid container>

                  <Grid
                    item
                    md={12}
                    lg={12}
                    xs={12}
                    style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                  >
                   <p style={{fontSize: 20}}><strong> Clique em confirmar para deletar seu indicador.</strong> </p>

                  </Grid>



                </Grid>

              </Grid>

              <Grid container justify="center" alignItems="center">
                <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={loadingDelete}
                    loading={loadingDelete}
                    fullWidth
                    type="submit"
                  >
                    <SaveOutlined style={{ marginRight: 10 }} /> Confirmar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default EditCadastro;
