import React, { useEffect } from 'react';
import { Drawer, IconButton, Divider } from '@material-ui/core/';
import clsx from 'clsx';
import { useStyles } from './styles';
import { Close } from '@material-ui/icons';
import { getColors } from '@styles/colorsTheme';

interface FiltrosProps {
  abrir: boolean;
  openMenu: Function;
}

const Filtros: React.FC<FiltrosProps> = ({ openMenu, abrir, children }) => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    right: false,
  });

  const handleOption = () => {
    setState({ ...state, ['right']: abrir });
  };

  useEffect(() => {
    handleOption();
  }, [abrir]);

  type Anchor = 'top' | 'left' | 'bottom' | 'right';

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      setState({ ...state, [anchor]: open });
      openMenu();
    };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      style={{ overflowX: 'hidden', overflowY: 'hidden' }}
    >
      {children}
    </div>
  );

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor={'right'}
        open={state['right']}
        classes={{
          paper: classes.drawerPaper,
        }}
        SlideProps={{ direction: 'left' }}
        style={{ zIndex: 1000, overflowX: 'hidden', overflowY: 'hidden' }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={toggleDrawer('right', false)}>
            <Close htmlColor={getColors()?.branco} />
          </IconButton>
        </div>
        <Divider />
        {list('right')}
      </Drawer>
    </>
  );
};

export default Filtros;
