import React, { useEffect, useState, useRef } from 'react';

import { Grid, MenuItem, Button } from '@material-ui/core';

import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { SaveOutlined, CloseOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';
import { TitlePage } from '@src/@styles/styles.global';


import Service, { IAproveCorrespDevolvida } from './services';

interface IProps {
  id: any;
  finishAprove?: Function;
}

const CorrespondenciaComponent: React.FC<IProps> = ({
  id,
  finishAprove,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingCreate, setLoadingCreate] = useState(false);

  const [tipoPerfilSelected, setTipoPerfilSelected] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [loadingAproveCorrespondencia, setLoadingAproveCorrespondencia] =
    useState(false);

  useEffect(() => {
    const init = async () => {
      const service = new Service();
    };
    init();
    setLoading(false);
  }, []);

  const aprovaCorrespondencia = async () => {

    const componentAproveCorrespondenciaServices = new Service();
    setLoadingAproveCorrespondencia(true);

    const aproveCorrespondenciaResponse =
      await componentAproveCorrespondenciaServices.AproveCorrespDevolvida({id});

    if (!aproveCorrespondenciaResponse.error) {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Correspondência excluída com sucesso!`, {
        title: 'Solicitação recusada com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      if (finishAprove) {
        setLoadingCreate(false);
        await finishAprove();
      }
    } else {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    window.location.reload();
  };


  return (
    <Grid container>
      <TitlePage style={{ margin: 5 }}>Aprovar Correspondência</TitlePage>
      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingAproveCorrespondencia}
          tip={loadingAproveCorrespondencia ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
           <Form
          form={formRef}
          scrollToFirstError={true}
          //onFinish={onSubmit}
          requiredMark={false}
          style={{ width: '100%' }}
          layout="vertical"
        >

            <Grid item lg={12} xs={12} justify="center" alignItems="center">
            <Form.Item>
                      <Input
                        disabled

                        value={id}
                      />
                    </Form.Item>
              </Grid>

              <Grid item lg={12} xs={12} justify="center" alignItems="center">
              Clique em confirmar para aprovar a(s) correspondência(s) devolvida(s).
              </Grid>

              <Grid item lg={12} xs={12}></Grid>

              <Grid container justify="center" alignItems="center">
                <Grid item sm={6} xs={12} style={{ paddingTop: 10 }}>
                <ButtonComponent
                color="primary"
                onClick={aprovaCorrespondencia}
                fullWidth
                type="submit"
              >
                <SaveOutlined style={{ marginRight: 10 }} /> Salvar
              </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default CorrespondenciaComponent;
