import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// import { getRouteByID } from '@hooks/getFuncionalidades';

import {
  routesOperacional,
  IRoute,
} from '../routes/Amex.operacional.config.routes';

import { useAuth } from '@hooks/AuthenticateContext';

function RenderRoutes(route: IRoute, key: number, user: any) {
  // if (getRouteByID(route.id)) {

  if (route.roles.includes(user.role)) {
    return (
      <Route key={key} path={route.path} exact component={route.component} />
    );
  }
  // }
}

function RouteAmexOperacional() {
  const { user } = useAuth();
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Redirect to={routesOperacional[0].path} />}
        />

        {routesOperacional.map((route, i) => RenderRoutes(route, i, user))}

        <Route
          path="*"
          component={() => {
            return <Redirect to={routesOperacional[0].path} />;
          }}
        />
      </Switch>
    </div>
  );
}

export default RouteAmexOperacional;
