import api from '@http/api';

export interface IRequestSolicitacao {
  id_perfil: number;
  id_tipo_solicitacao: number;
  observacoes: string;
  valor: number;
  data_vencimento: string;
  id_campanha: number;
}

export interface IPerfil {
  id: number;
  descricao: string;
  status_ativo: boolean;
}

export interface ITipoSolicitacao {
  id: string;
  descricao: string;
}

export interface ICampanha {
  id: number;
  descricao: string;
}

interface IResponseCampanha {
  error: boolean;
  response?: ICampanha[];
}

class ComponentSolicitacaoServices {
  public async GetTiposPerfil() {
    return await api
      .get<IPerfil[]>('api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async CreateSolicitacao(data: IRequestSolicitacao) {
    return await api
      .post('api/Solicitacao/CreateSolicitacao', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async loadTiposSolicitacao(id: number) {
    return await api
      .get<ITipoSolicitacao[]>(
        '/api/Solicitacao/getTipoSolicitacaoByPerfilID/' + id,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async getCampanhas(): Promise<IResponseCampanha> {
    return await api
      .get(`/api/Campanha/GetCampanhasDeSolicitacao`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ComponentSolicitacaoServices;
