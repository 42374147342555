import api from '@http/api';
import moment from 'moment';

interface IRequestData {
  pageNumber: number;
  pageSize: number;
}

export interface ICampanha {
  id: number;
  descricao: string;
  data_fim_campanha: any;
  quantidade_estrategias: number;
}

export interface IResponseGetCampanhas {
  total_itens: number;
  total_pagina: number;
  modelo: ICampanha[];
}

interface IResponse {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    modelo: ICampanha[];
  };
}

interface IRequestCampanhaData {
  descricao: string;
  data_inicio_campanha: any;
  data_fim_campanha: any;
  status_ativo: number;
}

interface IReturn {
  error: boolean;
}

class ListCampanhasServices {
  public async getCampanhas({
    pageNumber,
    pageSize,
  }: IRequestData): Promise<IResponse> {
    return await api
      .get<IResponseGetCampanhas>(
        `api/Campanha/GetCampanhasPaginadas?pageNumber=${pageNumber}&pageSize=${pageSize}`,
      )
      .then(response => {
        const campanhas = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemCampanhas: ICampanha[] = [];

        campanhas.map((item: ICampanha) => {
          return itemCampanhas.push({
            id: item.id,
            descricao: item.descricao,
            data_fim_campanha: moment
              .utc(item.data_fim_campanha)
              .format('DD/MM/YYYY'),
            quantidade_estrategias: item.quantidade_estrategias,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, modelo: itemCampanhas },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createCampanha({
    data_fim_campanha,
    data_inicio_campanha,
    descricao,
    status_ativo,
  }: IRequestCampanhaData): Promise<IReturn> {
    let data_cadastro = moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ssZ');

    data_inicio_campanha = moment
      .utc(new Date(data_inicio_campanha))
      .format('YYYY-MM-DD HH:mm:ssZ');

    data_fim_campanha = moment
      .utc(new Date(data_fim_campanha))
      .format('YYYY-MM-DD HH:mm:ssZ');

    return await api
      .post('api/Campanha/CreateCampanha', {
        data_cadastro,
        data_fim_campanha,
        data_inicio_campanha,
        descricao,
        status_ativo,
      })
      .then(response => {
        return { error: false };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ListCampanhasServices;
