import React from 'react';
import { Chart } from "react-google-charts";

import { Container, ChartContainer } from './styles';

interface IBarChartProps {
    title: string;
    data: {
        mes: string,
        doadores_mes: number,
        doacoes_mes: number,
        valor_doacoes_mes: number,
        cadastros_mes: number,
        percentual_doadores: number,
        media_per_capta: number,
    }[],
}


const configuraDados = (a: any[]) => {
    let meses = a.map((item) => { return item.mes; });
    let percentual = a.map((item) => { return item.doadores_mes; });

    const database = {
        meses,
        percentual,
    };

    let indices = Object.keys(database);

    let valores = Object.values(database);

    let dados = [];
    for (let i = 0; i < valores[0].length; i++) {
        dados[i] = valores.map((item) => {
            return item[i];
        });
    }
    dados.unshift(indices);
    console.log("PERCENTUAL",dados);
    return dados;
}


const PiChartBox: React.FC<IBarChartProps> = ({ title, data }) => (
    <Container>
        <span>{title}</span>
        <ChartContainer>
            <Chart
                chartType="PieChart"
                data={configuraDados(data)}
                width="100%"
                height="98%"
                loader={<div>Loading Chart</div>}
                options={{
                    is3D: false,
                    pieHole: 0.3,
                    chartArea: { left: 15, top: 8, right: 0, bottom: 10 },
                    legend: {
                        position: 'rigth',
                        textStyle: {fontSize: 11},
                    },
                    colors: ["#3B276E", "#F77D5E", "#8F3176", "#D49EB9", "#F9F871", "#CF4D6D", "#6D6A93", "#FFB957", "#A44537", "#F7A4B2", "#9A89B4", "#FFAE9F"],
                    backgroundColor: "#EFEEF2",
                }}
                chartLanguage="pt-BR"
            />
        </ChartContainer>
    </Container>
);

export default PiChartBox;

