import api from '@http/api';
import { RedoRounded } from '@material-ui/icons';

export interface ICreatePalitagem {
  descricao: string;
  id_tipo_marcador: number;
  status_ativo: number;
  data_cadastro: Date;
}

export interface IListaSituacoes {
  id: number;
  descricao: string;
}

export interface IListaSituacoesAxios {
  listaSituacao: IListaSituacoes[];
}

export interface IListaSituacoesFunction {
  error: boolean;
  response?: IListaSituacoes[];
}

export interface ICheckPalitagemFunction {
  error: boolean;
  response?: ICheckPalitagem;
}

export interface ICheckPalitagemReceptivoFunction {
  error: boolean;
  response?: any;
}

export interface ICreatePalitagemReceptivaAxios {
  id_perfil: number | string;
  data_agendamento: Date | null;
  observacao: string;
  id_situacao: number;
  telefone: string;
  interacao_inicio: {
    hora: number;
    minuto: number;
    segundo: number;
  };
  interacao_fim: {
    hora: number;
    minuto: number;
    segundo: number;
  };
}

export interface ICreatePalitagemAxios {
  id_ligacao_interacao: number;
  data_agendamento: Date | null;
  observacao: string;
  telefone: string;
  id_situacao: number;
}

export interface ICreateWebPhoneFunction {
  error: boolean;
  response?: {
    url: string;
  };
}

export interface IDeleteInteracaoFunction {
  error: boolean;
}

export interface ICreatePalitagemFunction {
  error: boolean;
}

export interface ICheckPalitagem {
  estrategia_id: number | string;
  estrategia_nome: number | string;
  id_ligacao_interacao: number | string;
  retorno: number | string | null;
  receptivo: boolean;
}

class Service {
  public async loadTiposCorrespondecia({
    data_cadastro,
    descricao,
    status_ativo = 1,
    id_tipo_marcador,
  }: ICreatePalitagem) {
    return await api
      .post<ICreatePalitagem>('/api/Telefonia/CreateMarcador', {
        data_cadastro,
        descricao,
        status_ativo,
        id_tipo_marcador,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async LoadSituacoes(): Promise<IListaSituacoesFunction> {
    return api
      .get<IListaSituacoesAxios>('api/Telefonia/GetSituacoes')
      .then(response => {
        return {
          error: false,
          response: response.data.listaSituacao,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async createPalitagem(
    data: ICreatePalitagemAxios,
  ): Promise<ICreatePalitagemFunction> {
    return api
      .post<ICreatePalitagemAxios>(
        'api/Telefonia/CreatePalitagemSemWebPhone',
        data,
      )
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createPalitagemReceptivo(
    data: ICreatePalitagemReceptivaAxios,
  ): Promise<ICheckPalitagemReceptivoFunction> {
    return api
      .post('api/Telefonia/CreatePalitagemReceptivaSemWebPhone', data)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createWebPhone(
    telefone: string | null,
    fechar?: boolean,
  ): Promise<ICreateWebPhoneFunction> {
    return api
      .post<{ url: string }>('/api/WebPhone/CreateWebPhone', {
        telefone: telefone,
        fechar_fim: fechar !== undefined ? fechar : false,
      })
      .then(response => {
        return {
          error: false,
          response: {
            url: response.data.url,
          },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async DeleteInteracao(id: number): Promise<IDeleteInteracaoFunction> {
    return api
      .delete(`/api/Telefonia/DeleteInteracao?interacao_Id=${id}`)
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return { error: true };
      });
  }

  public async CheckPalitagem(): Promise<ICheckPalitagemFunction> {
    return api
      .get<ICheckPalitagem>(`/api/Telefonia/GetPalitagemEmFalta`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return { error: true };
      });
  }

  public async CheckWebPhoneOpen(): Promise<Boolean> {
    return api
      .get<any>(`/api/WebPhone/getVerificaWebphoneDisponivel`)
      .then(response => {
        return true;
      })
      .catch(error => {
        return false;
      });
  }
}

export default Service;
