import styled from 'styled-components';
import { theme } from '@styles/Theme';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  background-color: ${theme.palette.secondary.main};
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  left: 0;
  height: 80px;
  z-index: 1339;
`;

export const TextoFooter = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonLigacao = styled.button`
  padding: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #2dc579;
  border-radius: 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;

export const ButtonDescartarLigacao = styled.button`
  padding: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: red;
  border-radius: 25px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
`;
