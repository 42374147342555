import api from '@http/api';

interface ISendSocketAxios {
  eventName: string;
  message: string;
}

interface ISendEventSocketFunctionReturn {
  error: boolean;
}

class Services {
  public async sendEventSocket({
    eventName,
    message,
  }: ISendSocketAxios): Promise<ISendEventSocketFunctionReturn> {
    return await api
      .post(`/api/Socket/sendEventSocket`, {
        eventName,
        message,
      })
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default Services;
