import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';

import Tag from '@components/Shared/Tag';
import ButtonComponent from '@components/Shared/Buttons/Button';
import { ClearOutlined, SaveOutlined } from '@material-ui/icons';

import { Spin, Form, Input, Select as SelectAntd } from 'antd';
import { useSnackbar } from 'notistack';
import Services from './services';

interface IProps {
  Cancel: Function;
  Done: Function;
}

interface IUserType {
  id: number;
  descricao: string;
}

interface IUsuario {
  usuario: {
    nome: string;
    email: string;
    senha: string;
    confirmacao_senha: string;
    status_ativo: number;
    entidade_id?: number;
    tipo_usuario_id: number;
  };
}

const senhaforte = RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
);

const NewUserForm: React.FC<IProps> = ({ Cancel, Done }) => {
  const [loadingCadastro, setLoadingCadastro] = useState(false);
  const [userType, setUserType] = useState<IUserType[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit(values: any) {
    const services = new Services();
    const user: IUsuario = {
      usuario: {
        nome: values.nome,
        email: values.email,
        confirmacao_senha: values.confirmaSenha,
        senha: values.senha,
        status_ativo: 1,
        entidade_id: 0,
        tipo_usuario_id: values.tipo_usuario,
      },
    };

    const { error, response } = await services.PostUsuarios(user);

    if (!error) {
      enqueueSnackbar('Usuário cadastrado com sucesso!', {
        title: 'Usuário cadastrado com sucesso!',
        variant: 'success',
        // persist: true,
        // transitionDuration: 500,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      handleDone();
    } else {
      enqueueSnackbar('Erro ao efetuar cadastro!', {
        title: 'Erro ao efetuar cadastro.',
        variant: 'error',
        // persist: true,
        // transitionDuration: 500,
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  }

  function handleCancel() {
    Cancel();
  }

  function handleDone() {
    Done();
  }

  const firstLoad = useCallback(async () => {
    const services = new Services();
    const { error, response } = await services.GetTiposUsuarios();

    if (!error && response) {
      setUserType(response);
    } else {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    firstLoad();
  }, []);

  return (
    <>
      <Grid item sm={5} xs={12}>
        <Tag color="default">Novo Usuário</Tag>
      </Grid>

      <Spin size="large" spinning={loadingCadastro} tip="Enviando dados...">
        <Form
          size="middle"
          layout="vertical"
          scrollToFirstError={true}
          onFinish={onSubmit}
          labelAlign="left"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          requiredMark={false}
        >
          <Grid container>
            <Grid item sm={12} xs={12}>
              <Form.Item
                name="tipo_usuario"
                label="Tipo de usuário"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione um Tipo de usuário',
                  },
                ]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <SelectAntd placeholder="Tipo de usuário">
                  {userType?.map(type => (
                    <SelectAntd.Option key={type.id} value={type.id}>
                      {type.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Nome"
                hasFeedback
                name="nome"
                rules={[{ required: true, message: 'Campo obrigatorio' }]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Nome" />
              </Form.Item>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Form.Item
                label="E-mail"
                hasFeedback
                name="email"
                rules={[{ required: true, message: 'Campo obrigatorio' }]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="E-mail" />
              </Form.Item>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Senha"
                hasFeedback
                name="senha"
                rules={[
                  {
                    required: true,
                    pattern: senhaforte,
                    message: 'Senha forte necessária',
                  },
                ]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Senha" type="password" />
              </Form.Item>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Form.Item
                label="Confirmação de Senha"
                hasFeedback
                name="confirmaSenha"
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('senha') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('As senhas não batem!'));
                    },
                  }),
                ]}
                style={{ padding: 0, paddingTop: 5 }}
              >
                <Input placeholder="Confirmação de senha" type="password" />
              </Form.Item>
            </Grid>

            <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
              <ButtonComponent
                color="primary"
                disabled={loadingCadastro}
                loading={loadingCadastro}
                fullWidth
                type="submit"
              >
                <SaveOutlined style={{ marginRight: 10 }} /> Salvar
              </ButtonComponent>
            </Grid>
            <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
              <ButtonComponent
                color="default"
                disabled={loadingCadastro}
                loading={loadingCadastro}
                fullWidth
                onClick={handleCancel}
              >
                <ClearOutlined style={{ marginRight: 10 }} /> Cancelar
              </ButtonComponent>
            </Grid>
          </Grid>
        </Form>
      </Spin>
    </>
  );
};

export default NewUserForm;
