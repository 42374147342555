import api from '@http/api';

export interface IUpdateTipoPerfil {
  perfil_id: number;
  tipo_perfil_id: number;
}

export interface IPerfil {
  id: number;
  descricao: string;
  status_ativo: boolean;
}

class ComponentPerfilServices {
  public async GetTiposPerfil() {
    return await api
      .get<IPerfil[]>('api/TipoPerfil/GetTiposPerfil')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async UpdateTipoPerfil({
    perfil_id,
    tipo_perfil_id,
  }: IUpdateTipoPerfil) {
    return await api
      .patch('/api/Perfil/UpdateTipoPerfilById', {
        perfil_id,
        tipo_perfil_id,
      })

      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ComponentPerfilServices;
