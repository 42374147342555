import React, { useState, useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';

import { Visibility } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';

import { TitlePage } from '@styles/styles.global';
import TableActions from '@components/Shared/Tables/TableCustomKeys';

import PageListPessoasServices, { IIndicador } from './services';
import { useAuth } from '@hooks/AuthenticateContext';


const columnsLabel = {
  id_pessoa: 'ID',
  documento_pessoa: 'CPF/CNPJ',
  nome_pessoa: 'Nome',
  classificacao: 'Classificação',
  total_indicados: 'Total de Indicados',
};

const ListPessoas: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IIndicador[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [totalItens, setTotalItems] = useState(0);
  const { user } = useAuth();


  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      console.log(response);
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.indicadores);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setItemsPerPage(itemsPage);

    onChangeSubmit(page, itemsPage);
  }

  async function onChangeSubmit(page: number, itemsPerPage: number) {
    setLoading(true);
    setPage(page);

    const pageListPessoasServices = new PageListPessoasServices();
    const { error, response } = await pageListPessoasServices.getIndicadores({
      page_number: page,
      page_size: itemsPerPage,
    });
    setValuesOrError(error, response);
  }

  const firstLoad = useCallback(async () => {
    setLoading(true);

    const pageListPessoasServices = new PageListPessoasServices();
    const { error, response } = await pageListPessoasServices.getIndicadores({
      page_number: 1,
      page_size: 10,
    });
    setValuesOrError(error, response);
  }, []);

  //Bloqueio inspecionar
  useEffect(() => {
    if (user.role !== 'ADM AMEX') {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };
    }
  }, [user.role]);

  useEffect(() => {
    firstLoad();
  }, [firstLoad]);

  useEffect(() => {
    window.document.title = 'CRM - Lista de Cadastros Indicadores';
  }, []);

  return (
    <Grid container alignItems="center" item lg={9} xs={12}>
      <Grid item md={12} xs={12} lg={12}>
        <TitlePage style={{ margin: 5 }}>Cadastros Indicadores</TitlePage>
      </Grid>

      <Grid container>
        <Grid item xs={12} lg={12} style={{ padding: 10 }}>
          <TableActions
            keys={[
              'id_pessoa',
              'documento_pessoa',
              'nome_pessoa',
              'classificacao',
              'total_indicados',
            ]}
            FieldsBold={['documento_pessoa']}
            loading={loading}
            columns={[columnsLabel]}
            data={data}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            pageCurrent={page}
            fetchData={setPagination}
            totalItens={totalItens}
            Actions={[
              {
                Button: (row: IIndicador) => {
                  return (
                    <IconButton
                      onClick={() => window.open(`/cadastro/${row.id_pessoa}/`)}
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListPessoas;
