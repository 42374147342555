import api from '@http/api';
import moment from 'moment';

export interface IOrigemDoacao {
  id: number;
  descricao: string;
  status_ativo: string;
}

export interface IMotivo {
  id: number;
  descricao: string;
  status_ativo: string;
  data_cadastro: any;
}

export interface IOrigemEspecifica {
  id: number;
  descricao: string;
  status_ativo: string;
  data_cadastro: any;
}
export interface IEstrategias {
  id: number;
  nome: string;
  data_fim_estrategia: any;
  quantidade_perfis: number;
}

export interface IFiltro {
  filtro_id: number;
  filtro_descricao: string;
}
export interface ITipoFiltro {
  tipo_filtro_id: number;
  tipo_filtro_descricao: string;
  filtros: IFiltro[];
}
export interface ICampanha {
  id: number;
  usuario_cadastro_id: number;
  descricao: string;
  data_cadastro: any;
  data_inicio_campanha: any;
  data_fim_campanha: any;
  quantidade_estrategias: number;
  status_ativo_id: number;
}

export interface IResponseGetCampanha {
  campanha: ICampanha;
}

export interface IResponseGetEstrategias {
  total_itens: number;
  total_pagina: number;
  modelo: IEstrategias[];
}

interface IResponse {
  error: boolean;
  response?: ICampanha;
}

interface IResponseCreateEstrategia {
  error: boolean;
}
interface IResponseEstrategias {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    modelo: IEstrategias[];
  };
}

interface IResponseFiltros {
  error: boolean;
  response: {
    statusFiltro: IFiltro[];
    cadastroFiltro: IFiltro[];
    formaContribuicaoFiltro: IFiltro[];
    tipoCorrespondenciaFiltro: IFiltro[];
    tipoPerfilFiltro: IFiltro[];
    tipoMotivo: IFiltro[];
    tipoOrigemDocao: IFiltro[];
    tipoOrigemEspecifica: IFiltro[];
  };
}

interface IResponseOrigemDoacao {
  error: boolean;
  response?: IOrigemDoacao[];
}

interface IResponseMotivo {
  error: boolean;
  response?: IMotivo[];
}

interface IResponseOrigemEspecifica {
  error: boolean;
  response?: IOrigemEspecifica[];
}

interface IRequestCreateEstrategia {
  // nome: string;
  // campanha_id: any;
  // descricao: string;
  // data_inicio_estrategia: any;
  // data_fim_estrategia: any;
  // status_ativo: number;
  // filtro_status_id: number;
  // filtros_cadastro: [any];
  // filtros_forma_contribuicao: [any];
  // filtros_tipo_correspondencia: [any];
  // filtros_tipo_perfil: [any];
  // gerar_boleto: number;
  // assunto: string;
  // email_remetente: string;
  req: {
    nome: string;
    campanha_id: number;
    descricao: string;
    status_ativo: number;
    data_inicio_estrategia: any;
    data_fim_estrategia: any;
    filtros_padrao_cadastro: {
      filtros_cadastro_ativo_documento_valido: [any];
      filtros_forma_contribuicao: [any];
      filtros_tipo_correspondencia: [any];
      filtros_tipo_perfil: [any];
      filtros_origem_especifica: [any];
      filtros_motivo_cadastro: [any];
    };
    filtros_endereco: {
      estados_in_sigla: [string];
      cidades_in_id: [any];
    };
    gerar_boleto: boolean;
    usa_status: boolean;
    filtro_status_id: number;
    filtros_personalizados: {
      data_inicio_cadastro: any;
      data_fim_cadastro: any;
      data_inicio_doacao: any;
      data_fim_doacao: any;
      com_doacao: true;
      quantidade_doacao: number;
      soma_doacao: number;
      filtros_origem_doacao: [number];
      filtros_vinculo: {
        estrategias_feitas_in: [number];
        estrategias_feitas_not_in: [number];
      };
    };
  };
}

interface IRequestData {
  id: any;
}

interface IRequestDataEstrategia {
  pageNumber: number;
  pageSize: number;
  id: any;
  status_ativo: number;
}
class DetalhesCampanhasServices {
  public async getCampanhas({ id }: IRequestData): Promise<IResponse> {
    return await api
      .get(`api/Campanha/GetCampanhaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getEstrategias({
    pageNumber,
    pageSize,
    id,
    status_ativo,
  }: IRequestDataEstrategia): Promise<IResponseEstrategias> {
    return await api
      .get(
        `api/Estrategia/GetEstrategiasByCampanhaIdPaginadas?campanhaId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&statusAtivo=${status_ativo}`,
      )
      .then(response => {
        const estrategias = response.data.modelo;
        const total_itens = response.data.total_itens;
        const total_pagina = response.data.total_pagina;

        var itemEstrategias: IEstrategias[] = [];

        estrategias.map((item: IEstrategias) => {
          return itemEstrategias.push({
            id: item.id,
            data_fim_estrategia: moment
              .utc(item.data_fim_estrategia)
              .format('DD/MM/YYYY'),
            nome: item.nome,
            quantidade_perfis: item.quantidade_perfis,
          });
        });

        return {
          error: false,
          response: { total_itens, total_pagina, modelo: itemEstrategias },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getFiltros(): Promise<IResponseFiltros> {
    return await api.get('api/Estrategia/GetTiposFiltros').then(response => {
      let res = response.data;

      var statusFiltro: IFiltro[] = [];
      var cadastroFiltro: IFiltro[] = [];
      var formaContribuicaoFiltro: IFiltro[] = [];
      var tipoCorrespondenciaFiltro: IFiltro[] = [];
      var tipoPerfilFiltro: IFiltro[] = [];
      var tipoMotivo: IFiltro[] = [];
      var tipoOrigemDoacao: IFiltro[] = [];
      var tipoOrigemEspecifica: IFiltro[] = [];

      res.map((item: ITipoFiltro) => {
        if (item.tipo_filtro_descricao.toLocaleLowerCase() === 'status') {
          statusFiltro = item.filtros;
        }
        if (item.tipo_filtro_descricao.toLocaleLowerCase() === 'cadastro') {
          cadastroFiltro = item.filtros;
        }
        if (
          item.tipo_filtro_descricao.toLocaleLowerCase() ===
            'forma de contribuição' ||
          item.tipo_filtro_descricao.toLocaleLowerCase() ===
            'forma contribuição'
        ) {
          formaContribuicaoFiltro = item.filtros;
        }
        if (
          item.tipo_filtro_descricao.toLocaleLowerCase() ===
            'tipo de correspondência' ||
          item.tipo_filtro_descricao.toLocaleLowerCase() ===
            'tipo correspondência'
        ) {
          tipoCorrespondenciaFiltro = item.filtros;
        }
        if (
          item.tipo_filtro_descricao.toLocaleLowerCase() === 'tipo de perfil' ||
          item.tipo_filtro_descricao.toLocaleLowerCase() === 'tipo perfil'
        ) {
          tipoPerfilFiltro = item.filtros;
        }
        if (
          item.tipo_filtro_descricao.toLocaleLowerCase() ===
            'motivo cadastro' ||
          item.tipo_filtro_descricao.toLocaleLowerCase() ===
            'motivo de cadastro'
        ) {
          tipoMotivo = item.filtros;
        }
        if (
          item.tipo_filtro_descricao.toLocaleLowerCase() === 'origem doação' ||
          item.tipo_filtro_descricao.toLocaleLowerCase() === 'origem de doação'
        ) {
          tipoOrigemDoacao = item.filtros;
        }
        if (
          item.tipo_filtro_descricao.toLocaleLowerCase() === 'origem específica'
        ) {
          tipoOrigemEspecifica = item.filtros;
        }
      });

      return {
        error: false,
        response: {
          statusFiltro: statusFiltro,
          cadastroFiltro: cadastroFiltro,
          formaContribuicaoFiltro: formaContribuicaoFiltro,
          tipoCorrespondenciaFiltro: tipoCorrespondenciaFiltro,
          tipoPerfilFiltro: tipoPerfilFiltro,
          tipoMotivo: tipoMotivo,
          tipoOrigemDocao: tipoOrigemDoacao,
          tipoOrigemEspecifica: tipoOrigemEspecifica,
        },
      };
    });
  }

  public async getOrigensDoacao(): Promise<IResponseOrigemDoacao> {
    return await api
      .get('/api/Doacao/GetOrigensDoacao')
      .then(response => {
        let resposta: IOrigemDoacao[] = response.data;

        var itemOrigemDoacao: IOrigemDoacao[] = [];

        resposta.map((item: IOrigemDoacao) => {
          return itemOrigemDoacao.push({
            id: item.id,
            descricao: item.descricao,
            status_ativo: item.status_ativo,
          });
        });
        return {
          error: false,
          response: itemOrigemDoacao,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getMotivos(): Promise<IResponseMotivo> {
    return await api
      .get('/api/MotivoCadastro/GetMotivosCadastros')
      .then(response => {
        let resposta: IMotivo[] = response.data;

        var itemMotivo: IMotivo[] = [];

        resposta.map((item: IMotivo) => {
          return itemMotivo.push({
            id: item.id,
            descricao: item.descricao,
            data_cadastro: item.data_cadastro,
            status_ativo: item.status_ativo,
          });
        });
        return {
          error: false,
          response: itemMotivo,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getOrigemEspecifica(): Promise<IResponseOrigemEspecifica> {
    return await api
      .get('/api/OrigemEspecifica/GetOrigensEspecificas')
      .then(response => {
        let resposta: IOrigemEspecifica[] = response.data;

        var itemOE: IOrigemEspecifica[] = [];

        resposta.map((item: IOrigemEspecifica) => {
          return itemOE.push({
            id: item.id,
            descricao: item.descricao,
            data_cadastro: item.data_cadastro,
            status_ativo: item.status_ativo,
          });
        });
        return {
          error: false,
          response: itemOE,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async createEstrategias(req: any): Promise<IResponseCreateEstrategia> {
    req.gerar_boleto = req.gerar_boleto ? 1 : 0;
    return await api
      .post('/api/Estrategia/CreateEstrategia', req)
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default DetalhesCampanhasServices;
