import React, { useMemo } from 'react';
import { Container } from './styles';
import CountUp from 'react-countup';


import cadastroImg from '@src/Assets/cadastro.svg';
import doacaoImg from '@src/Assets/doacao.svg';
import totalDoacaoImg from '@src/Assets/totalDoacao.svg';
import mediaPerCapta from '@src/Assets/media.svg';
import { size } from 'polished';




interface IWalletBoxProps {
  title: string;
  amount: number;
  icon: 'cadastro' | 'doacao' | 'totalDoacao' | 'mediaPerCapta';
  color: string;
}


const WalletBox: React.FC<IWalletBoxProps> = ({
  title,
  amount,
  icon,
  color }) => {


  const iconSelected = useMemo(() => {
    switch (icon) {
      case 'cadastro':
        return cadastroImg;
      case 'doacao':
        return doacaoImg;
      case 'totalDoacao':
        return totalDoacaoImg;
      case 'mediaPerCapta':
        return mediaPerCapta;
      default:
        return undefined;
    }
  }, [icon]);


  const moeda = useMemo(() => {
    if (title == "Valor das Doações (Anual)" || title == "Média Per Capta (Anual)") {
      return "R$";
    } else {
      return "";
    }
  }, [title]);

  const decimal = useMemo(() => {
    if (title == "Valor das Doações (Anual)" || title == "Média Per Capta (Anual)") {
      return ",";
    } else {
      return "";
    }
  }, [title]);

  const casasDecimais = useMemo(() => {
    if (title == "Valor das Doações (Anual)" || title == "Média Per Capta (Anual)") {
      return 2;
    } else {
      return 0;
    }
  }, [title]);


  return (
    <Container color={color}>
      <span>{title}</span>
      <h1>
        <strong>{moeda}
          <CountUp style={{ fontSize: '20px', paddingBottom: '50px' }}
            end={amount}
            separator="."
            decimal={decimal}
            decimals={casasDecimais}
          />
        </strong>
      </h1>

      <img src={iconSelected} alt={title} />

    </Container>
  )
}

export default WalletBox;
