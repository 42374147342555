import styled, { keyframes } from 'styled-components';

interface ILegendProps {
    color: string;
}

const animate = keyframes`
    0% {
        transform: translateX(100px);
        opacity: 0;
    }
    50%{
        opacity: .3;
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
    }
`;

export const Container = styled.div`
    width: 100%;
    min-height: 260px;

    margin: 10px 0;

    background-color:#EFEEF2;
    color:#36316a;
<<<<<<< HEAD

=======

>>>>>>> Production
    border-radius: 7px;

    display: flex;

    >span{
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        position: absolute;
    }

    animation: ${animate} .5s;

    @media(max-width: 1200px){
        display: flex;
        flex-direction: column;

        width: 100%;
        height: auto;
    }
`;

export const ChartContainer = styled.div`
     flex: 1;
     height: 80%;

    margin-top: 45px;
`;

export const Legend = styled.li<ILegendProps>`
    display: flex;
    align-items: center;
    position: absolute;

    margin-bottom: 7px;

    padding-left: 16px;

    > div {
        background-color: ${props => props.color};

        width: 40px;
        height: 40px;
        border-radius: 5px;

        font-size: 14px;
        line-height: 40px;
        text-align: center;
    }

    > span {
        margin-left: 5px;
    }

    @media(max-width: 1200px){
        > div {
            width: 30px;
            height: 30px;

            font-size: 10px;
            line-height: 30px;
        }

    }
`;
