import React, { useState, useImperativeHandle, Ref, useEffect } from 'react';

import { TweenOneGroup } from 'rc-tween-one';
import { Form, Input, Select, Tag } from 'antd';
import DatePicker from '@components/Shared/DatePicker';
import { Grid } from '@material-ui/core';
import Button from '@components/Shared/Buttons/Button';
import Service, { ICampanha, IEstrategias } from './services';

import { FormatDate } from '@utils/formats';
import { AddOutlined } from '@material-ui/icons';

interface IProps {
  ref: React.Ref<any>;
}

interface IFiltros {
  id: string;
  periodo: Date[];
  campanhas?: {
    descricao: string;
    id: number;
  };
  estrategias?: {
    descricao: string;
    id: number;
  };
  participou: boolean;
}

const TagsInput: React.FC<IProps> = React.forwardRef(
  (props: any, ref: Ref<any>) => {
    const [formRef] = Form.useForm();
    const [dates, setDate] = useState([new Date(), new Date()]);
    const [filtros, setFiltros] = useState<IFiltros[]>([]);
    const [campanhas, setCampanhas] = useState<any[]>([]);
    const services = new Service();
    const [periodoDefinido, setPeriodoDefinido] = useState(false);
    const [campanhaSelecionada, setCampanhaSelecionada] = useState(false);
    const [estrategias, setEstrategias] = useState<any[]>([]);
    const [estrategiasFiltradas, setEstrategiasFiltradas] = useState<any[]>([]);
    async function LoadCampanhas(date: Date[]) {
      setDate(date);

      const { error, response } = await services.getCampanhas({
        data_inicio_campanhas_estrategias: date[0],
        data_fim_campanhas_estrategias: date[1],
      });
      if (!error && response) {
        setCampanhas(response);
        let estrategiasArray: any[] = [];
        response.map(campanha => {
          campanha.estrategias.map(estrategia => {
            const obj = {
              campanha_id: campanha.campanha_id,
              estrategia_id: estrategia.estrategia_id,
              estrategia_descricao: estrategia.estrategia_descricao,
            };
            estrategiasArray.push(obj);
          });
          setEstrategias(estrategiasArray);
        });
        setPeriodoDefinido(true);
      } else console.log(error);
    }

    const OnSelectCampanha = (values: any) => {
      setCampanhaSelecionada(true);

      setEstrategiasFiltradas([]);

      let campanhaID = JSON.parse(values).campanha_id;

      setEstrategiasFiltradas(
        estrategias.filter(estrategia => estrategia.campanha_id === campanhaID),
      );

      formRef.setFieldsValue({
        estrategias: '',
      });
    };

    useImperativeHandle(
      ref,
      () => ({
        getFiltros: () => {
          return filtros;
        },
      }),
      [filtros],
    );

    const handleClose = (removedTag: string) => {
      const filtrosArray = filtros.filter(
        (filtro: IFiltros) => filtro.id !== removedTag,
      );
      setFiltros(filtrosArray);
    };

    const handleInputConfirm = (values: any) => {
      let CampanhaValueJSON = JSON.parse(values.campanhas);
      let EstrategiaValueJSON = JSON.parse(values.estrategias);

      const obj: IFiltros = {
        id: Date.now().toString(),
        campanhas: {
          descricao: CampanhaValueJSON.campanha_descricao,
          id: CampanhaValueJSON.campanha_id,
        },
        estrategias: {
          descricao: EstrategiaValueJSON.estrategia_descricao,
          id: EstrategiaValueJSON.estrategia_id,
        },
        participou: values.participou === 'true' ? true : false,
        periodo: [dates[0], dates[1]],
      };

      if (
        filtros.filter((filtro: IFiltros) => filtro.id == obj.id).length <= 0
      ) {
        const tagsArray = [...filtros, obj];
        setFiltros(tagsArray);

        formRef.setFieldsValue({
          estrategias: '',
        });
      } else {
        alert('já existe id');
      }
    };

    const forMap = (tag: IFiltros) => {
      let tagString = `${FormatDate(tag.periodo[0].toString())} ~ ${FormatDate(
        tag.periodo[1].toString(),
      )} - `;

      if (tag.campanhas) {
        tagString += `${tag.campanhas?.descricao} - `;
      }

      if (tag.estrategias) {
        tagString += `${tag.estrategias?.descricao} - `;
      }

      tagString += ` ${tag.participou ? 'Participou' : 'Não participou'} `;

      const tagElem = (
        <Tag
          closable
          onClose={e => {
            e.preventDefault();
            handleClose(tag.id);
          }}
        >
          {tagString}
        </Tag>
      );
      return (
        <span
          key={tag.id}
          style={{ display: 'inline-block', marginBottom: 10 }}
        >
          {tagElem}
        </span>
      );
    };

    return (
      <>
        <Form name="control-ref" onFinish={handleInputConfirm} form={formRef}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <DatePicker
                cleanable={false}
                notUseDefaultValue={false}
                title="Período: "
                color={'secondary'}
                setValor={(dates: [Date]) => LoadCampanhas(dates)}
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <Form.Item name="campanhas" label="Campanhas">
                <Select
                  placeholder="Selecione uma campanha"
                  allowClear
                  showSearch
                  disabled={!periodoDefinido}
                  onChange={OnSelectCampanha}
                >
                  {campanhas ? (
                    campanhas.map(campanha => (
                      <Select.Option
                        key={JSON.stringify(campanha)}
                        value={JSON.stringify(campanha)}
                      >
                        {campanha.campanha_descricao}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value={''}>
                      Nenhuma campanha disponivel
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Grid>
            <Grid item lg={12} xs={12}>
              <Form.Item name="estrategias" label="Estratégias">
                <Select
                  placeholder="Selecione uma estratégia"
                  allowClear
                  disabled={!campanhaSelecionada}
                  showSearch
                >
                  {estrategiasFiltradas ? (
                    estrategiasFiltradas.map(estrategia => (
                      <Select.Option
                        key={JSON.stringify(estrategia)}
                        value={JSON.stringify(estrategia)}
                      >
                        {estrategia.estrategia_descricao}
                      </Select.Option>
                    ))
                  ) : (
                    <Select.Option value={''}>
                      Nenhuma estratégia disponivel
                    </Select.Option>
                  )}
                </Select>
              </Form.Item>
            </Grid>
            <Grid item lg={6} xs={6}>
              <Form.Item
                name="participou"
                label="Participou?"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select placeholder="Participou?" allowClear>
                  <Select.Option value={'true'}>Sim</Select.Option>
                  <Select.Option value={'false'}>Não</Select.Option>
                </Select>
              </Form.Item>
            </Grid>
            <Grid item lg={6} xs={6} style={{ alignSelf: 'flex-end' }}>
              <Form.Item>
                <Button
                  type="submit"
                  style={{ width: '100%', margin: 0, height: 32 }}
                  color="inherit"
                >
                  <AddOutlined style={{ marginRight: 10 }} />
                  Adicionar
                </Button>
              </Form.Item>
            </Grid>
          </Grid>
        </Form>

        <div
          style={{
            maxHeight: 100,
            overflowY: 'scroll',
            overflowX: 'scroll',
          }}
        >
          <TweenOneGroup
            enter={{
              scale: 0.8,
              opacity: 0,
              type: 'from',
              duration: 100,
              onComplete: e => {},
            }}
            leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
            appear={false}
          >
            {filtros.map(forMap)}
          </TweenOneGroup>
        </div>
      </>
    );
  },
);
export default TagsInput;
