import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';

export interface IResponseRequestPessoaByCPF {
  pessoa: {
    id: string;
    documento: string;
    nome: string;
    perfis: IPerfil[];
    tipos_perfis: string[];
  };
}

export interface IResponsePessoaByCPF {
  response?: {
    pessoa: {
      id: string;
      nome: string;
      documento: string;
      perfis: IPerfil[];
      tipos_perfis: string[];
    };
  };
  error: boolean;
}

export interface IResponseCreatePessoa {
  id: number;
}

export interface IPessoa {
  id: number;
  id_pessoa_responsavel: number;
  id_pessoa_indicador: number;
  nome_pessoa_responsavel: string;
  nome_pessoa_indicador: string;
}

export interface IRequestUpdateResponsavel {
  pessoa: IPessoa;
}

export interface IMotivoDevolucao {
  id: number;
  codigoCorreio: string;
  descricao: string ;
  dataCadastro: Date;
  objetivoDevolucao: number;
}

export interface IRequestCreateCorrespDevolvida {
  observacao: string;
  data_envio: Date;
  data_retorno: Date;
  mala_mensal: boolean;
  id_perfil: number;
  id_motivo_devolucao: number;
}

export interface IResponseCreateCorrespDevolvida {
  id: number;
}



class PageEditCadastroPessoaServices {

  public async getPessoaByID(id: number): Promise<IResponsePessoaByCPF> {
    return await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoaByDocumento(
    documento: string,
  ): Promise<IResponsePessoaByCPF> {
    return await api
      .post<IResponseRequestPessoaByCPF>(`api/Pessoa/FindPessoaByCpf`, {
        documento,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async UpdateResponsavel(
    pessoa: IRequestUpdateResponsavel,
  ) {
    console.log(pessoa);
    return await api
      .put('/api/Pessoa/UpdatePessoaByPessoaId', pessoa)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetMotivoDevolucao() {
    return await api
      .get<IMotivoDevolucao[]>(`api/CorrespondenciaDevolvida/GetListaMotivosDevolucao`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async CreateCorrespDevolvida(data: IRequestCreateCorrespDevolvida) {
    return await api
    .post('/api/CorrespondenciaDevolvida/CreateCorrespondenciaDevolvida', data)
    .then(response => {
      return {
        error: false,
        response: response.data,
      };
    })
    .catch(error => {
      return {
        error: true,
        response: [],
      };
    });
}


}

export default PageEditCadastroPessoaServices;
