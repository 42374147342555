import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Grid, IconButton } from '@material-ui/core';
import { Form, Input, Spin, Radio, Select as SelectAntd } from 'antd';
import ButtonComponent from '@components/Shared/Buttons/Button';

import CreatePerfil from '@src/components/Shared/Perfil/create';

import { TitlePage } from '@styles/styles.global';
import InvisibleContent from '@components/Shared/InvisibleContent';


import Services, { IRequestCreatePessoa } from './services';
import { MaskCPFeCNPJ, ClearString } from '@utils/Masks';
import { validaCPFandCNPJ } from '@utils/Validators';
import { AddOutlined, Visibility } from '@material-ui/icons';
import { format, addDays, subDays, isValid } from 'date-fns';

import CheckExistsDocumentService from '@utils/CheckExistsDocumentService';
import useDebounce from '@hooks/useDebounce';
import { LinkRoute } from './styles';

import { getColors } from '@styles/colorsTheme';
import { IPerfil, IUser } from '@interfaces/UserComplete';

import { useAuth } from '@hooks/AuthenticateContext';

const DATA = addDays(new Date(), 0).toISOString().split('T')[0];
const DATA2 = new Date;

const result = isValid(new Date(6574))
const optionsFilterResponsavel = ['ID', 'CPF'];
const optionsFilterIndicador = ['ID', 'CPF/CNPJ'];


const EditCadastro: React.FC = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [formref] = Form.useForm();
  const [formPessoaRef] = Form.useForm();

  const [idExists, setIdExists] = useState(0);
  const [showButtonViewPessoa, setShowButtonViewPessoa] = useState(false);
  const [loadingCreatePessoa, setLoadingCreatePessoa] = useState(false);
  const [pessoaId, setPessoaId] = useState<number>();
  const [data, setData] =  useState<IPerfil[]>([]);

  const [tipoDocumento, setTipoDocumento] = useState<'pf' | 'pj'>('pf');
  const [idpessoaresponsavel, setIdpessoaresponsavel] = useState<number>(0);

  const [valueInput, setValueInput] = useState('');
  const [statePessoa, setStatePessoa] = useState({
    nome_responsavel: '',
    id_responsavel: '',
  });
  const [viewConfirmation, setViewConfirmation] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilterResponsavel[optionsFilterResponsavel.length - 1],
  );
  const [respostaErroBuscaPerfil, setRespostaErroBuscaPerfil] = useState('');
  const [loadingTipoPerfil, setLoadingTipoPerfil] = useState(false);
  const [perfis, setPerfis] = useState<IPerfil[]>([]);
  const [enableSearchForm, setEnableSearchForm] = useState(false);
  const [convenios, setConvenios] = useState<IUser[]>([]);
  const [dataNasc, setDataNasc] = useState(false);

  const [idpessoaIndicador, setIdpessoaIndicador] = useState<number>(0);

  const [valueInputIndicador, setValueInputIndicador] = useState('');
  const [statePessoaIndicador, setStatePessoaIndicador] = useState({
    nome_indicador: '',
    id_indicador: '',
  });
  const [viewConfirmationIndicador, setViewConfirmationIndicador] = useState(false);
  const [selectedFilterIndicador, setSelectedFilterIndicador] = useState(
    optionsFilterIndicador[optionsFilterIndicador.length - 1],
  );
  const [respostaErroBuscaPerfilIndicador, setRespostaErroBuscaPerfilIndicador] = useState('');
  const [loadingTipoPerfilIndicador, setLoadingTipoPerfilIndicador] = useState(false);
  const [enableSearchFormIndicador, setEnableSearchFormIndicador] = useState(false);
  const [valorModifyValorPadrao, setValorModifyValorPadrao] =
  useState<string>('');

  function handleInput(e: any) {
    var ss = e.target.selectionStart;
    var se = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.selectionStart = ss;
    e.target.selectionEnd = se;
  };

  const checkDocument = (documento: string) => {
    const checkExistsDocumentService = new CheckExistsDocumentService();
    checkExistsDocumentService
      .checkDocument(documento)
      .then(({ error, response }) => {
        if (!error && response.id) {
          enqueueSnackbar(
            `Já existe uma pessoa cadastrada com esse documento`,
            {
              title: 'Já existe uma pessoa cadastrada com esse documento!',
              variant: 'warning',
              anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
            },
          );
          formPessoaRef.setFields([
            {
              name: 'documento',
              errors: ['Já existe uma pessoa cadastrada com esse documento'],
            },
          ]);
          setIdExists(response.id);
          setShowButtonViewPessoa(true);
          return;
        }
      });
    setShowButtonViewPessoa(false);

  };

  const debounceCheckDocument = useDebounce(checkDocument, 800);


  async function teste2(data_nascimento: Date){

    var date1 = new Date(data_nascimento);
    var date2 = new Date();

    var timeDiff = Math.abs(date2.getTime() - date1.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (diffDays < 6574 ){
      setDataNasc(true);
      //console.log ('MENOR DE 18');

    }else{

      setDataNasc(false);
      //console.log ('MAIOR QUE 18');
    }

    //console.log(diffDays+ " dias");
  }


  async function onFinish (values: any) {
    if (showButtonViewPessoa) {
      formPessoaRef.setFields([
        {
          name: 'documento',
          errors: ['Já existe uma pessoa cadastrada com esse documento'],
        },
      ]);
      return;
    }

    setLoadingCreatePessoa(true);
    const services = new Services();
    values.tipoDocumento = tipoDocumento;
    values.status_ativo = 1;


    const createPessoa: IRequestCreatePessoa = {
      nome: values.nome,
      nome_fantasia: values.nome_fantasia,
      data_nascimento: values.data_nascimento,
      documento: values.documento,
      sexo: values.sexo,
      status_ativo: 1,
      id_pessoa_responsavel: Number(statePessoa.id_responsavel) ,
      id_pessoa_indicador: Number(statePessoaIndicador.id_indicador),
      nome_pessoa_responsavel: statePessoa.nome_responsavel || '',
      nome_pessoa_indicador: statePessoaIndicador.nome_indicador || '',
    };

    //var teste = values.data_nascimento;




    const { error, response } = await services.CreatePessoa(createPessoa);

    if (!error && response !== null) {
      setPessoaId(response.id);
      formref.setFieldsValue({
        id: response.id,
      });

      enqueueSnackbar(`Pessoa cadastrada com sucesso`, {
        title: 'Cadastro salvo com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    //}  //else if (error && response?.id === null) {
      //enqueueSnackbar(
        //`Responsável não pode ser menor de idade`,
        //{
        //  title: `Responsável não pode ser menor de idade `,
       //   variant: 'warning',
         // anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        //},
//);
    }else {
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      enqueueSnackbar(`Caso haja responsável verifique se o mesmo é menor de idade`, {
        title: 'Caso haja responsável Verifique se o mesmo é menor de idade',
        variant: 'warning',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      enqueueSnackbar(`Caso haja indicador verifique se o mesmo possui perfil indicador`, {
        title: 'Caso haja indicador verifique se o mesmo possui perfil indicador',
        variant: 'warning',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }

    setLoadingCreatePessoa(false);
  };


  const onChangeDocumento = (e: any) => {
    const value = e.target.value;
    const { typeDocument, valueModify } = MaskCPFeCNPJ(value);

    setTipoDocumento(typeDocument);

    formPessoaRef.setFieldsValue({
      documento: valueModify,
    });


    if (typeDocument === 'pj') {
      formPessoaRef.setFieldsValue({
        sexo: 1,
      });
    }
  };

  const validaDocumento = (_: any, val: any) => {
    const documentoExiste = validaCPFandCNPJ(val);

    if (documentoExiste) {
      return Promise.resolve();
    }

    return Promise.reject(new Error('Digite um documento válido'));
  };

  const getPessoaByDocumento = async (documento: string) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByDocumento(documento);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil(
          'Não existe perfil cadastrado para este CPF/CNPJ',
        );
      }
      if (!error && response) {
        setStatePessoa({
          nome_responsavel: response.pessoa.nome,
          id_responsavel: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation(true);
      }
    } else {
      setViewConfirmation(false);
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este CPF/CNPJ',
      );
    }
    setLoadingTipoPerfil(false);
  };

  const getPessoaByID = async (id: number) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByID(id);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil('Não existe perfil cadastrado para este ID');
      }
      if (!error && response) {
        setStatePessoa({
          nome_responsavel: response.pessoa.nome,
          id_responsavel: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation(true);
      }
    } else {
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este ID',
      );
      setViewConfirmation(false);
    }

    setLoadingTipoPerfil(false);
  };

  const debounceOnChange = (value: string) => {
    formref.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
    if (selectedFilter === 'CPF') {
      debounceGetPessoaByDocumento(value);
    } else if (selectedFilter === 'ID') {
      debounceGetPessoaByID(parseInt(value));
    }
  };

  const getPessoaByDocumentoIndicador = async (documento: string) => {
    setRespostaErroBuscaPerfilIndicador('');
    setLoadingTipoPerfilIndicador(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByDocumento(documento);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfilIndicador(
          'Não existe perfil cadastrado para este CPF/CNPJ',
        );
      }
      if (!error && response) {
        setStatePessoaIndicador({
          nome_indicador: response.pessoa.nome,
          id_indicador: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmationIndicador(true);
      }
    } else {
      setViewConfirmationIndicador(false);
      setRespostaErroBuscaPerfilIndicador(
        'Não existe uma pessoa cadastrada para este CPF/CNPJ',
      );
    }
    setLoadingTipoPerfilIndicador(false);
  };

  const getPessoaByIDIndicador = async (id: number) => {
    setRespostaErroBuscaPerfilIndicador('');
    setLoadingTipoPerfilIndicador(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByID(id);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfilIndicador('Não existe perfil cadastrado para este ID');
      }
      if (!error && response) {
        setStatePessoaIndicador({
          nome_indicador: response.pessoa.nome,
          id_indicador: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmationIndicador(true);
      }
    } else {
      setRespostaErroBuscaPerfilIndicador(
        'Não existe uma pessoa cadastrada para este ID',
      );
      setViewConfirmationIndicador(false);
    }
    setLoadingTipoPerfilIndicador(false);
  };

  const debounceOnChangeIndicador = (value: string) => {
    formref.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
    if (selectedFilterIndicador === 'CPF/CNPJ') {
      debounceGetPessoaByDocumentoIndicador(value);
    } else if (selectedFilterIndicador === 'ID') {
      debounceGetPessoaByIDIndicador(parseInt(value));
    }
  };

  const debounceGetPessoaByDocumento = useDebounce(getPessoaByDocumento, 1000);
  const debounceGetPessoaByID = useDebounce(getPessoaByID, 1000);

  const debounceGetPessoaByDocumentoIndicador = useDebounce(getPessoaByDocumentoIndicador, 1000);
  const debounceGetPessoaByIDIndicador = useDebounce(getPessoaByIDIndicador, 1000);

  const handleSelectFilter = (value: string) => {
    setSelectedFilter(value);
    setSelectedFilterIndicador(value);

    formref.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
  };


  window.document.title = 'CRM - Novo Cadastro';


  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };
  
        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };
  
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
  
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <>
      {/* // HEADER PAGE */}
      <Grid container alignItems="center" justify="flex-start">
        <Grid container item sm={12}>
          <Grid item lg={3} sm={3} xs={12}>
            <TitlePage>Novo Cadastro</TitlePage>
          </Grid>

          <Grid item lg={3} sm={3} xs={12} style={{ display: 'inline-flex' }}>
            <Form
              form={formref}
              size="middle"
              layout="inline"
              scrollToFirstError={true}
              onFinish={() => {}}
              labelAlign="left"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              requiredMark={false}
            >
              <Grid container alignItems="center">
                {pessoaId && (
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                  >
                    <Form.Item
                      label="ID"
                      hasFeedback
                      name="id"
                      style={{ margin: 0, width: '100%' }}
                    >
                      <Input
                        placeholder="ID"
                        disabled
                        style={{ marginRight: 8 }}
                      />
                    </Form.Item>
                  </Grid>
                )}
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Grid>
      {/* // HEADER PAGE */}

      <Grid
        container
        item
        sm={12}
        justify="space-around"
        xs={12}
        style={{ marginTop: 20 }}
      >
        {/* FORMULARIO  */}
        <Grid item sm={5} xs={12}>
          <Spin
            size="large"
            spinning={loadingCreatePessoa}
            tip="Enviando dados..."
          >
            <Form
              form={formPessoaRef}
              size="middle"
              layout="vertical"
              scrollToFirstError={true}
              onFinish={onFinish}
              labelAlign="left"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              requiredMark={false}
            >

              <Grid container>
                <InvisibleContent visible={dataNasc === true}>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10 }}
                  >
                    <Form.Item
                      label="Documento"
                      hasFeedback
                      name="documento"
                      rules={[
                        { required: false, message: 'Campo obrigatorio' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        //{ validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          debounceCheckDocument(e.target.value);
                        }}
                        placeholder="Documento"
                      />
                    </Form.Item>
                    {showButtonViewPessoa && (
                      <Grid item xs={12} lg={12} md={12}>
                        <LinkRoute
                          onClick={() => window.open(`/cadastro/${idExists}/`)}
                        >
                          Visualizar cadastro
                          <Visibility style={{ marginLeft: 10 }} />
                        </LinkRoute>
                      </Grid>
                    )}
                  </Grid>
                </InvisibleContent>

                <InvisibleContent visible={dataNasc === false}>
                  <Grid
                    item
                    lg={12}
                    xs={12}
                    style={{ paddingRight: 10, paddingLeft: 10 }}
                  >
                    <Form.Item
                      label="Documento"
                      hasFeedback
                      name="documento"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 14, message: 'Minimo 14 caracteres' },
                        { validator: (e, a) => validaDocumento(e, a) },
                      ]}
                    >
                      <Input
                        maxLength={18}
                        onChange={(e: any) => {
                          onChangeDocumento(e);
                          debounceCheckDocument(e.target.value);
                        }}
                        placeholder="Documento"
                      />
                    </Form.Item>
                    {showButtonViewPessoa && (
                      <Grid item xs={12} lg={12} md={12}>
                        <LinkRoute
                          onClick={() => window.open(`/cadastro/${idExists}/`)}
                        >
                          Visualizar cadastro
                          <Visibility style={{ marginLeft: 10 }} />
                        </LinkRoute>
                      </Grid>
                    )}
                  </Grid>
                </InvisibleContent>

                <InvisibleContent
                  visible={tipoDocumento === 'pf'}
                >

                  <Grid item lg={12} xs={12}>
                    <Form.Item
                      label='Nome Completo'
                      hasFeedback
                      name="nome"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 3, message: 'Minimo de 3 letras' },
                        {
                          pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                          message: 'Somente letras'
                        }
                      ]}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Input
                        onInput={handleInput}
                        placeholder='Nome Completo'
                      />
                    </Form.Item>
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        label= 'Nome Social'
                        hasFeedback
                        name="nome_fantasia"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },
                          {
                            pattern:  /^[A-Za-záàâãéèêíïóôõöúçñÁÀ ÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/,
                            message: 'Somente letras'
                          }
                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          onInput={handleInput}
                          placeholder= 'Nome Social'
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="data_nascimento"
                        label='Data de Nascimento'
                        hasFeedback
                        //rules={[
                          //{
                            //required: true,
                          // message: 'campo obrigatorio',
                        // },
                        //]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          type="date"
                          placeholder= 'Data de Nascimento'
                          max={DATA}
                          onChange={(e: any) => {
                            //onChangeDocumento(e);
                            teste2(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                </InvisibleContent>

                <InvisibleContent
                  visible={tipoDocumento === 'pj'}
                >
                  <Grid item lg={12} xs={12}>

                    <Form.Item
                      label='Razão Social'
                      hasFeedback
                      name="nome"
                      rules={[
                        { required: true, message: 'Campo obrigatorio' },
                        { min: 3, message: 'Minimo de 3 letras' },

                      ]}
                      style={{ paddingLeft: 10, paddingRight: 10 }}
                    >
                      <Input
                        onInput={handleInput}
                        placeholder='Razão Social'
                      />
                    </Form.Item>
                  </Grid>

                  <Grid container>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        label= 'Nome Fantasia'
                        hasFeedback
                        name="nome_fantasia"
                        rules={[
                          { required: true, message: 'Campo obrigatorio' },
                          { min: 3, message: 'Minimo de 3 letras' },
                        ]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          onInput={handleInput}
                          placeholder= 'Nome Fantasia'
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Form.Item
                        name="data_nascimento"
                        label='Data de Fundação'
                        hasFeedback
                        //rules={[
                          //{
                            //required: true,
                          // message: 'campo obrigatorio',
                        // },
                        //]}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <Input
                          type="date"
                          placeholder= 'Data de Fundação'
                          max={DATA}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                </InvisibleContent>

                <Grid item container justify="space-around" sm={12} xs={12}>
                  <Form.Item name="sexo">
                    <Radio.Group disabled={tipoDocumento === 'pj'}>
                      <Radio value={1}>Feminino</Radio>
                      <Radio value={0}>Masculino</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Grid>

                <div
                style={{
                  background: getColors()?.branco,
                  border: `1px dashed ${getColors()?.azulEscuro} `,
                  minHeight: 'auto',
                  maxHeight: 'auto',
                  width: 490,
                  padding: 3,
                  marginLeft: 10,
                }}
              >
                <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                  <p>Possui responsável?</p>

                <InvisibleContent visible={tipoDocumento === 'pf' && dataNasc === false}>

                  <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                    <Form.Item
                    >
                      <Radio.Group>
                        <Radio value={1} onChange={(e: any) => setIdpessoaresponsavel(e.target.value)}  >Sim</Radio >
                        <Radio value={0} onChange={(e: any) => setIdpessoaresponsavel(e.target.value)} >Não</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Grid>
                </InvisibleContent>

                < InvisibleContent visible={tipoDocumento === 'pj'}>

                  <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                    <Form.Item>
                      <Radio.Group disabled={tipoDocumento === 'pj'}>
                        <Radio value={1} onChange={(e: any) => setIdpessoaresponsavel(e.target.value)}  >Sim</Radio >
                        <Radio value={0} onChange={(e: any) => setIdpessoaresponsavel(e.target.value)} >Não</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Grid>
                </InvisibleContent>

                  <InvisibleContent visible={tipoDocumento === 'pf' && dataNasc === true}>

                  <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                    <Form.Item
                     name="teste"
                    rules={[
                        {
                          required: true,
                          message: 'Por favor, cadastre um responsável',
                        },
                      ]}>
                      <Radio.Group onChange={(e: any) => setIdpessoaresponsavel(e.target.value)}>
                        <Radio value={1}>Sim</Radio >
                        <Radio value={0} disabled >Não</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Grid>
                  </InvisibleContent>
                </Grid>


                <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                  <InvisibleContent visible={idpessoaresponsavel === 1}>
                    <Spin spinning={loadingTipoPerfil} tip="Buscando perfil">
                      <Grid container>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          xs={12}
                          style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                        >
                          Filtros
                          <SelectAntd
                            style={{ width: '100%' }}
                            placeholder="Filtros"
                            onChange={(e: string) => {
                              setValueInput('');
                              handleSelectFilter(e);
                              setViewConfirmation(false);
                              formPessoaRef.setFieldsValue({
                                cpf: undefined,
                              });
                              setEnableSearchForm(true);
                            }}
                          >
                            {optionsFilterResponsavel.map((option, index) => (
                              <SelectAntd.Option key={option} value={option}>
                                {option}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Grid>

                        <Grid
                          item
                          md={9}
                          lg={9}
                          xs={12}
                          style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                        >
                          <Form.Item
                            name="cpf"
                            label="Buscar por"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Digite um documento',
                              },
                            ]}
                            style={{ paddingTop: 0 }}
                          >
                            <Input
                              disabled={!enableSearchForm}
                              minLength={11}
                              maxLength={14}
                              name={selectedFilter}
                              value={valueInput}
                              type={
                                selectedFilter === 'ID' || selectedFilter === 'ID SARA'
                                  ? 'number'
                                  : 'text'
                              }
                              onChange={(e: any) => {
                                setViewConfirmation(false);
                                if (selectedFilter === 'CPF') {
                                  formPessoaRef.setFieldsValue({
                                    cpf: MaskCPFeCNPJ(e.target.value).valueModify,
                                  });
                                }
                                if (
                                  selectedFilter === 'CPF' &&
                                  e.target.value.length >= 11
                                ) {
                                  debounceOnChange(e.target.value);
                                } else if (selectedFilter !== 'CPF') {
                                  debounceOnChange(e.target.value);
                                }
                              }}
                            />
                          </Form.Item>
                        </Grid>

                        {viewConfirmation && (
                          <Grid container>
                            <Grid item lg={2} xs={12} sm={12} style={{ marginTop: 10 }}>
                              <Form.Item hasFeedback  label="ID">
                              <strong>
                                <Input
                                  placeholder="ID"
                                  value= {statePessoa.id_responsavel}
                                />
                            </strong>
                              </Form.Item>
                            </Grid>

                            <Grid item lg={8} xs={12} sm={12} style={{ marginTop: 10 }}>
                              <Form.Item hasFeedback  label="Nome">
                              <strong>
                                <Input
                                  placeholder="Nome"
                                  value= {statePessoa.nome_responsavel}
                                />
                            </strong>
                              </Form.Item>
                            </Grid>

                            <Grid item sm={2} xs={12} style={{  marginTop: 25 }}>
                              <Form.Item>
                                <IconButton
                                  onClick={() => window.open(`/cadastro/${statePessoa.id_responsavel}/`)}
                                >
                                  <Visibility />
                                </IconButton>
                              </Form.Item>
                              </Grid>
                          </Grid>
                        )}
                        <div style={{color: "#FF0000"} }>
                          {respostaErroBuscaPerfil}
                        </div>
                      </Grid>
                    </Spin>
                  </InvisibleContent>
                </Grid>
              </div>

              <Grid item container justify="space-around" sm={12} xs={12} lg={12} style={{paddingTop: 20}}></Grid>

              <div
                style={{
                  background: getColors()?.branco,
                  border: `1px dashed ${getColors()?.azulEscuro} `,
                  minHeight: '10',
                  maxHeight: '10',
                  width: 490,
                  padding: 3,
                  marginLeft: 10,
                }}
              >

                <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                  <p>Possui indicador?</p>

                  <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                    <Form.Item>
                      <Radio.Group>
                        <Radio value={1} onChange={(e: any) => setIdpessoaIndicador(e.target.value)}  >Sim</Radio >
                        <Radio value={0} onChange={(e: any) => setIdpessoaIndicador(e.target.value)} >Não</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Grid>
                </Grid>

                <Grid item container justify="space-around" sm={12} xs={12} lg={12}>
                  <InvisibleContent visible={idpessoaIndicador === 1}>
                    <Spin spinning={loadingTipoPerfilIndicador} tip="Buscando perfil">
                      <Grid container>
                        <Grid
                          item
                          md={3}
                          lg={3}
                          xs={12}
                          style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                        >
                          Filtros
                          <SelectAntd
                            style={{ width: '100%' }}
                            placeholder="Filtros"
                            onChange={(e: string) => {
                              setValueInputIndicador('');
                              handleSelectFilter(e);
                              setViewConfirmationIndicador(false);
                              formPessoaRef.setFieldsValue({
                                cpfIndicador: undefined,
                              });
                              setEnableSearchFormIndicador(true);
                            }}
                          >
                            {optionsFilterIndicador.map((option, index) => (
                              <SelectAntd.Option key={option} value={option}>
                                {option}
                              </SelectAntd.Option>
                            ))}
                          </SelectAntd>
                        </Grid>

                        <Grid
                          item
                          md={9}
                          lg={9}
                          xs={12}
                          style={{ padding: 10, paddingBottom: 5, paddingLeft: 0 }}
                        >
                          <Form.Item
                            name="cpfIndicador"
                            label="Buscar por"
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Digite um documento',
                              },
                            ]}
                            style={{ paddingTop: 0 }}
                          >
                            <Input
                              disabled={!enableSearchFormIndicador}
                              minLength={14}
                              maxLength={18}
                              name={selectedFilterIndicador}
                              value={valueInputIndicador}
                              type={
                                selectedFilterIndicador === 'ID' || selectedFilterIndicador === 'ID SARA'
                                  ? 'number'
                                  : 'text'
                              }
                              onChange={(e: any) => {
                                setViewConfirmationIndicador(false);
                                if (selectedFilterIndicador === 'CPF/CNPJ') {
                                  formPessoaRef.setFieldsValue({
                                    cpfIndicador: MaskCPFeCNPJ(e.target.value).valueModify,
                                  });
                                }
                                if (
                                  selectedFilterIndicador === 'CPF/CNPJ' &&
                                  e.target.value.length >= 14
                                ) {
                                  debounceOnChangeIndicador(e.target.value);
                                } else if (selectedFilter !== 'CPF/CNPJ') {
                                  debounceOnChangeIndicador(e.target.value);
                                }
                              }}
                            />
                          </Form.Item>
                        </Grid>

                        {viewConfirmationIndicador && (
                          <Grid container>
                            <Grid item lg={2} xs={12} sm={12} style={{ marginTop: 10 }}>
                              <Form.Item hasFeedback  label="ID" >
                              <strong>
                                <Input
                                  placeholder="ID"
                                  value= {statePessoaIndicador.id_indicador}
                                />
                                 </strong>
                              </Form.Item>
                            </Grid>

                            <Grid item lg={8} xs={12} sm={12} style={{ marginTop: 10 }}>
                              <Form.Item hasFeedback  label="Nome">
                              <strong>
                                <Input
                                  placeholder="Nome"
                                  value= {statePessoaIndicador.nome_indicador}
                                />
                            </strong>
                              </Form.Item>
                            </Grid>

                            <Grid item sm={2} xs={12} style={{  marginTop: 25 }}>
                              <Form.Item>
                                <IconButton
                                  onClick={() => window.open(`/cadastro/${statePessoaIndicador.id_indicador}/`)}
                                >
                                  <Visibility />
                                </IconButton>
                              </Form.Item>
                              </Grid>
                          </Grid>
                        )}
                        <div style={{color: "#FF0000"} }>
                          {respostaErroBuscaPerfilIndicador}
                        </div>
                      </Grid>
                    </Spin>
                  </InvisibleContent>
                </Grid>
              </div>

               {/*  <Grid
                  item
                  lg={12}
                  xs={12}
                  style={{ paddingRight: 10, paddingLeft: 10 }}
                >
                  <Form.Item
                    label="CPF/CNPJ do Indicador"
                    hasFeedback
                    name="documento_indicador"
                    rules={[{ required: false, message: 'Campo obrigatorio' }]}
                  >
                    <Input
                      maxLength={18}
                      onChange={e => {
                        const { valueModify } = MaskCPFeCNPJ(e.target.value);

                        formPessoaRef.setFieldsValue({
                          documento_indicador: valueModify,
                        });
                      }}
                      placeholder="CPF/CNPJ do Indicador"
                    />
                  </Form.Item>
                </Grid>*/}

                {/* <Grid
                  item
                  lg={12}
                  xs={12}
                  style={{ paddingRight: 10, paddingLeft: 10 }}
                >
                  <Form.Item
                    label="Origem inicial"
                    hasFeedback
                    name="origem_inicial"
                    rules={[
                      { required: true, message: 'Campo obrigatorio' },
                      { min: 3, message: 'Minimo de 3 letras' },
                    ]}
                  >
                    <Input placeholder="Origem inicial" />
                  </Form.Item>
                </Grid>

                <Grid
                  item
                  lg={12}
                  xs={12}
                  style={{ paddingRight: 10, paddingLeft: 10 }}
                >
                  <Form.Item
                    label="Motivo inicial"
                    hasFeedback
                    name="motivo_inicial"
                    rules={[
                      { required: true, message: 'Campo obrigatorio' },
                      { min: 3, message: 'Minimo de 3 letras' },
                    ]}
                  >
                    <Input placeholder="Motivo inicial" />
                  </Form.Item>
                </Grid> */}
              <InvisibleContent visible={loadingTipoPerfilIndicador === false && loadingTipoPerfil === false && respostaErroBuscaPerfil === '' && respostaErroBuscaPerfilIndicador === ''}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
                >
                  <ButtonComponent
                    color="primary"
                    disabled={!pessoaId ? loadingCreatePessoa : true}
                    loading={loadingCreatePessoa}
                    fullWidth
                    type="submit"
                  >
                    <AddOutlined style={{ marginRight: 10 }} /> Cadastrar pessoa
                  </ButtonComponent>
                </Grid>
                </InvisibleContent>

                <InvisibleContent visible={loadingTipoPerfilIndicador === true || loadingTipoPerfil === true || respostaErroBuscaPerfil !== '' || respostaErroBuscaPerfilIndicador !== ''}>
                <Grid
                  item
                  sm={12}
                  xs={12}
                  style={{ marginTop: 10, paddingLeft: 10, paddingRight: 10 }}
                >
                  <ButtonComponent
                    color="primary"
                    disabled
                    loading={loadingCreatePessoa}
                    fullWidth
                    type="submit"
                  >
                    <AddOutlined style={{ marginRight: 10 }} /> Cadastrar pessoa
                  </ButtonComponent>
                </Grid>
                </InvisibleContent>


              </Grid>
            </Form>
          </Spin>
        </Grid>
        {/* FORMULARIO  */}

        {/* Component Perfil */}
        <Grid item sm={7} style={{ padding: 10 }}>
          {pessoaId && (
            <Grid item sm={7}>
              <CreatePerfil
                idUsuario={pessoaId}
                finishCreate={() => history.push(`/cadastro/${pessoaId}/`)}
                data={data}
              />
            </Grid>
          )}
        </Grid>
        {/* Component Perfil */}
      </Grid>
    </>
  );
};

export default EditCadastro;
