import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import Service, { IListaSituacoes, ICreatePalitagemAxios } from './services';
import * as S from './styles';
import { IconButton } from '@material-ui/core';
import { ChevronRight, Visibility } from '@material-ui/icons';

import Modal from '@components/Shared/Modal/ModalPure';
import { Grid } from '@material-ui/core';
import {
  Form,
  Select as SelectAntd,
  Input,
  DatePicker,
  Spin,
  Radio,
  Space,
  TimePicker,

} from 'antd';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@components/Shared/InvisibleContent';
import Table from '@components/Shared/Tables/NoPagination';
import { SaveOutlined } from '@material-ui/icons';

import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';

import { HubConnectionBuilder } from '@microsoft/signalr';

import { useAuth } from '../AuthenticateContext';
import { IPerfil } from '@src/@interfaces/UserComplete';
import { MaskCPFeCNPJ } from '@src/utils/Masks';
import useDebounce from '../useDebounce';
import Services from '@src/Pages/Private/Cliente/Admin/InsercaoDoacoes/services';
import serviceSocket from '@src/utils/SendSocket';
import { UrlServerAPI } from '@src/Configs/app';
import { validateYupSchema } from 'formik';



interface IProps {
  id_ligacao_interacao: number | null;
  id_estrategia: number | null;
  estrategia_nome: string | null;
  id_pessoa: number | null;
  id_perfil: number | null;
  id_estrategia_ligacao: number | null;
  nomeEstrategia: string | null;
  nomePessoa: string | null;
  contatos_perfil: string[] | null;
}

interface INameFunction {
  example: IProps;
}

interface IFooterTelefoniaContextData {
  state: IProps;
  nameFunction4(data: INameFunction): void;
  openModalPalitagemReceptivoOSID(): void;

}

const FooterTelefoniaContext = createContext<IFooterTelefoniaContextData>(
  {} as IFooterTelefoniaContextData,
);

const optionsFilter = ['ID', 'CPF/CNPJ'];

export const FooterTelefoniaProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [modalOpenTelefoniaAtiva, setModalOpenTelefoniaAtiva] =
    useState<boolean>(false);
  const [modalOpenTelefoniaReceptiva, setModalOpenTelefoniaReceptiva] =
    useState<boolean>(false);
  const [listaSituacoes, setListaSituacoes] = useState<IListaSituacoes[]>([]);
  const [situacaoSelected, setSituacaoSelected] = useState<string>('');
  const [contatoSelected, setContatoSelected] = useState<string>('');
  const [connection, setConnection] = useState<any>(null);
  const [loadingTipoPerfil, setLoadingTipoPerfil] = useState(false);
  const [formRef] = Form.useForm();
  const [valueInput, setValueInput] = useState('');
  const [enableSearchForm, setEnableSearchForm] = useState(false);
  const [perfis, setPerfis] = useState<IPerfil[]>([]);
  const [selectedFilter, setSelectedFilter] = useState(
    optionsFilter[optionsFilter.length - 1],
  );

  const [statePessoa, setStatePessoa] = useState({
    Nome: '',
    id: '',
  });
  const [viewConfirmation, setViewConfirmation] = useState(false);


  const [botaoLigacaoDisable, setBotaoLigacaoDisable] = useState(true);
  const [emLigacao, setEmLigacao] = useState(false);

  const [
    id_ligacao_interacaoParaPalitagem,
    setId_ligacao_interacaoParaPalitagem,
  ] = useState<string>('');

  const [modalOpenSugestaoPessoas, setModalOpenSugestaoPessoas] =
    useState(false);

  const [modalExclusao, setmodalExclusao] = useState(false);

  const [motivoDescarte, setMotivoDescate] = useState(0);

  const [pessoasSugestao, setPessoasSugestao] = useState<
    [{ id: number; nome: string; documento: string }] | []
  >([]);

  const [respostaErroBuscaPerfil, setRespostaErroBuscaPerfil] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  //utilização do hook

  const [state, setstate] = useState<IProps>(() => {
    // const example = localStorage.getItem('@amex:example');



    return {
      estrategia_nome: null,
      id_estrategia: null,
      id_estrategia_ligacao: null,
      id_ligacao_interacao: null,
      id_perfil: null,
      id_pessoa: null,
      nomeEstrategia: null,
    } as IProps;
  });

  const nameFunction4 = useCallback(({ example }: INameFunction) => {
    setstate(example);
  }, []);

  //utilização do hook

  const handleSelectFilter = (value: string) => {
    setSelectedFilter(value);

    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
  };

  const getPessoaByDocumento = async (documento: string) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByDocumento(documento);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil(
          'Não existe perfil cadastrado para este CPF/CNPJ',
        );
      }
      if (!error && response) {
        setStatePessoa({
          Nome: response.pessoa.nome,
          id: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation(true);
      }
    } else {
      setViewConfirmation(false);
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este CPF/CNPJ',

      );
    }
    setLoadingTipoPerfil(false);
  };

  const getPessoaByID = async (id: number) => {
    setRespostaErroBuscaPerfil('');
    setLoadingTipoPerfil(true);
    const service = new Services();

    const { error, response } = await service.getPessoaByID(id);

    if (!error && response) {
      setPerfis(response.pessoa.perfis);
      if (response.pessoa.perfis.length <= 0) {
        setRespostaErroBuscaPerfil('Não existe perfil cadastrado para este ID');
      }
      if (!error && response) {
        setStatePessoa({
          Nome: response.pessoa.nome,
          id: response.pessoa.id,
        });
        setPerfis(response.pessoa.perfis);
        setViewConfirmation(true);
      }
    } else {
      setRespostaErroBuscaPerfil(
        'Não existe uma pessoa cadastrada para este ID',
      );
      setViewConfirmation(false);
    }

    setLoadingTipoPerfil(false);
  };

  const debounceOnChange = (value: string) => {
    formRef.setFieldsValue({
      tipo_perfil: undefined,
    });
    setPerfis([]);
    if (selectedFilter === 'CPF/CNPJ') {
      debounceGetPessoaByDocumento(value);
    } else if (selectedFilter === 'ID') {
      debounceGetPessoaByID(parseInt(value));
    }
  };

  const debounceGetPessoaByDocumento = useDebounce(getPessoaByDocumento, 1000);
  const debounceGetPessoaByID = useDebounce(getPessoaByID, 1000);

  const loadSituacoes = async () => {
    const service = new Service();

    const { error, response } = await service.LoadSituacoes();

    if (!error && response) {
      setListaSituacoes(response);
    }
  };

  const checkSituacaoIsAgendamento = () => {
    if (situacaoSelected !== '') {
      const JSONPARSED = JSON.parse(situacaoSelected);

      if (JSONPARSED.descricao.toLowerCase() === 'agendar retorno') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  const handleSubmit = async (values: any) => {
    setButtonLoading(true);
    localStorage.removeItem('@amex:id_estrategia');
    localStorage.removeItem('@amex:contato_perfil');
    console.log(values);

    if (state.id_ligacao_interacao === null) {
      console.log(state);
      console.log('entrou no if');
      return;
    }

    const objeto: ICreatePalitagemAxios = {
      id_ligacao_interacao: Number(state.id_ligacao_interacao),
      data_agendamento: checkSituacaoIsAgendamento()
        ? values['agendamento'].format('YYYY-MM-DD HH:mm:ss')
        : null,
      id_situacao: JSON.parse(values.situacao).id,
      telefone: values.telefone,
      observacao: values.observacao,
    };

    const services = new Service();

    const { error } = await services.createPalitagem(objeto);
    setButtonLoading(false);
    if (error) {
      console.log('erro palitagem');
    } else {
      enqueueSnackbar(`Palitagem feita com sucesso!`, {
        title: 'Palitagem feita com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      setNullState();
      setModalOpenTelefoniaAtiva(false);

      const socket = new serviceSocket();
      socket.sendEventSocket({
        eventName: `disconnect-webphone-ativo-${user.id}`,
        message: JSON.stringify({ emLigacao: false }),
      });

      history.push('/ligacoes');
    }
  };


  const createLigacaoLeucotron = async () => {
    const numeroDestino = formRef.getFieldValue('contato');
    const services = new Service();

    const { error, response } = await services.CreateLigacaoOSID(
      user.ramal_usuario,
      numeroDestino,
      state.id_ligacao_interacao,

    );

  };

  const finalizaLigacaoLeucotron = async () => {
    const numeroDestino = formRef.getFieldValue('contato');
    const services = new Service();

    const { error, response } = await services.FinalizaLigacaoOSID(
      //id_ligacao,
      user.ramal_usuario,

    );
  };


  const finalizarInteracao = async () => {
    if (motivoDescarte == 1) {
      deleteInterencao();
    } else {
      IgnorarLigacao();
    }
  };

  const deleteInterencao = async () => {
    const service = new Service();

    if (state.id_ligacao_interacao) {
      const { error } = await service.DeleteInteracao(
        state.id_ligacao_interacao,
      );

      if (!error) {
        setNullState();

        history.push('/ligacoes');
      }
    }
  };

  const IgnorarLigacao = async () => {
    const service = new Service();

    if (state.id_ligacao_interacao) {
      const { error } = await service.AvancarInteracao(
        state.id_ligacao_interacao,
      );
      if (!error) {
        setNullState();

        history.push('/ligacoes');
      }
    }
  };

  const checkPalitagem = async () => {
    const service = new Service();

    const { error, response } = await service.CheckPalitagem();

    if (!error && response) {
      if (response.receptivo) {
        setModalOpenTelefoniaReceptiva(true);
        setId_ligacao_interacaoParaPalitagem(
          response.id_ligacao_interacao.toString(),
          // response.estrategia_nome
        );
      } else {
        localStorage.removeItem('@amex:id_estrategia');
        localStorage.removeItem('@amex:contato_perfil');
        setId_ligacao_interacaoParaPalitagem(
          response.id_ligacao_interacao.toString(),
        );
        setModalOpenTelefoniaAtiva(true);
        setNullState();

        const socket = new serviceSocket();
        socket.sendEventSocket({
          eventName: `disconnect-webphone-ativo-${user.id}`,
          message: JSON.stringify({ emLigacao: false }),
        });
      }
    }
  };

  useEffect(() => {
    loadSituacoes();
    checkPalitagem();
  }, []);

  useEffect(() => {
    const newConnection = new HubConnectionBuilder()
      .withUrl(UrlServerAPI + '/socket')
      .withAutomaticReconnect()
      .build();
    setConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result: any) => {
          console.log('Connected!');

          connection.on(
            `palitagem-ativa-userid-${user.id}`,
            (data: {
              id_ligacao_interacao: number;
              telefone: string;
              pessoa_documento?: string;
              pessoa_perfil_descricao?: string;
            }) => {
              localStorage.removeItem('@amex:id_estrategia');
              localStorage.removeItem('@amex:contato_perfil');
              setId_ligacao_interacaoParaPalitagem(
                data.id_ligacao_interacao.toString(),
              );
              setModalOpenTelefoniaAtiva(true);
            },
          );

          connection.on(
            `palitagem-receptiva-userid-${user.id}`,
            (message: any) => {
              console.log('recebeu palitagem receptiva', message);

              setId_ligacao_interacaoParaPalitagem(
                message.id_ligacao_interacao,
              );
              setModalOpenTelefoniaReceptiva(true);
            },
          );

          connection.on(`palitagem-ativa-userid-${user.id}-close`, (_: any) => {
            localStorage.removeItem('@amex:id_estrategia');
            localStorage.removeItem('@amex:contato_perfil');

            setModalOpenTelefoniaAtiva(false);
          });

          connection.on(
            `palitagem-receptiva-userid-${user.id}-close`,
            (message: any) => {
              setModalOpenTelefoniaReceptiva(false);
            },
          );

          connection.on(`em-ligacao-telefonia-${user.id}`, (message: any) => {
            setEmLigacao(true);
            setBotaoLigacaoDisable(true);
          });

          connection.on(`dados-para-telefonia-${user.id}`, (data: string) => {
            console.log('Chegou os dados para telefonia');
            const JSONPARSED: IProps = JSON.parse(data);

            setstate(JSONPARSED);
          });

          connection.on(
            `disconnect-webphone-ativo-${user.id}`,
            (message: any) => {
              setNullState();
            },
          );

          connection.on(`alerta-ligacao-${user.id}`, (message: any) => {
            console.log('chegou');
            alert('Chegando uma nova ligação');
          });

          connection.on(`pessoas-telefone-${user.id}`, (message: any) => {
            if (message.length > 0) {
              setModalOpenSugestaoPessoas(true);
              setPessoasSugestao(message);
              localStorage.setItem(
                '@amex:pessoas_telefone',
                JSON.stringify(message),
              );
            }
          });
        })
        .catch((e: any) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  const setNullState = async () => {
    setstate({
      estrategia_nome: null,
      id_estrategia: null,
      id_estrategia_ligacao: null,
      id_ligacao_interacao: null,
      id_perfil: null,
      id_pessoa: null,
      nomeEstrategia: null,
      nomePessoa: null,
      contatos_perfil: null,
    });

    const socket = new serviceSocket();
    await socket.sendEventSocket({
      eventName: `dados-para-telefonia-${user.id}`,
      message: JSON.stringify({
        id_ligacao_interacao: null,
        id_estrategia: null,
        estrategia_nome: null,
        id_pessoa: null,
        id_perfil: null,
        id_estrategia_ligacao: null,
        nomeEstrategia: null,
        nomePessoa: null,
        contatos_perfil: null,
      }),
    });
  };

  useEffect(() => {
    console.log(state);
  }, [state]);

  const handleSubmitReceptivo = async (values: any) => {
    setButtonLoading(true);
    console.log(values);
    const service = new Service();

    const situacao = JSON.parse(values.situacao).id;
    const perfil = JSON.parse(values.tipo_perfil).perfil_id;

    const { error } = await service.createPalitagemReceptivo({
      data_agendamento: checkSituacaoIsAgendamento()
        ? values['agendamento'].format('YYYY-MM-DD HH:mm:ss')
        : null,
      id_perfil: Number(perfil),
      id_situacao: Number(situacao),
      observacao: values.observacao,
      telefone: values.telefone,
      interacao_inicio: {
        hora: values.dates[0].hours(),
        minuto: values.dates[0].minutes(),
        segundo: values.dates[0].seconds(),
      },
      interacao_fim: {
        hora: values.dates[1].hours(),
        minuto: values.dates[1].minutes(),
        segundo: values.dates[1].seconds(),
      },
    });

    setButtonLoading(false);
    if (error) {
      console.log('error palitagem receptivo');
    } else {
      setModalOpenTelefoniaReceptiva(false);
      enqueueSnackbar(`Palitagem feita com sucesso!`, {
        title: 'Palitagem feita com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
  };

  const openModalPalitagemReceptivoOSID = () => {
    setModalOpenTelefoniaReceptiva(true);
  };

  return (
    <FooterTelefoniaContext.Provider value={{ state, nameFunction4, openModalPalitagemReceptivoOSID }}>
      {/* MODAL TELEFONIA RECEPTIVA */}
      <Modal
        openModal={modalOpenTelefoniaReceptiva}
        title="Palitagem Receptiva"
        size="grande"
        controlModal={setModalOpenTelefoniaReceptiva}
      >
        <Form
          form={formRef}
          size="middle"
          layout="vertical"
          scrollToFirstError={true}
          onFinish={handleSubmitReceptivo}
          labelAlign="left"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          requiredMark={false}
        >
          <Grid container>
            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="situacao"
                label="Situação atual"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma Situação atual',
                  },
                ]}
              >
                <SelectAntd
                  placeholder="Situação atual"
                  onChange={(value: string) => {
                    setSituacaoSelected(value);
                  }}
                >
                  {listaSituacoes.map(item => (
                    <SelectAntd.Option
                      key={item.id}
                      value={JSON.stringify(item)}
                    >
                      {item.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <InvisibleContent visible={checkSituacaoIsAgendamento()}>
              <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                <Form.Item
                  name="agendamento"
                  label="Data de agendamento"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma data para o agendamento',
                    },
                  ]}
                >
                  <DatePicker
                    format=" DD-MM-YYYY HH:mm:ss"
                    disabledDate={disabledDate}
                    showTime={{
                      showSecond: false,
                      disabledHours: () => [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23,
                      ],

                      defaultValue: moment('09:00:00', 'HH:mm:ss'),
                    }}
                  />
                </Form.Item>
              </Grid>
            </InvisibleContent>

            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="dates"
                label="Data início e final da ligação"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione data início e data final',
                  },
                ]}
              >
                <TimePicker.RangePicker />
              </Form.Item>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="telefone"
                label="Telefone"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Digite o telefone',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="observacao"
                label="Observação"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Faça uma observação',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Grid>

            <Spin spinning={loadingTipoPerfil} tip="Buscando perfil">
              <Grid container>
                <Grid
                  item
                  md={3}
                  lg={3}
                  xs={12}
                  style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                >
                  Filtros
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Filtros"
                    onChange={(e: string) => {
                      setValueInput('');
                      handleSelectFilter(e);
                      setViewConfirmation(false);

                      formRef.setFieldsValue({
                        cpf: undefined,
                      });

                      setEnableSearchForm(true);
                    }}
                  >
                    {optionsFilter.map((option, index) => (
                      <SelectAntd.Option key={option} value={option}>
                        {option}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Grid>
                <Grid
                  item
                  md={9}
                  lg={9}
                  xs={12}
                  style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                >
                  <Form.Item
                    name="cpf"
                    label="Buscar por"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Digite um documento',
                      },
                    ]}
                    style={{ paddingTop: 0 }}
                  >
                    <Input
                      disabled={!enableSearchForm}
                      maxLength={18}
                      name={selectedFilter}
                      value={valueInput}
                      type={
                        selectedFilter === 'ID' || selectedFilter === 'ID SARA'
                          ? 'number'
                          : 'text'
                      }
                      onChange={(e: any) => {
                        setViewConfirmation(false);
                        if (selectedFilter === 'CPF/CNPJ') {
                          formRef.setFieldsValue({
                            cpf: MaskCPFeCNPJ(e.target.value).valueModify,
                          });
                        }
                        if (
                          selectedFilter === 'CPF/CNPJ' &&
                          e.target.value.length >= 14
                        ) {
                          debounceOnChange(e.target.value);
                        } else if (selectedFilter !== 'CPF/CNPJ') {
                          debounceOnChange(e.target.value);
                        }
                      }}
                    />
                  </Form.Item>
                </Grid>

                {viewConfirmation && (
                  <Grid container>
                    <Grid item lg={10} xs={12} sm={12} style={{ marginTop: 10 }}>
                      <Form.Item hasFeedback  label="Nome">
                      <strong>
                        <Input
                          placeholder="Nome"
                          value= {statePessoa.Nome}
                        />
                     </strong>
                      </Form.Item>
                    </Grid>

                    <Grid item sm={2} xs={12} style={{  marginTop: 10 }}>
                      <Form.Item label="Cadastro">
                        <IconButton
                          onClick={() => window.open(`/cadastro/${statePessoa.id}/`)}
                        >
                          <Visibility />
                        </IconButton>
                      </Form.Item>

                      </Grid>

                  </Grid>
                )}
                <div style={{color: "#FF0000"} }>
                {respostaErroBuscaPerfil}
                </div>
                <Grid
                  item
                  lg={12}
                  xs={12}
                  style={{ padding: 10, paddingBottom: 0, paddingLeft: 0 }}
                >
                  <Form.Item
                    name="tipo_perfil"
                    label="Tipo de perfil"
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Selecione um perfil',
                      },
                    ]}
                    style={{ paddingTop: 0 }}
                  >
                    <SelectAntd
                      disabled={perfis.length <= 0}
                      placeholder="Tipo de perfil"
                      onChange={e => {
                        console.log(e);
                      }}
                    >
                      {perfis.map((item, index) => (
                        <SelectAntd.Option
                          key={index}
                          value={JSON.stringify(item)}
                        >
                          {item.tipo_perfil_descricao}
                        </SelectAntd.Option>
                      ))}
                    </SelectAntd>
                  </Form.Item>
                </Grid>
              </Grid>
            </Spin>

            <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
              <ButtonComponent
                color={buttonLoading ? 'secondary' : 'primary'}
                disabled={buttonLoading}
                loading={buttonLoading}
                fullWidth
                type="submit"
              >
                <SaveOutlined style={{ marginRight: 10 }} /> Salvar
              </ButtonComponent>
            </Grid>
          </Grid>
        </Form>
      </Modal>
      {/* MODAL TELEFONIA RECEPTIVA */}

      {/* MODAL PALITAGEM TELEFONIA ATIVA */}
      <Modal
        openModal={modalOpenTelefoniaAtiva}
        title="Ligação encerrada"
        size="grande"
        controlModal={setModalOpenTelefoniaAtiva}
      >
        {id_ligacao_interacaoParaPalitagem}

        <Form
          size="middle"
          layout="vertical"
          scrollToFirstError={true}
          onFinish={handleSubmit}
          labelAlign="left"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          requiredMark={false}
        >
          <Grid container>
            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="situacao"
                label="Situação atual"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Selecione uma Situação atual',
                  },
                ]}
              >
                <SelectAntd
                  placeholder="Situação atual"
                  onChange={(value: string) => {
                    setSituacaoSelected(value);
                  }}
                >
                  {listaSituacoes.map(item => (
                    <SelectAntd.Option
                      key={item.id}
                      value={JSON.stringify(item)}
                    >
                      {item.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <InvisibleContent visible={checkSituacaoIsAgendamento()}>
              <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                <Form.Item
                  name="agendamento"
                  label="Data de agendamento"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Selecione uma data para o agendamento',
                    },
                  ]}
                >
                  <DatePicker
                    format=" DD-MM-YYYY HH:mm:ss"
                    disabledDate={disabledDate}
                    showTime={{
                      showSecond: false,
                      disabledHours: () => [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23,
                      ],

                      defaultValue: moment('09:00:00', 'HH:mm:ss'),
                    }}
                  />
                </Form.Item>
              </Grid>
            </InvisibleContent>

            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="observacao"
                label="Observação"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Faça uma observação',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 10 }}>
              <Form.Item
                name="telefone"
                label="Telefone"
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Digite o telefone',
                  },
                ]}
              >
                <Input.TextArea />
              </Form.Item>
            </Grid>

            <Grid item sm={12} xs={12} style={{ paddingTop: 10 }}>
              <ButtonComponent
                color={buttonLoading ? 'secondary' : 'primary'}
                disabled={buttonLoading}
                loading={buttonLoading}
                fullWidth
                type="submit"
              >
                <SaveOutlined style={{ marginRight: 10 }} /> Salvar
              </ButtonComponent>
            </Grid>
          </Grid>
        </Form>
      </Modal>
      {/* MODAL PALITAGEM TELEFONIA ATIVA */}

      {/* id: number; nome: string; documento: string; */}

      {/* MODAL PESSOAS TELEFONIA RECEPTIVA
      <Modal
        size="grande"
        openModal={modalOpenSugestaoPessoas}
        controlModal={setModalOpenSugestaoPessoas}
        title="Sugestão de cadastros"
      >
        <Grid container>
          <Table
            data={pessoasSugestao}
            loading={false}
            columns={['id', 'nome', 'documento']}
            Actions={[
              {
                Button: (row: any) => {
                  return (
                    <IconButton
                      onClick={() => window.open(`/cadastro/${row.id}/`)}
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Modal>
      {/* MODAL PESSOAS TELEFONIA RECEPTIVA */}

      {/* MODAL Confirmação de exclusão
      <Modal
        size="pequeno"
        openModal={modalExclusao}
        controlModal={setmodalExclusao}
        title="Motivo do descarte da ligação"
      >
        <Grid container>
          <Grid item xs={12} lg={12} md={12} style={{ marginTop: 20 }}>
            <Radio.Group
              onChange={e => {
                console.log(e.target.value);
                setMotivoDescate(e.target.value);
              }}
            >
              <Space direction="vertical">
                <Radio value="1">
                  Retornar cadastro para a lista de ligação.
                </Radio>
                <Radio value="2">
                  Objetivo concluído sem necessidade de contato com o doador.
                </Radio>
              </Space>
            </Radio.Group>
          </Grid>
          <Grid item xs={6} lg={6} md={6} style={{ marginTop: 20 }}>
            <ButtonComponent
              color={'primary'}
              onClick={() => finalizarInteracao()}
              fullWidth
            >
              Confirmar
            </ButtonComponent>
          </Grid>
        </Grid>
      </Modal>
      {/* MODAL Confirmação de exclusão */}

      {children}

      <InvisibleContent
        visible={
          state.id_ligacao_interacao !== null &&
          modalOpenTelefoniaAtiva === false
        }
      >
        <S.Container>
          <S.TextoFooter>
            <span style={{ color: '#BE638D ' }}> Estratégia:</span>
            {state.estrategia_nome}
          </S.TextoFooter>
          <S.TextoFooter>
            <span style={{ color: '#BE638D ' }}>Cadastro: </span>{' '}
            {state.nomePessoa}
            <IconButton
              onClick={() =>
                window.open(
                  `/cadastro/${state.id_pessoa}?perfil=${state.id_perfil}`,
                )
              }
            >
              <Visibility htmlColor="#BE638D " />
            </IconButton>
          </S.TextoFooter>

          <Form
          form={formRef}
          //size="middle"
          //layout="vertical"
          //scrollToFirstError={true}
          //onFinish={handleSubmitReceptivo}
          //labelAlign="left"
          //labelCol={{
            //span: 24,
         // }}
          //wrapperCol={{
//span: 24,
         // /}}
          requiredMark={false}>


          {!emLigacao && (
            <Form.Item
              style={{ width: 200 }}
              name="contato"
              label=""
              hasFeedback
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >

              <SelectAntd
                size="large"
                placeholder="Selecionar contato"
                onChange={(value: string) => {
                  // setSituacaoSelected(value);
                  setContatoSelected(value);
                  setBotaoLigacaoDisable(false);
                }}
              >
                {state.contatos_perfil?.map((item, index) => (
                  <SelectAntd.Option key={index} value={item}>
                    {item}
                  </SelectAntd.Option>
                ))}
              </SelectAntd>

              {/*<Input
                size="large"
                placeholder="Selecionar contato"
                onChange={() => {
                  // setSituacaoSelected(value);
                  //setContatoSelected(value);
                  setBotaoLigacaoDisable(false);
                }}
              >

              </Input>*/}
            </Form.Item>

          )}
 </Form>
          <S.ButtonLigacao
            onClick={() => createLigacaoLeucotron()}
            disabled={botaoLigacaoDisable === true}
          >
            {emLigacao ? 'Em ligação' : 'Realizar ligação'}
          </S.ButtonLigacao>

          {!emLigacao && (
            <S.ButtonDescartarLigacao onClick={() => {finalizaLigacaoLeucotron(); setModalOpenTelefoniaAtiva(true)}}>
              Finalizar ligação
            </S.ButtonDescartarLigacao>
          )}
        </S.Container>
      </InvisibleContent>
    </FooterTelefoniaContext.Provider>
  );
};

export function useFooterTelefoniaOSID(): IFooterTelefoniaContextData {
  const context = useContext(FooterTelefoniaContext);

  if (!context) {
    throw new Error(
      'useFooterTelefonia must be used within an FooterTelefoniaProvider',
    );
  }
  return context;
}
