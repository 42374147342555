import api from '@http/api';

export interface IItemsDashboardMes {
  mes: string;
  doadores_mes: number;
  doacoes_mes: number;
  valor_doacoes_mes: number;
  cadastros_mes: number;
  percentual_doadores: number;
  media_per_capta: number;
}

export interface IItemsMes {
  descricao: string;
  janeiro: number;
  fevereiro: number;
  marco: number;
  abril: number;
  maio: number;
  junho: number;
  julho: number;
  agosto: number;
  setembro: number;
  outubro: number;
  novembro: number;
  dezembro: number;
}

class PageDashboardServices {

   public async loadDashboardCadastrosNovos() {
    return await api
      .post('/api/Dashboard/FindDashboardCadastrosNovos')
      .then(response => {
        const total_cadastros = response.data.total_cadastros;
        const total_doacoes = response.data.total_doacoes;
        const valor_total_doacoes = response.data.valor_total_doacoes;
        const media_per_capta_anual = response.data.media_per_capta_anual;
        const comparacao_mes = response.data.comparacao_periodo;

        var itensGraficoNovos: IItemsDashboardMes[] = [];
        var itensorigensCadastroNovos: IItemsMes[] = [];
        var itenstiposCorrespondenciaNovos: IItemsMes[] = [];
        var itensformasContribuicaoNovos: IItemsMes[] = [];

        comparacao_mes.map((item: IItemsDashboardMes) => {
          return itensGraficoNovos.push({
            mes: item.mes,
            doadores_mes: item.doadores_mes,
            doacoes_mes: item.doacoes_mes,
            valor_doacoes_mes: item.valor_doacoes_mes,
            cadastros_mes: item.cadastros_mes,
            percentual_doadores: item.percentual_doadores,
            media_per_capta: item.media_per_capta,
          });
        });


        return {
          error: false,
          response: { total_cadastros,
                      total_doacoes,
                      valor_total_doacoes,
                      media_per_capta_anual,
                      comparacao_mes: itensGraficoNovos,
                      origem_cadastro: itensorigensCadastroNovos,
                      tipo_correspondencia: itenstiposCorrespondenciaNovos,
                      forma_contribuicao: itensformasContribuicaoNovos
                    },
        };
      })
      .catch(error => {
        console.log('caiu aqui service dashboard', error);

        return {
          error: true,
          response: [],
        };
      });
  }
}

export default PageDashboardServices;


