import React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';

import { Container } from './style';

interface IProps {
  changeFunction?: Function;
  title?: string;
  minutesDefault: number;
}

const CountDown: React.FC<IProps> = ({
  changeFunction,
  title,
  minutesDefault,
}) => {
  let countdownTimeout: any;

  const [time, setTime] = useState(minutesDefault * 60);
  const [isActive, setIsActive] = useState(true);
  const [clear, setClear] = useState(false);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  const startCountdown = useCallback(() => {
    setIsActive(true);
  }, []);

  const resetCountdown = useCallback(() => {
    clearTimeout(countdownTimeout);
    setIsActive(false);
    setTime(minutesDefault * 60);
  }, []);

  useEffect(() => {
    if (time <= 0 || clear) {
      setClear(false);
      resetCountdown();
      startCountdown();
      if (changeFunction) {
        changeFunction();
      }
    } else if (time > 0) {
      countdownTimeout = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }
  }, [isActive, time]);

  const [minuteLeft, minuteRight] = String(minutes).padStart(2, '0').split('');
  const [secondLeft, secondRight] = String(seconds).padStart(2, '0').split('');

  return (
    <>
      <Container>
        <IconButton onClick={() => setClear(true)}>
          <ReplayIcon color="primary" />
        </IconButton>
        <span>
          {!title
            ? `Tempo para atualização da tela ${minuteLeft}`
            : `${title} ${minuteLeft}`}
          {minuteRight}:{secondLeft}
          {secondRight}
        </span>
      </Container>
    </>
  );
};

export default CountDown;
