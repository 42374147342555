import api from '@http/api';

export interface IRequestClienteConfiguracaoEstrategiaAcao {
  estrategia_acao_id: string;
  banco_id: string;
  valor_padrao: boolean;
  valor_boletos: any;
  vencimento: string;
  convenio_id?: number;
}

export interface IRequestClienteConfiguracaoEstrategiaAcaoEmail {
  estrategia_acao_id: string;
}

interface IRequestData {
  pageNumber: number;
  pageSize: number;
  estrategiaId: string;
}

export interface IEstrategiaPerfis {
  perfil: {
    perfil_id: string;
    nome: string;
    pessoa: {
      id: number;
      nome: string;
      documento: string;
      data_cadastro: string;
    };
  };
}

export interface IModelo {
  id: string;
  nome: string;
  descricao: string;
  estrategia_perfis: IEstrategiaPerfis[];
}

export interface IConvenio {
  id: number;
  numero_convenio: string;
  nome_cedente: number | string | null;
  numero_agencia: number | string | null;
  digito_agencia: number | string | null;
  numero_conta: number | string | null;
  digito_cedente: number | string | null;
  id_banco: number | string;
  descricao: string;
}

export interface IResponseGetEstrategias {
  total_itens: number;
  total_pagina: number;
  modelo: IModelo[];
}

export interface IAcoes {
  acao_id: number;
  acao_descricao: string;
}
export interface ITiposAcoes {
  tipo_acao_id: string;
  tipo_acao_descricao: string;
  acoes: IAcoes[];
}

interface IResponseTiposAcoes {
  error: boolean;
  response?: ITiposAcoes[];
}

export interface IBancos {
  id: string;
  descricao: string;
  data_cadastro: string;
  status_ativo: number;
  codigo_referencia_externa: string;
}

export interface IEstrategiasAcoes {
  id: string;
  acao_id: string;
  acao_descricao: string;
  diretorio_layout: string;
  gerar_boleto: boolean;
  status: number;
}

export interface ICampanha {
  id: number;
  descricao: string;
}
export interface IResponseGetEstrategiaAcoesByID {
  tipo_acao_id: string;
  tipo_acao_descricao: string;
  estrategias_acoes: IEstrategiasAcoes[];
}

interface IResponseGetBancos {
  error: boolean;
  response?: IBancos[];
}

interface IResponse {
  error: boolean;
  response?: {
    total_itens: number;
    total_pagina: number;
    modelo: IModelo[];
  };
}

interface IResponseCampanha {
  error: boolean;
  response?: ICampanha;
}

class Services {
  public async getEstrategiasPerfil({
    pageNumber,
    pageSize,
    estrategiaId,
  }: IRequestData): Promise<IResponse> {
    return await api
      .get<IResponseGetEstrategias>(
        `/api/Estrategia/GetEstrategiasPerfisPaginadas?estrategiaId=${estrategiaId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      )
      .then(response => {
        console.log(response.data);
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getTiposAcoes(): Promise<IResponseTiposAcoes> {
    return await api
      .get<ITiposAcoes[]>(`/api/EstrategiaAcao/GetTiposAcoes`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getBancos(): Promise<IResponseGetBancos> {
    return await api
      .get<IBancos[]>(`/api/EstrategiaAcao/GetBancos`)
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getEstrategiaAcoesByID(estrategiaID: string): Promise<any> {
    return await api
      .get<IResponseGetEstrategiaAcoesByID[]>(
        `/api/EstrategiaAcao/GetEstrategiasAcoesByEstrategiaId?estrategiaId=${estrategiaID}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: false,
        };
      });
  }

  public async clienteConfiguracaoEstrategiaAcao({
    banco_id,
    estrategia_acao_id,
    valor_boletos,
    valor_padrao,
    vencimento,
    convenio_id,
  }: IRequestClienteConfiguracaoEstrategiaAcao): Promise<IResponse> {
    return await api
      .post(`/api/EstrategiaAcao/ClienteConfiguracaoEstrategiaAcao`, {
        banco_id,
        estrategia_acao_id,
        valor_boletos,
        valor_padrao,
        vencimento,
        convenio_id,
      })
      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async clienteConfiguracaoEstrategiaAcaoEmail({
    estrategia_acao_id,
  }: IRequestClienteConfiguracaoEstrategiaAcaoEmail): Promise<IResponse> {
    return await api
      .post(`/api/EstrategiaAcao/ClienteConfiguracaoEstrategiaAcaoEmail`, {
        estrategia_acao_id,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async clienteConfiguracaoEstrategiaAcaoGrafica({
    estrategia_acao_id,
  }: IRequestClienteConfiguracaoEstrategiaAcaoEmail): Promise<IResponse> {
    return await api
      .post(`/api/EstrategiaAcao/ClienteConfiguracaoEstrategiaAcaoGrafica`, {
        estrategia_acao_id,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getCampanhas({ campanha_id }: any): Promise<IResponseCampanha> {
    console.log(campanha_id);
    return await api
      .get(`/api/Campanha/GetCampanhaById/${campanha_id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
  public async GetConvenios() {
    return await api
      .get<IConvenio[]>(
        `api/Financeiro/GetFinanceiroConveniosByFormaContribuicao?formaContribuicaoId=0`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }
}

export default Services;
