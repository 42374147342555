import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '@hooks/AuthenticateContext';

// import { Container } from './styles';
import { Grid } from '@material-ui/core';

import Tag from '@src/components/Shared/Tag';
import ButtonComponent from '@components/Shared/Buttons/Button';

import { Spin, Form, Input, Select as SelectAntd } from 'antd';
import { useSnackbar } from 'notistack';
import Services from './services';

interface IProps {
  Cancel: Function;
  Done: Function;
  id: number;
}

interface IUserType {
  id: number;
  descricao: string;
}

interface IUsuarioOld {
  id?: number;
  nome?: string;
  email?: string;
  senha?: string;
  senhaAntiga?: string;
  nova_senha?: string;
  confirmacao_nova_senha?: string;
  status_ativo?: number;
  entidade_id?: number;
  tipo_usuario_id?: number;
  role?: string;
}

const senhaforte = RegExp(
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})',
);

const EditarUsuario: React.FC = () => {
  const { user } = useAuth();
  const [formRef] = Form.useForm();
  const [loadingCadastro, setLoadingCadastro] = useState(false);
  const [userType, setUserType] = useState<IUserType[]>([]);
  const [usuario, setUsuario] = useState<IUsuarioOld>();
  const { enqueueSnackbar } = useSnackbar();

  async function onSubmit(values: any) {
    if (
      (await updateUserPassword(values)) &&
      (await updateUserNameEmail(values))
    ) {
      loadUsuario(user.id);
    }
  }

  async function updateUserPassword(values: any) {
    const services = new Services();
    if (
      !(values.senha === undefined) &&
      !(values.senhaOld === undefined) &&
      !(values.confirmaSenha === undefined)
    ) {
      const usuario: IUsuarioOld = {
        id: user.id,
        senhaAntiga: values.senhaOld,
        nova_senha: values.senha,
        confirmacao_nova_senha: values.confirmaSenha,
      };

      const { error, response } = await services.UpdateUserPassword(usuario);

      if (!error) {
        enqueueSnackbar('Senha alterada com sucesso!', {
          title: 'Senha alterada com sucesso!',
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return true;
      } else {
        enqueueSnackbar('Erro ao alterar senha!', {
          title: 'Erro ao alterar senha!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return false;
      }
    } else {
      return true;
    }
  }

  async function updateUserNameEmail(values: any) {
    const services = new Services();
    if (
      (!(values.nome === usuario?.nome) && values.nome) ||
      (!(values.email === usuario?.email) && values.email)
    ) {
      const usuario: any = {
        id: user.id,
        nome: values.nome,
        email: values.email,
      };

      const { error, response } = await services.UpdateUserNomeEmail(usuario);

      if (!error) {
        enqueueSnackbar('Nome/E-mail alterado com sucesso!', {
          title: 'Nome/E-mail alterado com sucesso!',
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return true;
      } else {
        enqueueSnackbar('Erro ao alterar Nome/E-mail!', {
          title: 'Erro ao alterar Nome/E-mail!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        return false;
      }
    } else {
      return true;
    }
  }

  const loadUsuario = useCallback(async id => {
    setLoadingCadastro(true);
    const services = new Services();
    const { error, response } = await services.GetUserByID({ userID: id });

    if (!error && response) {
      const usuario: IUsuarioOld = {
        id: response?.id,
        nome: response?.nome,
        email: response?.email,
        tipo_usuario_id: response?.tipo_usuario_id,
        role: response?.role,
      };

      setUsuario(usuario);

      formRef.setFieldsValue({
        nome: usuario?.nome,
        email: usuario?.email,
        tipo_usuario_id: usuario?.tipo_usuario_id,
        role: usuario?.role,
        senhaOld: undefined,
        senha: undefined,
        confirmaSenha: undefined,
      });
      setLoadingCadastro(false);
    } else {
      console.log('error', error);
    }
  }, []);

  useEffect(() => {
    loadUsuario(user.id);
    window.document.title = 'CRM - Perfil';
  }, []);

  return (
    <>
      <Grid container style={{padding:10}}>
        <Grid item sm={2} xs={6}>
          <Tag color="default">Usuário: {user.id}</Tag>
        </Grid>
        <Grid item sm={10} xs={6}></Grid>
        <Grid item sm={3}>
          <Spin size="large" spinning={loadingCadastro} tip="Enviando dados...">
            <Form
              size="middle"
              layout="vertical"
              scrollToFirstError={true}
              onFinish={onSubmit}
              labelAlign="left"
              form={formRef}
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              requiredMark={false}
            >
              <Grid container>
                <Grid item sm={12} xs={12}>
                  <Form.Item
                    label="Nome"
                    hasFeedback
                    name="nome"
                    rules={[{ required: true, message: 'Campo obrigatorio' }]}
                    style={{ padding: 0, paddingTop: 5 }}
                  >
                    <Input placeholder="Nome" />
                  </Form.Item>
                </Grid>
                <Grid item sm={10}></Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    label="E-mail"
                    hasFeedback
                    name="email"
                    rules={[{ required: true, message: 'Campo obrigatorio' }]}
                    style={{ padding: 0, paddingTop: 5 }}
                  >
                    <Input placeholder="E-mail" />
                  </Form.Item>
                </Grid>
                <Grid item sm={10}></Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    label="Senha Atual"
                    hasFeedback
                    name="senhaOld"
                    style={{ padding: 0, paddingTop: 5 }}
                  >
                    <Input placeholder="Senha Atual" type="password" />
                  </Form.Item>
                </Grid>
                <Grid item sm={10}></Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    label="Nova senha"
                    hasFeedback
                    name="senha"
                    rules={[
                      {
                        required: false,
                        pattern: senhaforte,
                        message: 'Senha forte necessária',
                      },
                    ]}
                    style={{ padding: 0, paddingTop: 5 }}
                  >
                    <Input placeholder="Nova senha" type="password" />
                  </Form.Item>
                </Grid>
                <Grid item sm={10}></Grid>

                <Grid item sm={12} xs={12}>
                  <Form.Item
                    label="Confirmação de Senha"
                    hasFeedback
                    name="confirmaSenha"
                    rules={[
                      { required: false },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('senha') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error('As senhas não batem!'),
                          );
                        },
                      }),
                    ]}
                    style={{ padding: 0, paddingTop: 5 }}
                  >
                    <Input placeholder="Confirmação de senha" type="password" />
                  </Form.Item>
                </Grid>
                <Grid item sm={10}></Grid>

                <Grid item sm={12} xs={12} style={{ marginTop: 10 }}>
                  <ButtonComponent
                    color="primary"
                    disabled={loadingCadastro}
                    loading={loadingCadastro}
                    fullWidth
                    type="submit"
                  >
                    Salvar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Spin>
        </Grid>
      </Grid>
    </>
  );
};

export default EditarUsuario;
