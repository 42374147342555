import React, { useEffect, useState } from 'react';

import { addDays } from 'date-fns';
import { Checkbox, Grid, IconButton } from '@material-ui/core';
import { FindInPageOutlined } from '@material-ui/icons';
import { Select as SelectAntd, Form } from 'antd';
import { Spin, Space } from 'antd';
import { useSnackbar } from 'notistack';


import DatePicker2 from '@components/Shared/DatePicker2';
import TableCustomKeysNoPagination from '@src/Pages/Private/Cliente/Admin/HistoricoRemessa/components/table';
import { TitlePage } from '@styles/styles.global';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@components/Shared/InvisibleContent';
import Modal from '@components/Shared/Modal/ModalPure';

import Services, {
  IFormaContribuicao,
  IConvenio,
  IDebitoResultTable,
  ITipoRemessa,
} from './services';

import { useAuth } from '@hooks/AuthenticateContext';

// BANCO = CONVENIO
// TIPO_REMESSA = COD MOVIMENTO

const DEBITO_NORMAL_DATA = addDays(new Date(), 0);
const DEBITO_NORMAL_DATA_Ok = addDays(new Date(), 4);

const DESCRICAO_BOLETO = 'boleto';
const DESCRICAO_DEBITO = 'débito automático';
const DESCRICAO_ENERGIA= 'conta de energia';

const HistoricoRemessa: React.FC = () => {
  const [formRef] = Form.useForm();
  const [formasContribuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);
  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [debitos, setDebitos] = useState<IDebitoResultTable[]>([]);
  const [loading, setLoading] = useState(true);

  const [tipoRemessa, setTipoRemessa] = useState<ITipoRemessa[]>([]);

  const [formaContribuicaoSelected, setFormaContruibuicaoSelected] =
    useState<number>(0);

  const [stringTipoRemessa, setStringTipoRemessa] = useState<string>('');

  const [rangeData, setRangeData] = useState<Date[]>([
    DEBITO_NORMAL_DATA,
    DEBITO_NORMAL_DATA,
  ]);
  const { enqueueSnackbar } = useSnackbar();




  const loadTipoRemessa = async (
    formaContribuicaoID: string | number,
  ): Promise<number> => {
    const service = new Services();
    const { error, response } = await service.GetTipoRemessa(
      formaContribuicaoID,
    );
    if (!error && response) {
      setTipoRemessa(response);
      if (response.length > 0) {
        formRef.setFieldsValue({
          tipoRemessa: response[0]?.id,
        });

        setStringTipoRemessa(response[0]?.descricao);
        return response[0].id;
      } else {
        setStringTipoRemessa('');

        formRef.setFieldsValue({
          tipoRemessa: response[0]?.id,
        });
        return 0;
      }
    } else {
      return 0;
    }
  };

  const loadFormasContribuicao = async () => {
    const services = new Services();
    const { error, response } = await services.loadFormaContribuicao();

    if (!error && response) {
      setFormasContribuicao(response);
      // const foundFormaContribuicao = response.find(
      //   x => x.id === formaContribuicaoSelected,
      // );

      // if (!foundFormaContribuicao) {
      //   return 0;
      // }

      // if (foundFormaContribuicao.descricao.toLowerCase() === DESCRICAO_DEBITO) {
      //   setRangeData([DEBITO_NORMAL_DATA, DEBITO_NORMAL_DATA]);
      // }

      setFormaContruibuicaoSelected(Number(response[0].id));
      setRangeData([DEBITO_NORMAL_DATA, DEBITO_NORMAL_DATA]);
      return Number(response[0].id);
    } else {
      return 0;
    }
  };

  const loadConvenios = async (
    idFormaContribuicao: number | string,
  ): Promise<number> => {
    const services = new Services();
    const { error, response } = await services.GetConvenios(
      idFormaContribuicao,
    );

    if (!error && response) {
      setConvenios(response);
      formRef.setFieldsValue({ convenio: response[0]?.id });

      console.log('TESTEEE', response);

      return response[0]?.id;
    } else {
      console.log('ENTROU AQUI');

      formRef.setFieldsValue({ convenio: null });
      return 0;
    }
  };

  const loadDebitos = async (
    idConvenvio?: number,
    tipoRemessa?: number,
    rangeDatas?: Date[],
  ) => {
    setLoading(true);

        const services = new Services();

        const { error, response } = await services.GetListDebitosAgrupados(
        1,
        1,
        idConvenvio ? idConvenvio : formRef.getFieldValue('convenio'),
        tipoRemessa
            ? tipoRemessa
            : formRef.getFieldValue('tipoRemessa'),

        rangeDatas
            ? `${rangeDatas[0].toLocaleDateString('pt-BR')}`
            : `${rangeData[0].toLocaleDateString('pt-BR')}`,
        rangeDatas
            ? `${rangeDatas[1].toLocaleDateString('pt-BR')}`
            : `${rangeData[1].toLocaleDateString('pt-BR')}`,
        );

    //padrao de data eua


        if (!error) {
            setDebitos(response?.modelo || []);
        }

        setLoading(false);
    };


  const firstLoadDebitos = async () => {
    const formaContribuicaoID = await loadFormasContribuicao();
    const convenioID = await loadConvenios(formaContribuicaoID);
    const tipoRemessa = await loadTipoRemessa(formaContribuicaoID);

    await loadDebitos(convenioID, tipoRemessa);
  };

  const handleChangeDates = (dates: Date[]) => {
    setRangeData(dates);
    loadDebitos(undefined, undefined, dates);
  };

  useEffect(() => {
    window.document.title = 'CRM - Histórico Remessa';
    setLoading(true);
    firstLoadDebitos();
    setLoading(false);
  }, []);

  const getConvenioFilter = (id: number | string) => {
    const convenioFiltered = convenios.filter(x => x.id === id);

    if (convenioFiltered.length > 0) {
      return convenioFiltered[0];
    } else {
      return id;
    }
  };

  const getCodigoMovimento = (id: number | string) => {
    const tipoRemessaFiltered: ITipoRemessa[] = tipoRemessa.filter(
      x => x.id === id,
    );

    if (tipoRemessaFiltered.length > 0) {
      setStringTipoRemessa(tipoRemessaFiltered[0].descricao);

      return tipoRemessaFiltered[0];
    } else {
      return id;
    }
  };

  const changeFormaContribuicao = async (idFormaContribuicao: number) => {
    const convenioID = await loadConvenios(idFormaContribuicao);
    const tipoRemessa = await loadTipoRemessa(idFormaContribuicao);

    await loadDebitos(convenioID, tipoRemessa);
  };

  const handleArquivoRemessaBoleto = async (arquivoId: string) => {
    const services = new Services();

    const { error, response } = await services.arquivoRemessaBoleto(arquivoId);

    if (!error && response?.link_remessa) {
      window.open(response?.link_remessa);
    } else {
      console.log(error);
    }

  };

  const handleArquivoRemessaDebito = async (arquivoId: string) => {
    const services = new Services();

    const { error, response } = await services.arquivoRemessaDebito(arquivoId);

    if (!error && response?.link_remessa) {
      window.open(response?.link_remessa);
    } else {
      console.log(error);
    }

  };

  const handleArquivoCSV = async (arquivoId: string, tipoRemessa: string ) => {
    const services = new Services();

    const { error, response } = await services.arquivoRemessaCSV(
      arquivoId,
      tipoRemessa
      ? tipoRemessa
      : formRef.getFieldValue('tipoRemessa'),
    );

    if (!error && response) {
      window.open(response);
    } else {
      console.log(error);
    }

  };

  useEffect(() => {
    setLoading(true);
    changeFormaContribuicao(formaContribuicaoSelected);
    setLoading(false);
  }, [formaContribuicaoSelected]);


  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };

        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <>
      <TitlePage> Histórico da remessa </TitlePage>

      {/* Boleto ou Debito */}
      <Grid style={{}}>
        <Spin size="large" spinning={loading} tip={'Loading...'}>
          {formasContribuicao.length > 0 &&
            formasContribuicao.map(forma => {
              if (
                forma.descricao.toLowerCase().includes(DESCRICAO_DEBITO) ||
                forma.descricao.toLowerCase().includes(DESCRICAO_BOLETO) ||
                forma.descricao.toLowerCase().includes(DESCRICAO_ENERGIA)
              ) {
                return (
                  <ButtonComponent
                    key={forma.id}
                    style={{ margin: 5 }}
                    color={
                      formaContribuicaoSelected != forma.id
                        ? 'default'
                        : 'secondary'
                    }
                    disabledColor="#ccc"
                    onClick={() => {
                      setFormaContruibuicaoSelected(Number(forma.id));
                      loadTipoRemessa(Number(forma.id));
                    }}
                  >
                    {forma.descricao.toLowerCase()}
                  </ButtonComponent>
                );
              }
            })}
        </Spin>
      </Grid>
      {/* Boleto ou Debito */}

      {/* Debito Automatico */}

      <Grid
        item
        md={8}
        lg={12}
        xs={12}
        // style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
      >
        <Form className="teste2 " layout={'horizontal'} form={formRef}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item lg={3} style={{ padding: 5 }}>
              <DatePicker2
                cleanable={false}
                notUseDefaultValue={false}
                title="Período: "
                color={'secondary'}
                setValor={handleChangeDates}
                placement={'bottomStart'}
                notViewRanges={true}
                rangeInitial={rangeData}
              />
            </Grid>

            <Grid
              item
              lg={2}
              style={{
                padding: 5,
              }}
            >
              <Form.Item name="convenio">
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Banco"
                  onChange={(e: string) => {
                    loadDebitos();
                  }}
                >
                  {convenios.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao + ' - ' + option.numero_convenio}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid
              item
              lg={2}
              style={{
                padding: 5,
              }}
            >
              <Form.Item name={'tipoRemessa'}>
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Tipo Remessa"
                  onChange={(e: string) => {
                    getCodigoMovimento(e);
                    loadDebitos();
                  }}
                >
                  {tipoRemessa.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>
          </Grid>
        </Form>
      </Grid>

      <Grid container>
        <Grid item lg={12}>
        <InvisibleContent
                    visible={stringTipoRemessa.toLowerCase() != 'débito normal'}
                  >
          <TableCustomKeysNoPagination
            data={debitos}
            keys={['arquivoId','data_exportacao', 'nome_arquivo', 'total_registros', 'valor_total', 'usuario_cadastro', 'link_remessa', 'link_csv']}
            loading={loading}
            columns={[
              {
                arquivoId: 'ID',
                data_exportacao: 'Exportado em',
                nome_arquivo: 'Nome do arquivo',
                total_registros: 'Total de registro',
                valor_total: 'Total em R$',
                usuario_cadastro: 'Gerada por',
                link_remessa: 'Arquivo',
                link_csv: 'CSV',
              },
            ]}
            handleDownloadArquivoGrafica={(arquivoId: string) => handleArquivoRemessaBoleto(arquivoId)}
            handleDownloadArquivoCSV={(arquivoId: string, id_tipo_remessa: string) => handleArquivoCSV(arquivoId, id_tipo_remessa)}

            ActionHeader={'Detalhes'}
            Actions={[
              {
                Button: (values: IDebitoResultTable) => {
                  return (
                    <IconButton
                      onClick={() => {
                        const obj = {
                          arquivoId: values,
                          convenio: getConvenioFilter(
                            formRef.getFieldValue('convenio'),
                          ),
                        };

                        window.open(
                          `/historico/remessa/detalhes?params=${JSON.stringify(
                            obj,
                          )}`,
                        );
                      }}
                    >
                      <FindInPageOutlined />
                    </IconButton>
                  );
                },
              },
            ]}

          />

       </InvisibleContent>


        </Grid>
      </Grid>


      <Grid container>
        <Grid item lg={12}>
        <InvisibleContent
                    visible={stringTipoRemessa.toLowerCase() == 'débito normal'}
                  >
          <TableCustomKeysNoPagination
            data={debitos}
            keys={['arquivoId','data_exportacao', 'nome_arquivo', 'total_registros', 'valor_total', 'usuario_cadastro', 'link_remessa', 'link_csv']}
            loading={loading}
            columns={[
              {
                arquivoId: 'ID',
                data_exportacao: 'Exportado em',
                nome_arquivo: 'Nome do arquivo',
                total_registros: 'Total de registro',
                valor_total: 'Total em R$',
                usuario_cadastro: 'Gerada por',
                link_remessa: 'Arquivo',
                link_csv: 'CSV',
              },
            ]}
            handleDownloadArquivoGrafica={(arquivoId: string) => handleArquivoRemessaDebito(arquivoId)}
            handleDownloadArquivoCSV={(arquivoId: string, id_tipo_remessa: string) => handleArquivoCSV(arquivoId, id_tipo_remessa)}
            ActionHeader={'Detalhes'}
            Actions={[
              {
                Button: (values: IDebitoResultTable) => {
                  return (
                    <IconButton
                      onClick={() => {
                        const obj = {
                          arquivoId: values,
                          convenio: getConvenioFilter(
                            formRef.getFieldValue('convenio'),
                          ),
                        };

                        window.open(
                          `/historico/remessa/detalhes?params=${JSON.stringify(
                            obj,
                          )}`,
                        );
                      }}
                    >
                      <FindInPageOutlined />
                    </IconButton>
                  );
                },
              },
            ]}

          />

       </InvisibleContent>


        </Grid>
      </Grid>

      {/* Debito Automatico */}
    </>
  );
};

export default HistoricoRemessa;

