import api from '@http/api';

export interface IItemsDashboardMes {
  mes: string;
  doadores_mes: number;
  doacoes_mes: number;
  valor_doacoes_mes: number;
  cadastros_mes: number;
  percentual_doadores: number;
  media_per_capta: number;
}

export interface IItemsMes { 
  descricao: string;
  janeiro: number;  
  fevereiro: number; 
  marco: number;
  abril: number;
  maio: number;
  junho: number;
  julho: number;
  agosto: number;
  setembro: number;
  outubro: number;
  novembro: number;
  dezembro: number; 
}

class PageDashboardServices {

   public async loadDashboardCadastrosNovos() {
    return await api
      .post('/api/Dashboard/FindDashboardCadastrosNovos')
      .then(response => {
        const total_cadastros = response.data.total_cadastros;
        const total_doacoes = response.data.total_doacoes;
        const valor_total_doacoes = response.data.valor_total_doacoes;
        const media_per_capta_anual = response.data.media_per_capta_anual;
        const comparacao_mes = response.data.comparacao_periodo;
        const origem_cadastro = response.data.origem_cadastro;
        const tipo_correspondencia = response.data.tipo_correspondencia;
        const forma_contribuicao = response.data.forma_contribuicao;

        var itensGraficoNovos: IItemsDashboardMes[] = [];
        var itensorigensCadastroNovos: IItemsMes[] = [];
        var itenstiposCorrespondenciaNovos: IItemsMes[] = [];
        var itensformasContribuicaoNovos: IItemsMes[] = [];

        comparacao_mes.map((item: IItemsDashboardMes) => {
          return itensGraficoNovos.push({
            mes: item.mes,
            doadores_mes: item.doadores_mes,
            doacoes_mes: item.doacoes_mes,
            valor_doacoes_mes: item.valor_doacoes_mes,
            cadastros_mes: item.cadastros_mes,
            percentual_doadores: item.percentual_doadores,
            media_per_capta: item.media_per_capta,
          });
        });

        origem_cadastro.map((item: IItemsMes) => {
          return itensorigensCadastroNovos.push({            
            descricao: item.descricao,
            janeiro: item.janeiro, 
            fevereiro: item.fevereiro,  
            marco: item.marco,
            abril: item.abril,
            maio: item.maio,
            junho: item.junho,
            julho: item.julho,
            agosto: item.agosto,
            setembro: item.setembro,
            outubro: item.outubro,
            novembro: item.novembro,
            dezembro: item.dezembro,
          });
        });

        
        tipo_correspondencia.map((item: IItemsMes) => {
          return itenstiposCorrespondenciaNovos.push({            
            descricao: item.descricao,
            janeiro: item.janeiro, 
            fevereiro: item.fevereiro,  
            marco: item.marco,
            abril: item.abril,
            maio: item.maio,
            junho: item.junho,
            julho: item.julho,
            agosto: item.agosto,
            setembro: item.setembro,
            outubro: item.outubro,
            novembro: item.novembro,
            dezembro: item.dezembro,
          });
        });

        forma_contribuicao.map((item: IItemsMes) => {
          return itensformasContribuicaoNovos.push({            
            descricao: item.descricao,
            janeiro: item.janeiro, 
            fevereiro: item.fevereiro,  
            marco: item.marco,
            abril: item.abril,
            maio: item.maio,
            junho: item.junho,
            julho: item.julho,
            agosto: item.agosto,
            setembro: item.setembro,
            outubro: item.outubro,
            novembro: item.novembro,
            dezembro: item.dezembro,
          });
        });

        return {
          error: false,
          response: { total_cadastros, 
                      total_doacoes, 
                      valor_total_doacoes, 
                      media_per_capta_anual, 
                      comparacao_mes: itensGraficoNovos,
                      origem_cadastro: itensorigensCadastroNovos,
                      tipo_correspondencia: itenstiposCorrespondenciaNovos,
                      forma_contribuicao: itensformasContribuicaoNovos
                    },
        };
      })
      .catch(error => {
        console.log('caiu aqui service dashboard', error);

        return {
          error: true,
          response: [],
        };
      });
  }


  public async loadDashboardGeral() {
    return await api
      .post('/api/Dashboard/FindDashboardGeral')
      .then(response => {
        const total_cadastros = response.data.total_cadastros;
        const total_doacoes = response.data.total_doacoes;
        const valor_total_doacoes = response.data.valor_total_doacoes;
        const media_per_capta_anual = response.data.media_per_capta_anual;
        const comparacao_mes = response.data.comparacao_periodo;
        const origem_cadastro = response.data.origem_cadastro;
        const tipo_correspondencia = response.data.tipo_correspondencia;
        const forma_contribuicao = response.data.forma_contribuicao;

        var itensGraficoGeral: IItemsDashboardMes[] = [];
        var itensOrigensCadastroGeral: IItemsMes[] = [];
        var itenstiposCorrespondenciaGeral: IItemsMes[] = [];
        var itensformasContribuicaoGeral: IItemsMes[] = [];

        comparacao_mes.map((item: IItemsDashboardMes) => {
          return itensGraficoGeral.push({
            mes: item.mes,
            doadores_mes: item.doadores_mes,
            doacoes_mes: item.doacoes_mes,
            valor_doacoes_mes: item.valor_doacoes_mes,
            cadastros_mes: item.cadastros_mes,
            percentual_doadores: item.percentual_doadores,
            media_per_capta: item.media_per_capta,
          });
        });

        origem_cadastro.map((item: IItemsMes) => {
          return itensOrigensCadastroGeral.push({            
            descricao: item.descricao,
            janeiro: item.janeiro, 
            fevereiro: item.fevereiro,  
            marco: item.marco,
            abril: item.abril,
            maio: item.maio,
            junho: item.junho,
            julho: item.julho,
            agosto: item.agosto,
            setembro: item.setembro,
            outubro: item.outubro,
            novembro: item.novembro,
            dezembro: item.dezembro,
          });
        });

        
        tipo_correspondencia.map((item: IItemsMes) => {
          return itenstiposCorrespondenciaGeral.push({            
            descricao: item.descricao,
            janeiro: item.janeiro, 
            fevereiro: item.fevereiro,  
            marco: item.marco,
            abril: item.abril,
            maio: item.maio,
            junho: item.junho,
            julho: item.julho,
            agosto: item.agosto,
            setembro: item.setembro,
            outubro: item.outubro,
            novembro: item.novembro,
            dezembro: item.dezembro,
          });
        });

        forma_contribuicao.map((item: IItemsMes) => {
          return itensformasContribuicaoGeral.push({            
            descricao: item.descricao,
            janeiro: item.janeiro, 
            fevereiro: item.fevereiro,  
            marco: item.marco,
            abril: item.abril,
            maio: item.maio,
            junho: item.junho,
            julho: item.julho,
            agosto: item.agosto,
            setembro: item.setembro,
            outubro: item.outubro,
            novembro: item.novembro,
            dezembro: item.dezembro,
          });
        });

        return {
          error: false,
          response: { total_cadastros, 
                      total_doacoes, 
                      valor_total_doacoes, 
                      media_per_capta_anual, 
                      comparacao_mes: itensGraficoGeral,
                      origem_cadastro: itensOrigensCadastroGeral,
                      tipo_correspondencia: itenstiposCorrespondenciaGeral,
                      forma_contribuicao: itensformasContribuicaoGeral
                    },
        };
      })
      .catch(error => {
        console.log('caiu aqui service dashboard', error);

        return {
          error: true,
          response: [],
        };
      });
  }
}

export default PageDashboardServices;


