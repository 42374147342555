import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Grid, IconButton } from '@material-ui/core';
import { TitlePage } from '@src/@styles/styles.global';
import TableActions from './TableCustomKeysNoPagination';
import Modal from '@components/Shared/Modal/ModalPure';

import Button from '@components/Shared/Buttons/Button';
import Services, { ITiposAcoes } from './services';

import { Edit, SaveOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import api from '@src/http/api';
import { Form, Upload, Input } from 'antd';

const columnsLabel = {
  tipo_acao_id: 'ID',
  tipo_acao_descricao: 'Tipo de Ação',
  tipo_acao_adm_config_status: 'Status',
  tipo_acao_config_liberar_cliente: 'Estado',
};

const ConfigEstrategias: React.FC = () => {
  const { idEstrategia }: { idEstrategia: string } = useParams();
  const [estrategiaNome, setEstrategiaNome] = useState('');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ITiposAcoes[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [tipoAcaoId, setTipoAcaoId] = useState<number>(0);
  const [tipoAcaoDescricao, setTipoAcaoDescricao] = useState('');
  const [loadingButton, setLoadingButton] = useState(false);

  const firstLoad = useCallback(async () => {
    const services = new Services();

    const { error, response } = await services.getTiposAcoes(
      parseInt(idEstrategia),
    );
    if (!error && response) {
      setData(response);
      setLoading(false);
    }
  }, []);

  const loadEstrategia = useCallback(async () => {
    const services = new Services();

    const { error, response } = await services.getEstrategia(
      parseInt(idEstrategia),
    );
    if (!error && response) {
      setEstrategiaNome(response.estrategia.nome);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.document.title = `CRM - Estratégia`;
    firstLoad();
    loadEstrategia();
  }, []);

  const onFinish = async (values: any) => {
    setLoadingButton(true);
    let extensionFileOne = '';
    let extensionFileTwo = '';

    if (values.upload !== undefined) {
      extensionFileOne = values.upload[0].name.slice(
        ((values.upload[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      );
    }
    if (values.upload2 !== undefined) {
      extensionFileTwo = values.upload2[0].name.slice(
        ((values.upload2[0].name.lastIndexOf('.') - 1) >>> 0) + 2,
      );
    }

    if (
      (extensionFileOne !== '' &&
        extensionFileOne.toLocaleLowerCase() !== 'html') ||
      (extensionFileTwo !== '' && extensionFileTwo.toLowerCase() !== 'html')
    ) {
      enqueueSnackbar('Erro ao adicionar Arquivo!', {
        title: 'Erro ao adicionar Arquivo!',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoadingButton(false);
      return;
    }

    let formData = new FormData();
    formData.append('EstrategiaId', idEstrategia);
    formData.append('TipoAcaoId', tipoAcaoId.toString());
    formData.append(
      'ArquivoImpressao',
      values.upload !== undefined ? values.upload[0].originFileObj : null,
    );
    formData.append(
      'ArquivoEmail',
      values.upload2 !== undefined ? values.upload2[0].originFileObj : null,
    );
    formData.append(
      'NomeRemetente',
      values.NomeRemetente ? values.NomeRemetente : null,
    );
    formData.append(
      'EmailRemetente',
      values.EmailRemetente ? values.EmailRemetente : null,
    );
    formData.append('Assunto', values.assunto ? values.assunto : null);

    api
      .put('/api/EstrategiaAcao/AddArquivosEstrategiasAcoes', formData)
      .then(response => {
        enqueueSnackbar('Arquivo adicionado com sucesso!', {
          title: 'Arquivo adicionado com sucesso!',
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoadingButton(false);
        setOpenModal(false);
        firstLoad();
      })
      .catch(error => {
        enqueueSnackbar('Erro ao adicionar Arquivo!', {
          title: 'Erro ao adicionar Arquivo!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoadingButton(false);
        console.log(error);
      });
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const changeLiberar = async (tipo_acao_id: number, liberar: boolean) => {
    api
      .put('/api/TipoAcao/LiberarTipoAcao', {
        tipo_acao_id,
        estrategia_id: idEstrategia,
        tipo_acao_config_liberar_cliente: liberar,
      })
      .then(response => {
        firstLoad();
      })
      .catch(error => {
        console.log(error);
      });
  };

  const changeCreateListaLigacao = async (id_estrategia: number) => {
    const services = new Services();
    const { error } = await services.createListaLigacao(id_estrategia);
  };

  return (
    <>
      <Modal
        title=""
        size="medio"
        openModal={openModal}
        controlModal={(value: boolean) => setOpenModal(value)}
      >
        <Grid container>
          <Grid item xs={12} lg={12} style={{ padding: 10 }}>
            <TitlePage style={{ margin: 0 }}>
              Estratégia {idEstrategia} / {tipoAcaoDescricao}
            </TitlePage>
          </Grid>
          <Grid item xs={12} lg={12}>
            <Form onFinish={onFinish} layout="vertical">
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Form.Item
                    name="NomeRemetente"
                    label="Nome de remetente: "
                    rules={[
                      {
                        required: false,
                        message: 'Digite um Nome de remetente',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Form.Item
                    name="assunto"
                    label="Assunto "
                    rules={[
                      {
                        required: false,
                        message: 'Digite um Assunto',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Form.Item
                    name="EmailRemetente"
                    label="Email de Remetente "
                    rules={[
                      {
                        required: false,
                        message: 'Digite um Email de Remetente',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Grid>

                <Grid item xs={12} lg={12} style={{ padding: 10 }}>
                  <Form.Item
                    name="upload"
                    label="Modelo de carta para o boleto: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra=""
                    rules={[
                      {
                        required: false,
                        message: 'Selecione 1 modelo',
                      },
                    ]}
                  >
                    <Upload
                      maxCount={1}
                      beforeUpload={() => false}
                      listType="text"
                    >
                      <Button color="secondary">Anexar</Button>
                    </Upload>
                  </Form.Item>
                </Grid>
                <Grid item xs={12} lg={12} style={{ padding: 10 }}>
                  <Form.Item
                    name="upload2"
                    label="Modelo de envio por e-mail: "
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    extra=""
                    rules={[
                      {
                        required: false,
                        message: 'Selecione 1 modelo',
                      },
                    ]}
                  >
                    <Upload
                      maxCount={1}
                      beforeUpload={() => false}
                      listType="text"
                    >
                      <Button color="secondary">Anexar</Button>
                    </Upload>
                  </Form.Item>
                </Grid>
                <Grid item xs={12} lg={12} style={{ padding: 10 }}>
                  <Form.Item>
                    <Button
                      color="secondary"
                      type="submit"
                      fullWidth
                      loading={loadingButton}
                      disabled={loadingButton}
                    >
                      <SaveOutlined style={{ marginRight: 10 }} /> Salvar
                    </Button>
                  </Form.Item>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Modal>

      <Grid container>
        <Grid item sm={4} xs={12} lg={4}>
          <Grid
            item
            sm={12}
            xs={12}
            lg={12}
            style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
          >
            <TitlePage style={{ margin: 0 }}>{estrategiaNome}</TitlePage>
          </Grid>
          <Grid
            item
            sm={12}
            xs={12}
            lg={12}
            justify="center"
            style={{
              alignSelf: 'center',
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            <span style={{ fontSize: 16 }}>ID: {idEstrategia}</span>
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} lg={6} sm={6} style={{ padding: 5 }}>
          <TableActions
            onChangeLiberar={(
              row: any,
              liberar: boolean,
              tipo_acao_id: number,
            ) => {
              if (
                row.tipo_acao_descricao.toLowerCase().includes('ligação') &&
                !row.tipo_acao_config_liberar_cliente
              ) {
                changeCreateListaLigacao(Number(idEstrategia));
              }

              changeLiberar(tipo_acao_id, liberar);
            }}
            loading={loading}
            keys={[
              'tipo_acao_id',
              'tipo_acao_descricao',
              'tipo_acao_adm_config_status',
              'tipo_acao_config_liberar_cliente',
            ]}
            columns={[columnsLabel]}
            data={data}
            Actions={[
              {
                Button: (row: ITiposAcoes) => {
                  return (
                    <IconButton
                      onClick={() => {
                        setTipoAcaoId(row.tipo_acao_id);
                        setTipoAcaoDescricao(row.tipo_acao_descricao);
                        setOpenModal(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigEstrategias;
