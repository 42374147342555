import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '@http/api';

interface IEntidade {
  id: number;
  descricao: string;
}

interface EntidadeState {
  entidade: IEntidade;
}

interface EntidadeContextData {
  entidade: IEntidade;
  updateEntidade(entidade: IEntidade): void;
}

const EntidadeContext = createContext<EntidadeContextData>(
  {} as EntidadeContextData,
);

export const EntidadeProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<EntidadeState>(() => {
    const entidade = localStorage.getItem('@amex:entidade');

    if (entidade) {
      const entidadeJSON = JSON.parse(entidade);

      api.defaults.headers.entidade = entidadeJSON.id;
      return { entidade: entidadeJSON };
    }

    return {} as EntidadeState;
  });

  const updateEntidade = useCallback(
    (entidade: IEntidade) => {
      setData({
        entidade,
      });

      api.defaults.headers.entidade = entidade.id;
      localStorage.setItem('@amex:entidade', JSON.stringify(entidade));
    },
    [setData],
  );

  return (
    <EntidadeContext.Provider
      value={{ entidade: data.entidade, updateEntidade }}
    >
      {children}
    </EntidadeContext.Provider>
  );
};

export function useEntidade(): EntidadeContextData {
  const context = useContext(EntidadeContext);

  if (!context) {
    throw new Error('useEntidade must be used within an EntidadeProvider');
  }
  return context;
}
