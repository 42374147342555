export const ObjetoFiltrosVinculo = (filtros: any) => {
  let inCampanhas: number[] = [];
  let notInCampanhas: number[] = [];
  let inEstrategias: number[] = [];
  let notInEstrategias: number[] = [];

  if (filtros.length <= 0) {
    return { inCampanhas, notInCampanhas, inEstrategias, notInEstrategias };
  }

  filtros.map((filtro: any) => {
    if (filtro.campanhas) {
      if (filtro.participou === true) {
        inCampanhas = inCampanhas.concat(filtro.campanhas.id);
      } else {
        notInCampanhas = notInCampanhas.concat(filtro.campanhas.id);
      }
    }

    if (filtro.estrategias) {
      if (filtro.participou === true) {
        inEstrategias = inEstrategias.concat(filtro.estrategias.id);
      } else {
        notInEstrategias = notInEstrategias.concat(filtro.estrategias.id);
      }
    }
  });

  return { inCampanhas, notInCampanhas, inEstrategias, notInEstrategias };
};

export const CidadesIn = (cidades: any) => {
  if (cidades.length <= 0) {
    return [];
  }
  let cidadesAll: any[] = [];
  cidades.map((cidadesIn: any) => {
    const { cidades } = cidadesIn;
    cidades.map((cidade: any) => {
      const { id } = JSON.parse(cidade);
      cidadesAll = cidadesAll.concat(id);
    });
  });

  const finalarray = new Set(cidadesAll);
  let array: any = Array.from(finalarray);
  return array;
};
