import api from '@http/api';

export interface ITipoUsuario {
  id: number;
  descricao: string;
}

interface IResponse {
  error: boolean;
  response?: ITipoUsuario[];
}

interface IResponseUsuarioNew {
  error: boolean;
  response?: IUsuarioNew[];
}

interface IResponseUsuarioOld {
  error: boolean;
  messageError?: any;
  response?: IUsuarioOld;
}

interface IUsuarioNew {
  usuario: {
    nome: string;
    email: string;
    senha: string;
    confirmacao_senha: string;
    status_ativo: number;
    entidade_id?: number;
    tipo_usuario_id: number;
  };
}

interface IUsuarioOld {
  id?: number;
  nome?: string;
  email?: string;
  nova_senha?: string;
  confirmacao_nova_senha?: string;
  status_ativo?: number;
  entidade_id?: number;
  tipo_usuario_id?: number;
  role?: string;
}

class PageUsersServices {
  public async GetTiposUsuarios(): Promise<IResponse> {
    return await api
      .get<ITipoUsuario[]>('/api/Home/tipo_usuario')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async GetUserByID({ userID }: any): Promise<IResponseUsuarioOld> {
    return await api
      .get<IUsuarioOld>(`/api/Home/usuario_by_id?id=${userID}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async PostUsuarios({
    usuario,
  }: IUsuarioNew): Promise<IResponseUsuarioNew> {
    return api
      .post('/api/Home/create_usuario', usuario)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async UpdateUserPassword({
    id,
    nova_senha,
    confirmacao_nova_senha,
  }: IUsuarioOld): Promise<IResponseUsuarioOld> {
    return api
      .put('/api/Home/change_user_password', {
        user_id: id,
        nova_senha: nova_senha,
        confirmacao_nova_senha: confirmacao_nova_senha,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        console.log(error);
        return {
          error: true,
          messageError: {
            data: error.data,
          },
        };
      });
  }

  public async UpdateUserNomeEmail({
    id,
    nome,
    email,
  }: IUsuarioOld): Promise<IResponseUsuarioOld> {
    return api
      .put('/api/Home/change_nome_email_user', {
        user_id: id,
        nome: nome,
        email: email,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async UpdateUserRole({
    id,
    tipo_usuario_id,
  }: IUsuarioOld): Promise<IResponseUsuarioOld> {
    return api
      .put('/api/Home/adm_update_user', {
        user_id: id,
        status_ativo: 1,
        tipo_usuario_id: tipo_usuario_id,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default PageUsersServices;
