import React, { useEffect, useState } from 'react';

import * as S from './styles';
import { useAuth } from '@hooks/AuthenticateContext';
import { Beforeunload } from 'react-beforeunload';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { UrlServerAPI } from '@src/Configs/app';
import ServiceFooterTelefonia from '@hooks/FooterTelefonia/services';

const WebPhoneReceptivo: React.FC = () => {
  const { user } = useAuth();
  const [connection, SetConnection] = useState<any>();
  const [urlIframe, setUrlIframe] = useState('');

  const createUrl = async () => {
    const serviceFooterTelefonia = new ServiceFooterTelefonia();

    const { error, response } = await serviceFooterTelefonia.createWebPhone('');

    if (!error && response) {
      setUrlIframe(response.url);
    }
  };

  useEffect(() => {
    createUrl();

    const newConnection = new HubConnectionBuilder()
      .withUrl(UrlServerAPI + '/socket')
      .withAutomaticReconnect()
      .build();
    SetConnection(newConnection);
  }, []);

  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then((result: any) => {
          console.log('Connected!');
        })
        .catch((e: any) => console.log('Connection failed: ', e));
    }
  }, [connection]);

  return (
    <Beforeunload
      onBeforeunload={event => {
        event.preventDefault();
        alert('Você saiu do WebPhone');
        connection.send('DisconnectWebPhoneReceptivo', user.id.toString());
      }}
    >
      <S.Container>
        <S.Title>WebPhone Receptivo</S.Title>

        <S.Container>
          {urlIframe !== '' && (
            <iframe
              title="webphone"
              allow="microphone; autoplay"
              height="575"
              width="344"
              src={urlIframe}
            ></iframe>
          )}
        </S.Container>
      </S.Container>
    </Beforeunload>
  );
};

export default WebPhoneReceptivo;
