import React from 'react';

import DoacoesShared from '@pages/Private/shared/Doacoes';
import ListPessoasShared from '@pages/Private/shared/ListPessoas';
import EditCadastro from '@pages/Private/shared/EditCadastro';
import Indicadores from '@pages/Private/shared/ListIndicadores';

import ListCampanha from '@pages/Private/Amex/shared/ListCampanhas';
import DetalhesCampanha from '@pages/Private/Amex/shared/DetalhesCampanha';
import ConfigEstrategia from '@pages/Private/Amex/shared/ConfigEstrategias';

import { FavoriteBorderOutlined } from '@material-ui/icons';
import { BookMarkIcon, ContactPage } from '@src/Assets/Icons';
import DadosGeraisCadastros from '@src/Pages/Private/shared/Consultas';

export interface IRoute {
  index: number | null;
  path: string;
  component: React.ComponentType<any>;
  roles: string[];
}
export interface IRouteMenu {
  index: number;
  path?: string;
  Icon?: React.ReactNode;
  label: string;
  roles: string[];
  menu: 'lateral' | 'header';
  subMenu?: IRouteMenu[];
}

export const routesOperacional: IRoute[] = [
  {
    index: 1,
    path: '/doacoes',
    component: DoacoesShared,
    roles: ['OPERACIONAL AMEX'],
  },
  {
    index: 2,
    path: '/cadastros/consulta',
    component: ListPessoasShared,
    roles: ['OPERACIONAL AMEX'],
  },
  {
    path: '/cadastro/:idCadastro',
    component: EditCadastro,
    roles: ['OPERACIONAL AMEX'],
    index: null,
  },
  {
    path: '/cadastros/dadosGerais',
    component: DadosGeraisCadastros,
    roles: ['OPERACIONAL AMEX'],
    index: null,
  },
  {
    path: '/cadastros/indicadores',
    component: Indicadores,
    roles: ['OPERACIONAL AMEX'],
    index: null,
  },

  {
    index: null,
    path: '/campanha',
    component: ListCampanha,
    roles: ['OPERACIONAL AMEX'],
  },
  {
    index: null,
    path: '/campanha/:idCampanha',
    component: DetalhesCampanha,
    roles: ['OPERACIONAL AMEX'],
  },
  {
    index: null,
    path: '/estrategia/:idEstrategia',
    component: ConfigEstrategia,
    roles: ['OPERACIONAL AMEX'],
  },
];

export const routesOperacionalMenu: IRouteMenu[] = [
  {
    index: 1,
    path: '/doacoes',
    label: 'Doações',
    Icon: <FavoriteBorderOutlined />,
    roles: ['OPERACIONAL AMEX'],
    menu: 'header',
  },
  {
    index: 3,
    label: 'Cadastros',
    path: '/cadastros',
    Icon: ContactPage,
    roles: ['OPERACIONAL AMEX'],
    menu: 'lateral',
    subMenu: [
      {
        index: 3.1,
        path: '/cadastros/consulta',
        label: 'Consulta',
        roles: ['OPERACIONAL AMEX'],
        menu: 'lateral',
      },
      {
        index: 3.2,
        path: '/cadastros/dadosGerais',
        label: 'Dados Gerais',
        roles: ['OPERACIONAL AMEX'],
        menu: 'lateral',
      },
      {
        index: 3.3,
        path: '/cadastros/indicadores',
        label: 'Indicadores',
        roles: ['OPERACIONAL AMEX'],
        menu: 'lateral',
      },
    ],
  },
  {
    index: 2,
    label: 'Campanha',
    path: '/campanha',
    Icon: BookMarkIcon,
    roles: ['OPERACIONAL AMEX'],
    menu: 'lateral',
  },
];
