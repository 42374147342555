import React, { useEffect, useState } from 'react';

import { addDays } from 'date-fns';
import { Checkbox, Grid, IconButton } from '@material-ui/core';
import { FindInPageOutlined, SearchOutlined } from '@material-ui/icons';
import { Select as SelectAntd, Form, Input } from 'antd';
import { Spin, Space } from 'antd';

import DatePicker2 from '@components/Shared/DatePicker2';
import TableCustomKeysNoPagination from '@components/Shared/Tables/TableCustomKeysNoPagination';
import { TitlePage } from '@styles/styles.global';
import ButtonComponent from '@components/Shared/Buttons/Button';
import InvisibleContent from '@components/Shared/InvisibleContent';
import Modal from '@components/Shared/Modal/ModalPure';
import { useSnackbar } from 'notistack';
import Button from '@components/Shared/Buttons/Button';



import Services, {
  IFormaContribuicao,
  IConvenio,
  IDebitoResultTable,
  ITipoRemessa,
} from './services';

import * as S from './styles';
import { clear } from 'console';

import { useAuth } from '@hooks/AuthenticateContext';

// BANCO = CONVENIO
// TIPO_REMESSA = COD MOVIMENTO


interface IDebitoRemessa {
  id: number;
  descricao: string;
}


const DEBITO_NORMAL_DATA = addDays(new Date(), 0);
const DEBITO_NORMAL_DATA_Ok = addDays(new Date(), 0);

const DESCRICAO_BOLETO = 'boleto';
const DESCRICAO_DEBITO = 'débito automático';

const Financeiro: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();
  const [formasContribuicao, setFormasContribuicao] = useState<
    IFormaContribuicao[]
  >([]);
  const [convenios, setConvenios] = useState<IConvenio[]>([]);
  const [debitos, setDebitos] = useState<IDebitoResultTable[]>([]);
  const [loading, setLoading] = useState(false);

  const [tipoRemessa, setTipoRemessa] = useState<ITipoRemessa[]>([]);

  const [datasSelecionadas, setDatasSelecionadas] = useState<string[]>([]);

  const [formaContribuicaoSelected, setFormaContruibuicaoSelected] =
    useState<number>(0);

  const [stringTipoRemessa, setStringTipoRemessa] = useState<string>('');

  const [rangeData, setRangeData] = useState<Date[]>([
    DEBITO_NORMAL_DATA,
    DEBITO_NORMAL_DATA,
  ]);

  const [rangeData2, setRangeData2] = useState<Date[]>([
    DEBITO_NORMAL_DATA_Ok,
    DEBITO_NORMAL_DATA_Ok,
  ]);

  const [modalDebitoDetalhes, setModalDebitoDetalhes] =
    useState<boolean>(false);

    const [debitoRemessa, setDebitoRemessa] = useState<IDebitoRemessa[]>([
      { id: 1, descricao: 'MENSAL' },
      { id: 2, descricao: 'SOLICITAÇÃO' },
    ]);

    const [boletoRemessa, setBoletoRemessa] = useState<IDebitoRemessa[]>([
      { id: 1, descricao: 'BOLETO' },
    ]);

  const loadTipoRemessa = async (
    formaContribuicaoID: string | number,
  ): Promise<number> => {
    const service = new Services();
    const { error, response } = await service.GetTipoRemessa(
      formaContribuicaoID,
    );
    if (!error && response) {
      setTipoRemessa(response);
      if (response.length > 0) {
        formRef.setFieldsValue({
          codigoMovimento: response[0]?.id,
        });

        setStringTipoRemessa(response[0]?.descricao);
        return response[0].id;
      } else {
        setStringTipoRemessa('');

        formRef.setFieldsValue({
          codigoMovimento: response[0]?.id,
        });
        return 0;
      }
    } else {
      return 0;
    }
  };

  const loadFormasContribuicao = async () => {
    const services = new Services();
    const { error, response } = await services.loadFormaContribuicao();

    if (!error && response) {
      setFormasContribuicao(response);
      // const foundFormaContribuicao = response.find(
      //   x => x.id === formaContribuicaoSelected,
      // );

      // if (!foundFormaContribuicao) {
      //   return 0;
      // }

      // if (foundFormaContribuicao.descricao.toLowerCase() === DESCRICAO_DEBITO) {
      //   setRangeData([DEBITO_NORMAL_DATA, DEBITO_NORMAL_DATA]);
      // }

      setFormaContruibuicaoSelected(Number(response[0].id));
      setRangeData([DEBITO_NORMAL_DATA, DEBITO_NORMAL_DATA]);
      setRangeData2([DEBITO_NORMAL_DATA_Ok, DEBITO_NORMAL_DATA_Ok]);

      return Number(response[0].id);
    } else {
      return 0;
    }
  };

  const loadConvenios = async (
    idFormaContribuicao: number | string,
  ): Promise<number> => {
    const services = new Services();
    const { error, response } = await services.GetConvenios(
      idFormaContribuicao,
    );

    if (!error && response) {
      setConvenios(response);
      formRef.setFieldsValue({ convenio: response[0]?.id });

      //console.log('TESTE', response);

      return response[0]?.id;
    } else {
      //console.log('ENTROU AQUI');

      formRef.setFieldsValue({ convenio: null });
      return 0;
    }
  };

  const loadDebitos = async (
    idConvenvio?: number,
    codigoMovimento?: number,
    Dadostipo?: string,
    rangeDatas?: Date[],
  ) => {
    setLoading(true);

    const services = new Services();

    const { error, response } = await services.GetListDebitosAgrupados(
      1,
      1,
      idConvenvio ? idConvenvio : formRef.getFieldValue('convenio'),
      codigoMovimento
        ? codigoMovimento
        : formRef.getFieldValue('codigoMovimento'),
        Dadostipo ? Dadostipo : formRef.getFieldValue('tipoDados'),

      rangeDatas
        ? `${rangeDatas[0].toLocaleDateString('en-US')}`
        : `${rangeData[0].toLocaleDateString('en-US')}`,
      rangeDatas
        ? `${rangeDatas[1].toLocaleDateString('en-US')}`
        : `${rangeData[1].toLocaleDateString('en-US')}`,
    );

    //padrao de data eua

    if (!error) {
      setDebitos(response?.modelo || []);
    }

    setLoading(false);
    console.clear();
  };

  const gerarRemessa = async () => {
    setLoading(true);

    const services = new Services();

    const convenioID = formRef.getFieldValue('convenio');
    const codigoMovimento = formRef.getFieldValue('codigoMovimento');
    const tipo_debito = formRef.getFieldValue('tipoDados');
    const ambiente = 'PRODUCAO';


    let arrayDatas: string[] = [];

    datasSelecionadas.forEach(data => {
      const dataSplit = data.split('/');

      const date = new Date(`${dataSplit[1]}/${dataSplit[0]}/${dataSplit[2]}`);
      arrayDatas.push(date.toISOString());
    });

    if (checkFormaContribuicao() === DESCRICAO_DEBITO) {
      const { error, response } = await services.GerarRemessaDebito(
        convenioID,
        codigoMovimento,
        arrayDatas,
        tipo_debito,
        ambiente,
      );

      if (!error && response) {
        setDatasSelecionadas([]);
        loadDebitos(convenioID, codigoMovimento, tipo_debito);
        window.open(response);
        enqueueSnackbar(`Gerado com sucesso!`, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoading(false);
      }
    } else {
      const { error, response } = await services.GerarRemessaBoleto(
        convenioID,
        codigoMovimento,
        arrayDatas,
        tipo_debito,
        ambiente,

      );

      if (!error && response) {
        setDatasSelecionadas([]);
        loadDebitos(convenioID, codigoMovimento, tipo_debito);
        window.open(response);
        enqueueSnackbar(`Gerado com sucesso!`, {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoading(false);
      }
     // setLoading(false);

    }
    //window.location.reload();
  };

  const selectedDatas = (value: IDebitoResultTable) => {
    if (datasSelecionadas.filter(x => x === value.data).length <= 0) {
      setDatasSelecionadas([...datasSelecionadas, value.data]);
    } else {
      const newArray = datasSelecionadas.filter(function (item) {
        return item !== value.data;
      });

      setDatasSelecionadas(newArray);
    }
  };

  const checkFormaContribuicao = () => {
    const teste = formasContribuicao.find(
      x => x.id === formaContribuicaoSelected,
    );

    if (!teste) {
      return '';
    }
    return teste?.descricao.toLowerCase();

  };

  const firstLoadDebitos = async () => {
    const formaContribuicaoID = await loadFormasContribuicao();
    const convenioID = await loadConvenios(formaContribuicaoID);
    const codigoMovimento = await loadTipoRemessa(formaContribuicaoID);


    await loadDebitos(convenioID, codigoMovimento);

  };

  const handleChangeDates = (dates: Date[]) => {
    setRangeData(dates);
    setRangeData2(dates);
    // setDatasSelecionadas
    //loadDebitos(undefined, undefined, undefined, dates);

  };

  useEffect(() => {
    setLoading(true);
    firstLoadDebitos();

    setLoading(false);

   // setRDebitoRemessa();
  }, []);

  const getConvenioFilter = (id: number | string) => {
    const convenioFiltered = convenios.filter(x => x.id === id);

    if (convenioFiltered.length > 0) {
      return convenioFiltered[0];
    } else {
      return id;
    }
  };

  const getCodigoMovimento = (id: number | string) => {
    const codigoMovimentoFiltered: ITipoRemessa[] = tipoRemessa.filter(
      x => x.id === id,
    );

    if (codigoMovimentoFiltered.length > 0) {
      setStringTipoRemessa(codigoMovimentoFiltered[0].descricao);

      return codigoMovimentoFiltered[0];
    } else {
      return id;
    }
  };

  const changeFormaContribuicao = async (idFormaContribuicao: number) => {
    const convenioID = await loadConvenios(idFormaContribuicao);
    const codigoMovimento = await loadTipoRemessa(idFormaContribuicao);

    await loadDebitos(convenioID, codigoMovimento);
  };

  useEffect(() => {
    setLoading(true);
    changeFormaContribuicao(formaContribuicaoSelected);
    setLoading(false);
    window.document.title = 'CRM - Gerar Remessa';
  }, [formaContribuicaoSelected]);


  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };
  
        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };
  
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
  
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);

  return (
    <S.Container>
      <TitlePage> Gerar Remessa </TitlePage>

      {/* Boleto ou Debito */}
      <Grid style={{}}>
        <Spin size="large" spinning={loading} tip={'Loading...'}>
          {formasContribuicao.length > 0 &&
            formasContribuicao.map(forma => {
              if (
                forma.descricao.toLowerCase().includes(DESCRICAO_DEBITO) ||
                forma.descricao.toLowerCase().includes(DESCRICAO_BOLETO)
              ) {
                return (
                  <ButtonComponent
                    key={forma.id}
                    style={{ margin: 5 }}
                    color={
                      formaContribuicaoSelected != forma.id
                        ? 'default'
                        : 'secondary'
                    }
                    disabledColor="#ccc"
                    onClick={() => {
                      setFormaContruibuicaoSelected(Number(forma.id));
                      loadTipoRemessa(Number(forma.id));
                    }}
                  >
                    {forma.descricao.toLowerCase()}
                  </ButtonComponent>
                );
              }
            })}
        </Spin>
      </Grid>
      {/* Boleto ou Debito */}

      {/* Debito Automatico */}

      <Grid
        item
        md={8}
        lg={12}
        xs={12}
        // style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 10 }}
      >
        <Form className="teste2 " layout={'horizontal'} form={formRef}>
          <Grid container justifyContent="flex-start" alignItems="center">
            <Grid item lg={3} style={{ padding: 5 }}>
              <DatePicker2
                cleanable={false}
                notUseDefaultValue={false}
                title="Período: "
                color={'secondary'}
                setValor={handleChangeDates}
                placement={'bottomStart'}
                //disableDateBefore={
                  //stringTipoRemessa.toLowerCase() == 'débito normal'
                   // ? DEBITO_NORMAL_DATA
                   //: undefined
                //}
                notViewRanges={true}
                rangeInitial={rangeData}
                //rangeInitial={ stringTipoRemessa.toLowerCase() == 'débito normal'
                //? rangeData
                //: rangeData2}
              />
            </Grid>

            <Grid
              item
              lg={2}
              style={{
                padding: 5,
              }}
            >
              <Form.Item name="convenio">
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Banco"
                  onChange={(e: string) => {
                    //loadDebitos();
                  }}
                >
                  {convenios.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao + ' - ' + option.numero_convenio}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <Grid
              item
              lg={2}
              style={{
                padding: 5,
              }}
            >
              <Form.Item name={'codigoMovimento'}>
                <SelectAntd
                  style={{ width: '100%' }}
                  placeholder="Tipo Remessa"
                  onChange={(e: string) => {
                    getCodigoMovimento(e);
                   // loadDebitos();
                  }}
                >
                  {tipoRemessa.map((option, index) => (
                    <SelectAntd.Option key={option.id} value={option.id}>
                      {option.descricao}
                    </SelectAntd.Option>
                  ))}
                </SelectAntd>
              </Form.Item>
            </Grid>

            <InvisibleContent
              visible={checkFormaContribuicao() === DESCRICAO_DEBITO }
            >

              <Grid item lg={2} style={{padding: 5,}}>
                <Form.Item name={'tipoDados'}>
                  <SelectAntd
                    style={{ width: '100%' }}
                    placeholder="Tipo débito"
                    onChange={(e: number) => {
                      //loadDebitos();
                    }}
                  >

                    {debitoRemessa.map((option, index) => (
                      <SelectAntd.Option key={option.descricao} value={option.descricao}>
                        {option.descricao}
                      </SelectAntd.Option>
                    ))}
                  </SelectAntd>
                </Form.Item>
              </Grid>
            </InvisibleContent>


            <InvisibleContent
              visible={datasSelecionadas.length <= 0}
            >

              <Grid item lg={2} style={{ padding: 5 }}>
                <ButtonComponent
                   color={'secondary'}
                  onClick={() => {
                    loadDebitos();
                  }}
                >
                  <SearchOutlined style={{ marginRight: 10 }} /> Buscar
                </ButtonComponent>
              </Grid>
            </InvisibleContent>

            <InvisibleContent
              visible={datasSelecionadas.length > 0}
            >
              <Grid item lg={2} style={{ padding: 5 }}>

                  <ButtonComponent
                    color={'secondary'}
                    onClick={gerarRemessa}
                    loading={loading}
                    disabled={
                      datasSelecionadas.length <= 0 ||
                      formRef.getFieldValue('convenio') == null ||
                      formRef.getFieldValue('codigoMovimento') == null ||
                      //formRef.getFieldValue('tipoDados') == null
                      loading
                    }
                  >
                    Gerar
                  </ButtonComponent>
              </Grid>
            </InvisibleContent>
          </Grid>
        </Form>
      </Grid>

      <Grid container>
        <Grid item lg={8}>
          <TableCustomKeysNoPagination
            Selected={(e: IDebitoResultTable) => (
              <Grid container justifyContent="center">
                <Checkbox
                  onChange={() => selectedDatas(e)}
                  checked={
                    datasSelecionadas.filter(x => x === e.data).length > 0
                  }
                />
              </Grid>
            )}
            data={debitos}
            keys={['data', 'total_pessoas', 'valor_total']}
            loading={loading}
            columns={[
              {
                data: 'Data de geração',
                total_pessoas: 'Número de optantes',
                valor_total: 'Total em R$',
              },
            ]}
            Actions={[]}
          />
        </Grid>
      </Grid>

      {/* Debito Automatico */}
    </S.Container>
  );
};

export default Financeiro;
