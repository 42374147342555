import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import { Checkbox, Grid, IconButton } from '@material-ui/core';


import { Switch } from 'antd';

import { Empty } from 'antd';
import '@styles/AntStyles.css';

import {
  DivLoading,
  SpinnerLoadingMaterial,
  StyledTableCell,
  StyledTableRow,
  useStyles,
  Container,
  DivVoid,
} from './styles';

interface ITableProps {
  data: object[];
  columns: Object[];
  loading: Boolean;
  Actions?: IActionObj[];
  Selected?: Function;
  FieldsBold?: string[];
  //onChangeLiberar: Function;
  keys: string[];
}

interface IActionObj {
  Button: Function;
}

const TableActions: React.FC<ITableProps> = ({
  data,
  columns,
  loading,
  Actions,
  Selected,
  FieldsBold,
  //onChangeLiberar,
  keys,
}) => {
  const classes = useStyles();
  // const keys = Object.keys(data[0] || data);
  const alterColumns = columns[0] || {};

  const Head = ({ keys, alterColumns }: any) => {
    return (
      <>
        {Selected && (
          <StyledTableCell align="center">
            Selecionar
        </StyledTableCell>
        )}

        {keys.map((key: any) => (
          <StyledTableCell key={key} align="center">
            {alterColumns[key] || key}
          </StyledTableCell>
        ))}

        {Actions && <StyledTableCell align="center">Excluir</StyledTableCell>}
      </>
    );
  };

  const Rows = ({ row }: any) => {
    const colunas = Object.keys(row);

    return (
      <StyledTableRow>
        {Selected && (
          <StyledTableCell>
            <div>{Selected(row)}</div>
          </StyledTableCell>
        )}

        {colunas.map(coluna => {
          if (!keys.includes(coluna)) {
            return;
          }

          return (
            <StyledTableCell key={coluna} align="center">
              {FieldsBold?.includes(coluna) ? (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <strong
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </strong>
                </div>
              ) : (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </p>
                </div>
              )}

              {coluna === 'mala_mensal' && (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna] == false ? 'Não' : 'Sim'}
                  </p>
                </div>
              )}
            </StyledTableCell>
          );
        })}

        {Actions && (
          <StyledTableCell
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {Actions?.map((Item, index) => (
              <div key={index}>{Item.Button(row)}</div>
            ))}
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <Container>
      {loading && (
        <DivLoading>
          <SpinnerLoadingMaterial />
        </DivLoading>
      )}

      {!loading && data.length <= 0 && (
        <DivVoid>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum dado encontrado"
          />
        </DivVoid>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader size="medium">
          {!loading && (
            <TableHead>
              <TableRow>
                <Head keys={keys} alterColumns={alterColumns} />
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, index: number) => {
              return <Rows key={index} row={row} />;
            })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableActions;
