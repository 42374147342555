import React, { useEffect } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import Modal from '@src/components/Shared/Modal/ButtonIcon';
import {
  // CheckBox,
  PlaceOutlined,
  AccountBalanceOutlined,
  ChromeReaderModeOutlined,
  MailOutline,
  CategoryOutlined,
  FavoriteBorder,
  Visibility,
  StarsOutlined,
} from '@material-ui/icons';

import { mdiBarcodeScan, mdiTextBoxCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';

import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import { Article, Call } from '@assets/Icons';

import Tag from '@components/Shared/Tag';
import InputButton from '@components/Shared/Inputs/InputButton';

import TableDoacao from '@components/Shared/Perfil/TabelaDoacao';
import TableSolicitacao from '@components/Shared/Perfil/TabelaSolicitacao';
import InvisibleContent from '@components/Shared/InvisibleContent';
import TableNoPagination from '@components/Shared/Tables/TableCustomKeysNoPagination';
import { MaskCPFeCNPJ } from '@utils/Masks';
// import { Container } from './styles';

import api from '@http/api';
import TabelaBoletos from '../Perfil/TabelaBoletos';



interface IProps {
  idPerfil: number;
  nomePerfil: string;
  isIndicador?: boolean;
  tiposPerfil: string[];

}

const Historico: React.FC<IProps> = ({ idPerfil, nomePerfil, isIndicador, tiposPerfil }) => {
  const _renderContentModal = (tag: string, historico_descricao: string) => {
    let mostrarInput = false;
    if (tag === 'Geral') {
      mostrarInput = true;
    }

  

    return (
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={12}
          container
          justify="center"
          alignItems="center"
        >
          <h3 style={{ marginRight: 10 }}>Histórico </h3>{' '}
          <Tag color="secondary"> {tag} </Tag>
        </Grid>
        <Grid item xs={12} md={12}>
          <InputButton
            historico_descricao={historico_descricao}
            idPerfil={idPerfil}
            input={mostrarInput}
          />
        </Grid>
      </Grid>
    );
  };


  const _renderContentModalDoacao = (tag: string) => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={12}
          container
          justify="center"
          alignItems="center"
        >
          <h3 style={{ marginRight: 10 }}>Histórico </h3>{' '}
          <Tag color="secondary"> {tag} </Tag>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: 20, color: '#616161' }}>
          <TableDoacao idPerfil={idPerfil} />
        </Grid>
      </Grid>
    );
  };

  const _renderContentModalSolicitacao = (tag: string) => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={12}
          container
          justify="center"
          alignItems="center"
        >
          <h3 style={{ marginRight: 10 }}>Histórico </h3>{' '}
          <Tag color="secondary"> {tag} </Tag>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: 20, color: '#616161' }}>
          <TableSolicitacao idPerfil={idPerfil} tiposPerfil={tiposPerfil}/>
        </Grid>
      </Grid>
    );
  };

  const _renderContentModalBoletos = (tag: string) => {
    return (
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={12}
          container
          justify="center"
          alignItems="center"
        >
          <h3 style={{ marginRight: 10 }}>Histórico </h3>{' '}
          <Tag color="secondary"> {tag} </Tag>
        </Grid>
        <Grid
          item
          lg={12}
          xs={12}
          md={12}
          style={{ marginTop: 20, color: '#616161' }}
        >
          <TabelaBoletos idPerfil={idPerfil} />
        </Grid>
      </Grid>
    );
  };

  const _renderContentModalIndicados = (tag: string, perfilID: number) => {
    const [data, setData] = React.useState<any[]>([]);
    const [loading, setLoading] = React.useState(false);
    const loadTable = async () => {
      setLoading(true);
      api
        .get(
          `api/Indicador/GetIndicadosByPerfilIdIndicador?perfilIdIndicador=${perfilID}`,
        )
        .then(response => {
          setData(response.data);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
        });
    };

    React.useEffect(() => {
      loadTable();
    }, [perfilID]);

    return (
      <Grid container justify="center" alignItems="center">
        <Grid
          item
          xs={12}
          md={12}
          container
          justify="center"
          alignItems="center"
        >
          <h3 style={{ marginRight: 10 }}>Histórico </h3>{' '}
          <Tag color="secondary"> {tag} </Tag>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: 20, color: '#616161' }}>
          <TableNoPagination
            data={data}
            columns={[
              {
                documento_pessoa_indicada: 'Documento do indicado',
                nome_pessoa_indicada: 'Nome do indicado',
              },
            ]}
            keys={['documento_pessoa_indicada', 'nome_pessoa_indicada']}
            loading={loading}
            Actions={[
              {
                Button: (row: any) => {
                  return (
                    <IconButton
                      onClick={() =>
                        window.open(`/cadastro/${row.id_pessoa_indicada}/`)
                      }
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Grid item sm={12} xs={12} style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Grid container direction="column">
          <p style={{ textAlign: 'center' }}>Histórico</p>
          <p style={{ textAlign: 'center' }}>
            <strong>{nomePerfil}</strong>
          </p>
        </Grid>

        <Modal
          size="grande"
          color="secondary"
          titleButton="Geral"
          Icon={Article}
        >
          {_renderContentModal('Geral', 'geral')}
        </Modal>
        {/*<Modal
          size="grande"
          color="secondary"
          titleButton="Status"
          Icon={CategoryOutlined}
        >
          {_renderContentModal('Status', 'status')}
        </Modal>*/}
        <Modal
          size="gigante"
          color="secondary"
          titleButton="Doações"
          Icon={FavoriteBorder}
        >
          {_renderContentModalDoacao('Doações')}
        </Modal>
        <Modal
          size="grande"
          color="secondary"
          titleButton="Endereço"
          Icon={PlaceOutlined}
        >
          {_renderContentModal('Endereço', 'endereço')}
        </Modal>
        <Modal
          size="grande"
          color="secondary"
          titleButton="Débito"
          Icon={AccountBalanceOutlined}
        >
          {_renderContentModal('Débito', 'débito')}
        </Modal>
        <Modal
          size="grande"
          color="secondary"
          titleButton="Cartas"
          Icon={ChromeReaderModeOutlined}
        >
          {_renderContentModal('Cartas', 'cartas')}
        </Modal>
        <Modal
          size="grande"
          color="secondary"
          titleButton="Corresp. devolvida"
          Icon={Article}
        >
          {_renderContentModal('Correspondência devolvida', 'correspondencia devolvida')}
        </Modal>
        <Modal
          size="gigante"
          color="secondary"
          titleButton="Boletos"
          Icon={() => <Icon path={mdiBarcodeScan} size={1} color="White" />}
        >
          {_renderContentModalBoletos('Boletos')}
        </Modal>
        <Modal
          size="grande"
          color="secondary"
          titleButton="E-mails"
          Icon={MailOutline}
        >
          {_renderContentModal('E-mails', 'email')}
        </Modal>
        <Modal
          size="grande"
          color="secondary"
          titleButton="Ligações"
          Icon={Call}
        >
          {_renderContentModal('Ligações', 'telefonia')}
        </Modal>

        <InvisibleContent visible={isIndicador ? true : false}>
          <Modal
            size="grande"
            color="secondary"
            titleButton="Indicados"
            Icon={StarsOutlined}
          >
            {isIndicador
              ? _renderContentModalIndicados('Indicados', idPerfil)
              : {}}
          </Modal>
        </InvisibleContent>
        <Modal
          size="gigante"
          color="secondary"
          titleButton="Solicitações"
          Icon={() => <Icon path={mdiTextBoxCheckOutline} size={1} color="White" />}
        >
          {_renderContentModalSolicitacao('Solicitações')}
        </Modal>
      </Grid>
    </>
  );
};

export default Historico;
