import api from '@http/api';
import moment from 'moment';
import { IPerfil } from '@interfaces/UserComplete';

export interface IResponseRequestPessoaByCPF {
  pessoa: {
    id: string;
    documento: string;
    nome: string;
    perfis: IPerfil[];
    tipos_perfis: string[];
  };
}

export interface IResponsePessoaByCPF {
  response?: {
    pessoa: {
      id: string;
      nome: string;
      documento: string;
      perfis: IPerfil[];
      tipos_perfis: string[];
    };
  };
  error: boolean;
}

export interface IResponseCreatePessoa {
  id: number;
}

export interface IPessoa {
  id: number;
  id_pessoa_responsavel: number;
  id_pessoa_indicador: number;
  nome_pessoa_responsavel: string;
  nome_pessoa_indicador: string;
}

export interface IRequestDeleteIndicador {
  pessoa: IPessoa;
}

class PageEditCadastroPessoaServices {

  public async getPessoaByID(id: number): Promise<IResponsePessoaByCPF> {
    return await api
      .get(`api/Pessoa/GetPessoaById/${id}`)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async getPessoaByDocumento(
    documento: string,
  ): Promise<IResponsePessoaByCPF> {
    return await api
      .post<IResponseRequestPessoaByCPF>(`api/Pessoa/FindPessoaByCpf`, {
        documento,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async DeleteIndicador(
    pessoa: IRequestDeleteIndicador,
  ) {
    console.log(pessoa);
    return await api
      .put('/api/Pessoa/UpdatePessoaByPessoaId', pessoa)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,

        };
      });
  }

}

export default PageEditCadastroPessoaServices;
