import React from 'react';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    Cell,
    Tooltip,
    CartesianGrid,
    XAxis,
    YAxis,
    Legend
} from 'recharts';

import { Container, ChartContainer } from './styles';

interface IBarChartProps {
    title: string;    
    data: {
        mes: string,
        doadores_mes: number,
        doacoes_mes: number,
        valor_doacoes_mes: number,
        cadastros_mes: number,
        percentual_doadores: number,
        media_per_capta: number,
    }[],
}


const BarChartBox: React.FC<IBarChartProps> = ({ title, data}) => (
    <Container>
        <span>{title}</span>
        <ChartContainer>
            <ResponsiveContainer>
                <BarChart
                    width={500}
                    height={100}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="mes" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="cadastros_mes" name="Cadastros" fill="#6D6A93" />
                    <Bar dataKey="doadores_mes" name="Doadores" fill="#D49EB9" />
                </BarChart>
            </ResponsiveContainer>
        </ChartContainer>
    </Container>
);

export default BarChartBox;