import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import ListItem from '@material-ui/core/ListItem';

interface IProps {
  onClick: Function;
  to: string;
}

const ListItemActive: React.FC<IProps> = ({ onClick, to, children }) => {
  let match = useRouteMatch({
    path: to,
    exact: false,
  });

  return (
    <ListItem selected={match ? true : false} button onClick={() => onClick()}>
      {children}
    </ListItem>
  );
};

export default ListItemActive;
