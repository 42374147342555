import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import { Checkbox, Grid, IconButton } from '@material-ui/core';
import { AddBox, ClearRounded} from '@material-ui/icons';
import ButtonComponent from '@components/Shared/Buttons/Button';
import Modal from '@src/components/Shared/Modal/ModalPure';
import AddCorrespondencia from './AddCorrespondencia';
import DeleteCorrespondencia from './DeleteCorrespondencia';
import AproveCorrespondencia from './AproveCorrespondencia';


import CountDown from '@components/Shared/CountDown';
import TableCustomKeysNoPagination from './TableCustomKeysNoPagination';
import { TitlePage } from '@src/@styles/styles.global';

import Services, {
  ICorrespDevolvidaTable,
  ISolicitacaoResultTable
} from './services';

import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Collapse from '@material-ui/core/Collapse';

import { Form, Select as SelectAntd } from 'antd';

import {
  StyledTableCell,
  StyledTableRow,
} from '@src/components/Shared/Tables/TableCollapsible/styles';

import { useAuth } from '@hooks/AuthenticateContext';

const columnsLabel = {

  id_pessoa: 'ID pessoa',
  nome: 'Nome',
  mala_mensal: 'Mala mensal?',
  data_envio: 'Data envio',
  data_devolucao: 'Data devolução',
  motivo_devolucao: 'Motivo devolução',
};


interface IProps {
  idPerfil: number;
  functionReload?: Function;
  idSolicitacao: number;
}

const ListCorrespondenciaDevolvida: React.FC<IProps> = ({
  idPerfil,
  functionReload,
  idSolicitacao,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ICorrespDevolvidaTable[]>([]);


  const [openModalAddCorrespondencia, setOpenModalAddCorrespondencia] =
    useState(false);
    const [openModalDeleteCorrespondencia, setOpenModalDeleteCorrespondencia] =
    useState(false);
    const [openModalAproveCorrespondencia, setOpenModalAproveCorrespondencia] =
    useState(false);

  const [tipoAcaoId, setTipoAcaoId] = useState<number>(0);
  const [tipoAcaoDescricao, setTipoAcaoDescricao] = useState('');

  const [dadosSelecionadas, setDadosSelecionadas] = useState<string[]>([]);

  async function loadCorrespDevolvida ()  {
    setLoading(true);
    const services = new Services();

    const { error, response } = await services.DetalhesCorrespDevolvida();

    if (!error && response) {
      setData(response);
    }

    setLoading(false);
  };

  const finishAddCorrespondencia = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalAddCorrespondencia(false);
  };

  const finishDeleteCorrespondencia = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalDeleteCorrespondencia(false);
  };

  const finishAproveCorrespondencia = async () => {
    if (functionReload) {
      await functionReload();
    }
    setOpenModalAproveCorrespondencia(false);
  };

  const selectedDatas = (value: ISolicitacaoResultTable) => {
    if (dadosSelecionadas.filter(x => x === value.id_correspondencia_devolvida).length <= 0) {
      setDadosSelecionadas([...dadosSelecionadas, value.id_correspondencia_devolvida]);
    } else {
      const newArray = dadosSelecionadas.filter(function (item) {
        return item !== value.id_correspondencia_devolvida;
      });

      setDadosSelecionadas(newArray);
    }
  };

  console.log('Testando', dadosSelecionadas)


  useEffect(() => {
   loadCorrespDevolvida();
    window.document.title = 'CRM - Lista de Correspondência devolvida';
  }, []);

  const { user, signOut } = useAuth();

    //Bloqueio inspecionar
    useEffect(() => {
      if (user.role !== 'ADM AMEX') {
        const handleKeyDown = (event: KeyboardEvent) => {
          if (event.key === 'F12') {
            event.preventDefault();
            alert('F12 está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'I') {
            event.preventDefault();
            alert('Ctrl+Shift+I está desativado.');
          }
          if (event.ctrlKey && event.shiftKey && event.key === 'J') {
            event.preventDefault();
            alert('Ctrl+Shift+J está desativado.');
          }
          if (event.ctrlKey && event.key === 'U') {
            event.preventDefault();
            alert('Ctrl+U está desativado.');
          }
        };
  
        const handleContextMenu = (event: MouseEvent) => {
          event.preventDefault();
          alert('O botão direito do mouse está desativado.');
        };
  
        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('contextmenu', handleContextMenu);
  
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
          document.removeEventListener('contextmenu', handleContextMenu);
        };
      }
    }, [user.role]);
    

  return (
    <Grid container>
      <Grid item md={12} xs={12}>
        <TitlePage style={{ margin: 5 }}>Correspondência Devolvida</TitlePage>
      </Grid>

      <Grid
        container
        alignItems="center"
        item
        md={12}
        xs={12}
        style={{ margin: 5 }}
      >

        <Modal
          title="Nova correspondência"
          size="gigante"
          openModal={openModalAddCorrespondencia}
          controlModal={(value: boolean) =>
            setOpenModalAddCorrespondencia(value)
          }
        >
          <AddCorrespondencia
            //perfil_id={idPerfil}
            finishCreate={finishAddCorrespondencia}
            //ids_solicitacao={dadosSelecionadas}
          />
        </Modal>

        <Grid item md={2} xs={4} style={{ margin: 5 }}>

        <ButtonComponent
            color="secondary"
            onClick={() => setOpenModalAddCorrespondencia(true)}
            fullWidth
            style={{ height: 46 }}
          >
            <AddBox style={{paddingRight: 5}}/>
            Adicionar

          </ButtonComponent>


        </Grid>

      </Grid>

      <Modal
          title=""
          size="pequeno"
          openModal={openModalDeleteCorrespondencia}
          controlModal={(value: boolean) =>
            setOpenModalDeleteCorrespondencia(value)
          }
        >
          <DeleteCorrespondencia
            //perfil_id={idPerfil}
            finishDelete={finishDeleteCorrespondencia}
            id_correspondencia={tipoAcaoId}
            nome={tipoAcaoDescricao}
          />
        </Modal>

        <Modal
          title=""
          size="pequeno"
          openModal={openModalAproveCorrespondencia}
          controlModal={(value: boolean) =>
            setOpenModalAproveCorrespondencia(value)
          }
        >
          <AproveCorrespondencia
            finishAprove={finishAproveCorrespondencia}
            id={dadosSelecionadas}
          />
        </Modal>

        <Grid container item lg={12} xs={12}>
          <Grid item lg={8} xs={8}>
            <CountDown
              minutesDefault={1}

              title="Tempo para atualização dos dados"
            />
          </Grid>
          {/*<Grid item lg={3} xs={3}  style={{paddingLeft: 130, paddingBottom: 1}}>

            <ButtonComponent
              color="quartenary"
              disabled={dadosSelecionadas.length <= 0}
              onClick={() => setOpenModalAproveCorrespondencia(true)}
              fullWidth
              style={{ height: 46, width: 180}}
            >
              Aprovar

            </ButtonComponent>

        </Grid>*/}
      </Grid>
     <Grid item lg={11} xs={12}>
        <TableCustomKeysNoPagination
        //Selected={(e: ISolicitacaoResultTable) => (
          //<Grid container justifyContent="center">
           // <Checkbox
           //   onChange={() => selectedDatas(e)}
           // checked={
                //dadosSelecionadas.filter(x => x === e.id_correspondencia_devolvida).length >
              //  0
             // }
            ///>
          //</Grid>
        //)}
          loading={loading}
          columns={[columnsLabel]}
          FieldsBold={['nome']}
          data={data}
          keys={[
            'id_pessoa',
            'nome',
            'mala_mensal',
            'data_envio',
            'data_devolucao',
            'motivo_devolucao',
          ]}


        />
      </Grid>

    </Grid>
  );
};

export default ListCorrespondenciaDevolvida;
