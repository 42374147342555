import React, { useEffect } from 'react';
// import { GetRoutesPermissions } from '@hooks/auth';

import RouterClienteAdmin from './Cliente/AdminRouter';
import RouterClienteOperacional from './Cliente/OperacionalRouter';
import RouterAmexAdmin from './Amex/AdminRouter';
import RouterAmexOperacional from './Amex/OperacionalRouter';

import RouteAuth from './Auth';

import { token } from '@hooks/auth';
import { useAuth } from '@hooks/AuthenticateContext';
import { getColors } from '@styles/colorsTheme';

import AdminLayout from '@layouts/Amex';
// import OperacionalLayout from '@layouts/Cliente';

import Services from '../components/Amex/SelectEntidade/services';
import { useEntidade } from '@src/hooks/EntidadeContext';
import { LoadingProvider } from '@hooks/LoadingContext';

import { Spin } from 'antd';

const DrawLayoutClienteAdmin = (Route: any) => {
  return (
    <LoadingProvider>
      <Route />
    </LoadingProvider>
  );
};

const DrawLayoutOperacional = (Route: any) => {
  return (
    <LoadingProvider>
      <Route />
    </LoadingProvider>
  );
};

const DrawLayoutAmexAdmin = (Route: any, updateEntidade: any) => {
  const [loadingEntidade, setLoadingEntidade] = React.useState(false);

  const load = async () => {
    setLoadingEntidade(true);

    const entidadeStorage = localStorage.getItem('@amex:entidade');

    if (entidadeStorage) {
      const entidadeStorageJSON = JSON.parse(entidadeStorage);

      updateEntidade(entidadeStorageJSON);
      setLoadingEntidade(false);
    } else {
      const services = new Services();
      const { error, response } = await services.getEntidades();

      if (!error && response) {
        updateEntidade({
          descricao: response[0].label,
          id: parseInt(response[0].value),
        });
        setLoadingEntidade(false);
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loadingEntidade) {
    return (
      <Spin
        size="large"
        spinning={true}
        tip={'Carregando clientes...'}
        style={{
          background: getColors()?.branco,
          width: '100%',
          height: '100%',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            background: getColors()?.branco,
            width: '100%',
            height: '100%',
            minHeight: '100vh',
          }}
        ></div>
      </Spin>
    );
  }

  return (
    <AdminLayout>
      <LoadingProvider>
        <Route />
      </LoadingProvider>
    </AdminLayout>
  );
};

const DrawLayoutAmexOperacional = (Route: any, updateEntidade: any) => {
  const [loadingEntidade, setLoadingEntidade] = React.useState(false);

  const load = async () => {
    setLoadingEntidade(true);

    const entidadeStorage = localStorage.getItem('@amex:entidade');

    if (entidadeStorage) {
      const entidadeStorageJSON = JSON.parse(entidadeStorage);

      updateEntidade(entidadeStorageJSON);
      setLoadingEntidade(false);
    } else {
      const services = new Services();
      const { error, response } = await services.getEntidades();

      if (!error && response) {
        updateEntidade({
          descricao: response[0].label,
          id: parseInt(response[0].value),
        });
        setLoadingEntidade(false);
      }
    }
  };

  useEffect(() => {
    load();
  }, []);

  if (loadingEntidade) {
    return (
      <Spin
        size="large"
        spinning={true}
        tip={'Carregando clientes...'}
        style={{
          background: getColors()?.branco,
          width: '100%',
          height: '100%',
          minHeight: '100vh',
        }}
      >
        <div
          style={{
            background: getColors()?.branco,
            width: '100%',
            height: '100%',
            minHeight: '100vh',
          }}
        ></div>
      </Spin>
    );
  }

  return (
    <AdminLayout>
      <LoadingProvider>
        <Route />
      </LoadingProvider>
    </AdminLayout>
  );
};

function OrchestratorRoutes() {
  const { user } = useAuth();
  const { updateEntidade } = useEntidade();

  if (!!user && !!token) {
    if (user.role === 'ADM CLIENTE I') {
      return DrawLayoutClienteAdmin(RouterClienteAdmin);
    } else if (user.role === 'OPERACIONAL CLIENTE I') {
      return DrawLayoutOperacional(RouterClienteOperacional);
    } else if (user.role === 'ADM AMEX') {
      return DrawLayoutAmexAdmin(RouterAmexAdmin, updateEntidade);
    } else if (user.role === 'OPERACIONAL AMEX') {
      return DrawLayoutAmexOperacional(RouterAmexOperacional, updateEntidade);
    }

    return <RouteAuth />;
  } else {
    return <RouteAuth />;
  }
}

export default OrchestratorRoutes;
