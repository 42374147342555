import React, { useState, useEffect } from 'react';
import { Grid, TextField } from '@material-ui/core';
import TableCollapsible from '../../Tables/TableColapseNoPaginationDoacao';
import api from '@http/api';
import { CurrencyFormat, FormatDate } from '@utils/formats';


interface ITest {
  data_doacao: string;
  origem_doacao_descricao: string;
  campanha_descricao: string;
  valor_doacao: string;
  observacao: string;

}

interface IProps {
  idPerfil: number;
}

const GetDoacoes: React.FC<IProps> = ({ idPerfil }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITest[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const columnsLabel = {
    data_doacao: 'Data',
    origem_doacao_descricao: 'Origem',
    campanha_descricao: 'Campanha',
    valor_doacao: 'Valor',
    observacao: 'Observacao',
  };

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      await api
        .post('api/Doacao/FindDoacoesByPerfil', { perfil_id: idPerfil })
        .then(response => {
          const items = response.data.doacoes;
          setTotal(response.data.total_doacoes);
          const itemData: ITest[] = items.map((item: ITest) => ({
            data_doacao: FormatDate(item.data_doacao),
            valor_doacao: CurrencyFormat(parseFloat(item.valor_doacao)),
            origem_doacao_descricao: item.origem_doacao_descricao,
            campanha_descricao: item.campanha_descricao,
            observacao: item.observacao || '',
          }));

          setData(itemData);
          setLoading(false);
        })
        .catch(error => {
          alert(error);
        });
    }
    loadData();
  }, [idPerfil]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(item =>
    item.observacao.toLowerCase().includes(searchTerm.toLowerCase())
  );



  return (
    <>
      <TextField
        label="Pesquisar"
        variant="outlined"
        margin="normal"
        style={{width: '100%'}}
        value={searchTerm}
        onChange={handleSearchChange}
        InputLabelProps={{
          style: {
            top: '-2px',
          }
        }}
      />
      <TableCollapsible
        loading={loading}
        columns={[columnsLabel]}
        FieldsBold={['valor_doacao']}
        data={filteredData}
        keys={['Data', 'valor', 'Origem', 'Campanha', 'Detalhes']}
        ItemBottom={
          <Grid container justify="flex-end" style={{ marginTop: 20 }}>
            <p>
              <strong>Total de doações: </strong>
              {CurrencyFormat(total)}
            </p>
          </Grid>
        }
      />
    </>
  );
}
export default GetDoacoes;
