import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const TitlePage = styled.h1`
  font-weight: bold;
  color: #616161;
  padding: 20px;
  font-size: 22px;
`;

export const DivLoading = styled(Grid)`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  height: 450px;
  justify-content: center;
  align-items: center;
  z-index: 500;
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
`;
