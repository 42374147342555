import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// import { getRouteByID } from '@hooks/getFuncionalidades';

import { routesAdmin, IRoute } from '../routes/Amex.admin.config.routes';

import { useAuth } from '@hooks/AuthenticateContext';

function RenderRoutes(route: IRoute, key: number, user: any) {
  // if (getRouteByID(route.id)) {

  if (route.roles.includes(user.role)) {
    return (
      <Route key={key} path={route.path} exact component={route.component} />
    );
  }
  // }
}

function RouteAmexAdmin() {
  const { user } = useAuth();
  return (
    <div>
      <Switch>
        <Route
          exact
          path="/"
          component={() => <Redirect to={routesAdmin[0].path} />}
        />
        {routesAdmin.map((route, i) => RenderRoutes(route, i, user))}
        <Route
          path="*"
          component={() => {
            return <Redirect to={routesAdmin[0].path} />;
          }}
        />
      </Switch>
    </div>
  );
}

export default RouteAmexAdmin;
