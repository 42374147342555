import styled from 'styled-components';


interface IContainerProps {
    color: string;
}


export const Container = styled.div<IContainerProps>`
    width: 23%;
    margin: 10px auto;
    background-color:${props => props.color};
    color:#36316a;
    border-radius: 7px;
    padding: 10px 20px;
    position: relative;
    overflow: hidden;

    > img {
        position: absolute;
        top: -5px;
        right: -40px;
        opacity: .4;
    }

    > span {
        font-size: 16px;
        font-weight: bold;
    }

    > h1 {
        strong {
            font-size: 18px;
        }
    }

    @media (max-width: 768px) {
        max-width: none;
        width: 90%; 
        margin-right: auto; 
        margin-left: auto; 

        > h1 {
            strong {
                font-size: 16px;
            }
        }
    }
`;

