import moment from 'moment';
import api from '@http/api';

interface IRequestLoadRelatorioPeriodo {
  data_inicial: any;
  data_final: any;
}

export interface IItems {
  ano_atual: {
    valor: string;
  };
  ano_passado: {
    valor: string;
  };

ano_retrasado: {
  valor: string;
};
}

interface IResponse {
  error: boolean;
  response?: IItems[];
}


interface IItemsRelatorioPeriodo {
  dia: string;
  mes: string;
  ano: string;
  valor_doacoes: string;
}

interface IGraficoPeriodo {
  key: string;
  valor: string;
}

interface IResponseLoadRelatorioPeriodo {
  doacoes_periodo: number;
  tarifa_periodo: number;
  quantidade_doacoes_periodo: number;
  media_doacoes_periodo: number;
  comparacao_periodo: IItemsRelatorioPeriodo[];
}

interface IReturn {
  error: boolean;
  response?: {
    graficoPeriodoData: IGraficoPeriodo[];
    comparacao_periodo: IItemsRelatorioPeriodo[];
    doacoes_periodo: number;
    media_doacoes_periodo: number;
    quantidade_doacoes_periodo: number;
  };
}

interface IRequestRelatorioPDF {
  mensal: {
    ativo: boolean;
    anoPassado: boolean;
    anoRetrasado: boolean;
    anoAtual: boolean;
    grafico: boolean;
    tabela: boolean;
  };
  anual: {
    ativo: boolean;
    anoPassado: boolean;
    anoRetrasado: boolean;
    anoAtual: boolean;
    grafico: boolean;
    tabela: boolean;
  };
  usuario: string;
  entidade_id: number;
}

interface IResponseRelatorioPDF {
  error: boolean;
  response?: {
    responseURL: string;
  };
}

export interface IItemsDashboardMes {
  mes: string;
  doadores_mes: number;
  doacoes_mes: number;
  valor_doacoes_mes: number;
  cadastros_mes: number;
  percentual_doadores: number;
  media_per_capta: number;
}

class PageDoacoesServices {
  public async loadRelatorioPeriodo({
    data_inicial,
    data_final,
  }: IRequestLoadRelatorioPeriodo): Promise<IReturn> {
    data_final = data_final.setHours(20);
    data_final = moment
      .utc(new Date(data_final))
      .format('YYYY-MM-DD HH:mm:ssZ');

    data_inicial = data_inicial.setHours(20);
    data_inicial = moment
      .utc(new Date(data_inicial))
      .format('YYYY-MM-DD HH:mm:ssZ');

    return await api
      .post('/api/Relatorio/FindRelatorioPeriodo', {
        data_inicial: data_inicial,
        data_final: data_final,
      })
      .then(response => {
        const items: IResponseLoadRelatorioPeriodo = response.data;
        let graficoPeriodoData: IGraficoPeriodo[] = [];

        items.comparacao_periodo.reverse().map(item => {
          return graficoPeriodoData.push({
            key: item.dia + '/' + item.mes + '/' + item.ano,
            valor: item.valor_doacoes,
          });
        });

        const comparacao_periodo = items.comparacao_periodo;
        const doacoes_periodo = items.doacoes_periodo;
        const media_doacoes_periodo = items.media_doacoes_periodo;
        const quantidade_doacoes_periodo = items.quantidade_doacoes_periodo;

        return {
          error: false,
          response: {
            graficoPeriodoData: graficoPeriodoData,
            comparacao_periodo: comparacao_periodo,
            doacoes_periodo: doacoes_periodo,
            media_doacoes_periodo: media_doacoes_periodo,
            quantidade_doacoes_periodo: quantidade_doacoes_periodo,
          },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadRelatorioPeriodoRecente({
    data_inicial,
    data_final,
  }: IRequestLoadRelatorioPeriodo): Promise<IReturn> {
    data_final = data_final.setHours(20);
    data_final = moment
      .utc(new Date(data_final))
      .format('YYYY-MM-DD HH:mm:ssZ');

    data_inicial = data_inicial.setHours(20);
    data_inicial = moment
      .utc(new Date(data_inicial))
      .format('YYYY-MM-DD HH:mm:ssZ');

    return await api
      .post('/api/Relatorio/FindRelatorioRecente', {
        data_inicial: data_inicial,
        data_final: data_final,
      })
      .then(response => {
        const items: IResponseLoadRelatorioPeriodo = response.data;
        let graficoPeriodoData: IGraficoPeriodo[] = [];

        items.comparacao_periodo.reverse().map(item => {
          return graficoPeriodoData.push({
            key: item.dia + '/' + item.mes + '/' + item.ano,
            valor: item.valor_doacoes,
          });
        });

        const comparacao_periodo = items.comparacao_periodo;
        const doacoes_periodo = items.doacoes_periodo;
        const media_doacoes_periodo = items.media_doacoes_periodo;
        const quantidade_doacoes_periodo = items.quantidade_doacoes_periodo;

        return {
          error: false,
          response: {
            graficoPeriodoData: graficoPeriodoData,
            comparacao_periodo: comparacao_periodo,
            doacoes_periodo: doacoes_periodo,
            media_doacoes_periodo: media_doacoes_periodo,
            quantidade_doacoes_periodo: quantidade_doacoes_periodo,
          },
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }


  public async loadRelatorioPeriodoComparacao({
    data_final,
    data_inicial,
  }: IRequestLoadRelatorioPeriodo): Promise<IResponse> {


    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const delayedYear = currentYear - 2;
    data_final = data_final.setHours(20);
    data_final = moment
      .utc(new Date(data_final))
      .format('YYYY-MM-DD HH:mm:ssZ');

    data_inicial = data_inicial.setHours(20);
    data_inicial = moment
      .utc(new Date(data_inicial))
      .format('YYYY-MM-DD HH:mm:ssZ');
    return await api
      .post('api/Relatorio/GetRelatorioComparacaoPeriodo', {
        data_final,
        data_inicial,

      })
      .then(response => {

        const item: any = response.data;
        let graficoAnual: any[] = [];

        let obj: any = {};

        obj[currentYear] = item.ano_atual;
        obj[lastYear] = item.ano_passado;
        obj[delayedYear] = item.ano_retrasado;

        graficoAnual.push(obj);

        return {
          error: false,
          response: graficoAnual,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetRelatorioMensalComparacaoMensal() {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const delayedYear = currentYear - 2;

    return await api
      .get('/api/Relatorio/GetRelatorioComparacaoMensal')
      .then(response => {
        const items: any[] = response.data;
        let graficoMensal: any[] = [];

        items.map(item => {
          let obj: any = {};
          obj[currentYear] = item.ano_atual;
          obj[lastYear] = item.ano_passado;
          obj[delayedYear] = item.ano_retrasado;

          return graficoMensal.push(obj);
        });

        return {
          error: false,
          response: graficoMensal,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetRelatorioMensalComparacaoAnual() {
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;
    const delayedYear = currentYear - 2;

    return await api
      .get('/api/Relatorio/GetRelatorioComparacaoAnual')
      .then(response => {
        const item: any = response.data;
        let graficoAnual: any[] = [];

        let obj: any = {};

        obj[currentYear] = item.ano_atual;
        obj[lastYear] = item.ano_passado;
        obj[delayedYear] = item.ano_retrasado;

        graficoAnual.push(obj);

        return {
          error: false,
          response: graficoAnual,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }
  public async GetRelatorioPDF({
    anual,
    entidade_id,
    mensal,
    usuario,
  }: IRequestRelatorioPDF): Promise<IResponseRelatorioPDF> {
    return await api
      .post<{ responseURL: string }>('/api/Relatorio/GerarPDF', {
        anual,
        entidade_id,
        mensal,
        usuario,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadDashboardGeral() {
    return await api
      .post('/api/Dashboard/FindDashboardGeral')
      .then(response => {
        const total_cadastros = response.data.total_cadastros;
        const total_doacoes = response.data.total_doacoes;
        const valor_total_doacoes = response.data.valor_total_doacoes;
        const media_per_capta_anual = response.data.media_per_capta_anual;
        const comparacao_mes = response.data.comparacao_periodo;

        var itemsGrafico: IItemsDashboardMes[] = [];

        comparacao_mes.map((item: IItemsDashboardMes) => {
          return itemsGrafico.push({
            mes: item.mes,
            doadores_mes: item.doadores_mes,
            doacoes_mes: item.doacoes_mes,
            valor_doacoes_mes: item.valor_doacoes_mes,
            cadastros_mes: item.cadastros_mes,
            percentual_doadores: item.percentual_doadores,
            media_per_capta: item.media_per_capta,
          });
        });

        return {
          error: false,
          response: { total_cadastros,
                      total_doacoes,
                      valor_total_doacoes,
                      media_per_capta_anual,
                      comparacao_mes: itemsGrafico,
                    },
        };
      })
      .catch(error => {
        console.log('caiu aqui service dashboard', error);

        return {
          error: true,
          response: [],
        };
      });
  }

}

export default PageDoacoesServices;
