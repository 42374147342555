import React, { useState } from 'react';
import { Space, Form, Select, FormInstance, Divider } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import UFs from '@components/Shared/BuscaEstados/UF2.json';
import CidadesService from '@components/Shared/BuscaCidades/services';
import { Grid } from '@material-ui/core';
import { Clear, Add, Delete } from '@material-ui/icons';
import Button from '@material-ui/core/Button';

interface IProps {
  name: any;
  fieldKey: any;
  remove: any;
  restField: any;
  formRef: FormInstance;
}

const ExampleInput: React.FC<IProps> = ({
  restField,
  name,
  fieldKey,
  formRef,
  remove,
}) => {
  const [cidades, setCidades] = useState<any[]>([]);
  const [uf, setUF] = useState('');

  const [disable, setDisable] = useState(true);

  const renderOptionsCidades: any = [];
  cidades.map(cidade => {
    renderOptionsCidades.push(
      <Select.Option
        key={JSON.stringify(cidade)}
        value={JSON.stringify(cidade)}
      >
        {cidade.label}
      </Select.Option>,
    );
  });

  const renderOptionsEstados: any = [];
  UFs.UF.map(estado => {
    renderOptionsEstados.push(
      <Select.Option
        key={JSON.stringify(estado)}
        value={JSON.stringify(estado)}
      >
        {estado.label}
      </Select.Option>,
    );
  });

  React.useEffect(() => {
    setDisable(true);
    const getCidades = async () => {
      const cidadesService = new CidadesService();
      const getCidadesByUFResponse = await cidadesService.getCidadesByUF(uf);
      if (!getCidadesByUFResponse.error) {
        setCidades(getCidadesByUFResponse.response);
        setDisable(false);
      }
    };

    if (uf !== '') {
      getCidades();
    } else {
      setCidades([]);
      const { cidades } = formRef.getFieldsValue();

      if (cidades?.length > 0) {
        cidades[name] = {
          cidades: [],
        };
      }
    }
  }, [uf]);

  const setAllCidades = () => {
    const cidadesAll: any = [];

    cidades.map(cidade => cidadesAll.push(JSON.stringify(cidade)));

    const values = formRef.getFieldsValue();

    if (cidades?.length > 0) {
      values.cidades[name] = {
        cidades: cidadesAll,
      };

      formRef.setFieldsValue(values);
    }
  };

  const clearCidades = () => {
    const { cidades } = formRef.getFieldsValue();

    if (cidades?.length > 0) {
      console.log('remove');
      cidades[name] = {
        cidades: [],
      };

      formRef.setFieldsValue(cidades);
    }
  };

  return (
    <Grid
      container
      style={{ marginBottom: 8 }}
      alignContent="center"
      justify="center"
      alignItems="center"
    >
      <Grid item sm={3} xs={4}>
        <Form.Item
          fieldKey={[fieldKey, 'estados']}
          rules={[{ required: true, message: 'Missing first name' }]}
          label={'Estado'}
        >
          <Select
            mode="multiple"
            onChange={(value: any) => {
              if (value?.length > 1) {
                value.pop();
              } else if (value?.length > 0) {
                setUF(JSON.parse(value).label);
              } else {
                setUF('');
              }
            }}
          >
            {renderOptionsEstados}
          </Select>
        </Form.Item>
      </Grid>
      <Grid item sm={7} xs={7}>
        <Form.Item
          {...restField}
          name={[name, 'cidades']}
          label={'Cidades'}
          fieldKey={[fieldKey, 'cidades']}
          rules={[{ required: true, message: 'Selecione pelo menos 1 cidade' }]}
        >
          <Select
            mode="multiple"
            disabled={disable}
            maxTagCount={1}
            dropdownRender={menu => (
              <div>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    flexDirection: 'row',
                    padding: 4,
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      justifySelf: 'center',
                      alignSelf: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <a
                      style={{
                        flex: 'none',
                        padding: '4px',
                        display: 'block',
                        cursor: 'pointer',
                        textDecoration: 'None',
                      }}
                      onClick={setAllCidades}
                    >
                      <Add /> Selecionar todos
                    </a>
                  </div>
                  <div
                    style={{
                      width: '50%',
                      justifySelf: 'center',
                      alignSelf: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <a
                      style={{
                        flex: 'none',
                        padding: '4px',
                        display: 'block',
                        cursor: 'pointer',
                        textDecoration: 'None',
                      }}
                      onClick={clearCidades}
                    >
                      <Clear /> Remover todos
                    </a>
                  </div>
                </div>
              </div>
            )}
          >
            {renderOptionsCidades}
          </Select>
        </Form.Item>
      </Grid>
      <Grid
        item
        sm={2}
        xs={1}
        style={{ textAlign: 'center' }}
        alignItems="center"
      >
        <Button onClick={() => remove(name)} style={{}}>
          <Delete />
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExampleInput;
