import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { Empty } from 'antd';
import '@styles/AntStyles.css';

import {
  DivLoading,
  SpinnerLoadingMaterial,
  StyledTableCell,
  StyledTableRow,
  useStyles,
  Container,
  DivVoid,
} from './styles';
import { IconButton } from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import Services from '../services';
import { useSnackbar } from 'notistack';

interface ITableProps {
  data: object[];
  columns: Object[];
  loading: Boolean;
  Actions?: IActionObj[];
  Selected?: Function;
  FieldsBold?: string[];
  keys: string[];
}

interface IActionObj {
  Button: Function;
}

const TableActions: React.FC<ITableProps> = ({
  data,
  columns,
  Actions,
  Selected,
  FieldsBold,
  keys,
  loading,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  // const keys = Object.keys(data[0] || data);
  const alterColumns = columns[0] || {};
  const [loadingAction, setLoadingAction] = React.useState(false);

  const getPessoa = async (
    dados: { id: number; nome: string },
    tipoLista: boolean,
  ) => {
    setLoadingAction(true);

    const service = new Services();
    const { error, messageError, response } = await service.getPessoa(
      dados.id.toString(),
      tipoLista,
    );

    if (!error && response) {
      const objeto = {
        id_ligacao_interacao: response.id_ligacao_interacao,
        id_pessoa: response.id_pessoa,
      };
      setLoadingAction(false);

      window.open(
        `/cadastro/${response.id_pessoa}?telefonia=${JSON.stringify(objeto)}`,
      );
    } else if (error && messageError.status == 400) {
      setLoadingAction(false);

      if (messageError.data.retorno) {
        enqueueSnackbar(
          `Há uma interação já existente em: ${messageError.data.estrategia_id} - ${messageError.data.estrategia_nome} no retorno `,
          {
            title: `Há uma interação já existente em: ${messageError.data.estrategia_id} - ${messageError.data.estrategia_nome} no retorno `,
            variant: 'warning',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          },
        );
      } else {
        enqueueSnackbar(
          `Há uma interação já existente em: ${messageError.data.estrategia_id} - ${messageError.data.estrategia_nome}`,
          {
            title: `Há uma interação já existente em: ${messageError.data.estrategia_id} - ${messageError.data.estrategia_nome}`,
            variant: 'warning',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          },
        );
      }
    }
  };

  const Head = ({ keys, alterColumns }: any) => {
    return (
      <>
        {Selected && (
          <StyledTableCell align="center">Selecionar</StyledTableCell>
        )}

        {keys.map((key: any) => (
          <StyledTableCell key={key} align="center">
            {alterColumns[key] || key}
          </StyledTableCell>
        ))}

        <StyledTableCell align="center">1º ligação</StyledTableCell>
        <StyledTableCell align="center">Retorno</StyledTableCell>

        {Actions && <StyledTableCell align="center">Opções</StyledTableCell>}
      </>
    );
  };

  const Rows = ({ row }: any) => {
    const colunas = Object.keys(row);

    return (
      <StyledTableRow>
        {Selected && (
          <StyledTableCell>
            <div>{Selected(row)}</div>
          </StyledTableCell>
        )}

        {colunas.map(coluna => {
          if (coluna === 'listasPrimeira' || coluna === 'listasRetorno') {
            const quantidade = row[coluna]?.quantidade;
            const id = row[coluna]?.id;

            return (
              <StyledTableCell key={coluna} align="center">
                {FieldsBold?.includes(coluna) ? (
                  <div style={{ maxHeight: 46, maxWidth: 250 }}>
                    <strong
                      style={{
                        maxWidth: '50ch',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <IconButton
                        disabled={
                          quantidade === 0 || !quantidade || loadingAction
                        }
                        onClick={() => {
                          getPessoa(
                            row,
                            coluna === 'listasRetorno' ? true : false,
                          );
                        }}
                      >
                        <Phone /> {quantidade}
                      </IconButton>
                    </strong>
                  </div>
                ) : (
                  <div style={{ maxHeight: 46, maxWidth: 250 }}>
                    <p
                      style={{
                        maxWidth: '50ch',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      <IconButton
                        disabled={
                          quantidade === 0 || !quantidade || loadingAction
                        }
                        onClick={() => {
                          getPessoa(
                            row,
                            coluna === 'listasRetorno' ? true : false,
                          );
                        }}
                      >
                        <Phone /> {quantidade}
                      </IconButton>
                    </p>
                  </div>
                )}
              </StyledTableCell>
            );
          }

          if (!keys.includes(coluna)) {
            return;
          }

          return (
            <StyledTableCell key={coluna} align="center">
              {FieldsBold?.includes(coluna) ? (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <strong
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </strong>
                </div>
              ) : (
                <div style={{ maxHeight: 46, maxWidth: 450 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </p>
                </div>
              )}
            </StyledTableCell>
          );
        })}

        {Actions && (
          <StyledTableCell
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {Actions?.map((Item, index) => (
              <div key={index}>{Item.Button(row)}</div>
            ))}
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <Container>
      {(loading && (
        <DivLoading>
          <SpinnerLoadingMaterial />
        </DivLoading>
      )) ||
        (loadingAction && (
          <DivLoading>
            <SpinnerLoadingMaterial />
          </DivLoading>
        ))}

      {!loading && data.length <= 0 && (
        <DivVoid>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum dado encontrado"
          />
        </DivVoid>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader size="medium">
          {!loading && (
            <TableHead>
              <TableRow>
                <Head keys={keys} alterColumns={alterColumns} />
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, index: number) => {
              return <Rows key={index} row={row} />;
            })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default TableActions;
