import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Route from '@router/OrchestratorRoutes';
import Providers from '@hooks/index';

import Global from '@styles/global';
import { theme } from '@styles/Theme';
import { ThemeProvider, CssBaseline } from '@material-ui/core';

import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';
import { ConfigProvider } from 'antd';
import ptBRAnt from 'antd/lib/locale/pt_BR';
import 'rsuite/dist/styles/rsuite-default.css';

function App() {
  return (
    <BrowserRouter>
      <Providers>
        <Global />
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <IntlProvider locale={ptBR}>
            <ConfigProvider locale={ptBRAnt}>
              <Route />
            </ConfigProvider>
          </IntlProvider>
        </ThemeProvider>
      </Providers>
    </BrowserRouter>
  );
}

export default App;
