import api from '@http/api';

export interface IItemsDashboard {
    mes: string;
    ano_atual: number;
    ano_passado: number;
    ano_retrasado: number;
  }


class PageDashboardComparativoServices {

   public async loadDashboardComparativo() {
    console.log("RRRRR");
    return await api
      .post('/api/Dashboard/FindDashboardComparativo')
      .then(response => {
        const doacoes = response.data.doacoes;
        const cadastros = response.data.cadastros;
        const doadores = response.data.doadores;
        const media_per_capta = response.data.media_per_capta;
        var itemsDoacoes: IItemsDashboard[] = [];
        var itemsCadastros: IItemsDashboard[] = [];
        var itemsDoadores: IItemsDashboard[] = [];
        var itemsMedia: IItemsDashboard[] = [];

        doacoes.map((item: IItemsDashboard) => {
          return itemsDoacoes.push({
            mes: item.mes,
            ano_atual: item.ano_atual,
            ano_passado: item.ano_passado,
            ano_retrasado: item.ano_retrasado,
          });
        });

        cadastros.map((item: IItemsDashboard) => {
          return itemsCadastros.push({
            mes: item.mes,
            ano_atual: item.ano_atual,
            ano_passado: item.ano_passado,
            ano_retrasado: item.ano_retrasado,
          });
        });

        doadores.map((item: IItemsDashboard) => {
          return itemsDoadores.push({
            mes: item.mes,
            ano_atual: item.ano_atual,
            ano_passado: item.ano_passado,
            ano_retrasado: item.ano_retrasado,
          });
        });

        media_per_capta.map((item: IItemsDashboard) => {
          return itemsMedia.push({
            mes: item.mes,
            ano_atual: item.ano_atual,
            ano_passado: item.ano_passado,
            ano_retrasado: item.ano_retrasado,
          });
        });

        return {
          error: false,
          response: {
                      doacoes: itemsDoacoes,
                      cadastros: itemsCadastros,
                      doadores: itemsDoadores,
                      media_per_capta: itemsMedia,
                    },
        };
      })
      .catch(error => {
        console.log('caiu aqui service dashboard', error);

        return {
          error: true,
          response: [],
        };
      });
  }
}

export default PageDashboardComparativoServices;
