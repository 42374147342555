import api from '@http/api';

export interface IPessoa {
  id: string;
  nome: string;
  nome_fantasia: string;
  data_nascimento: any;
  documento: string;
  sexo: number;
  id_pessoa_responsavel: number;
  id_pessoa_indicador: number;
  nome_pessoa_responsavel: string;
  nome_pessoa_indicador: string;
}

export interface IFindDadosLigacao {
  id_ligacao_interacao: number | null;
  id_estrategia: number | null;
  estrategia_nome: string;
  id_pessoa: number | null;
  id_perfil: number | null;
  contatos_perfil: string[];
}

export interface IPutPessoa {
  status_ativo: 1 | 0;
}

export interface IRequestUpdatePessoaPerfil {
  pessoa: IPessoa;
}

class PageEditCadastroPessoaServices {
  public async UpdatePessoaPerfilByPessoaId(
    pessoa: IRequestUpdatePessoaPerfil,
  ) {
    console.log(pessoa);
    return await api
      .put('/api/Pessoa/UpdatePessoaByPessoaId', pessoa)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async PutPessoaPerfilByPessoaId(
    request: IPutPessoa,
    id: string | number,
  ) {
    return await api
      .put(`/api/Pessoa/desativarPessoaAndPerfil/${id}`, request)
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async FindDadosLigacao(
    id_ligacao_interacao: string | number,
    id_pessoa: string | number,
  ) {
    return await api
      .post<IFindDadosLigacao>('api/Telefonia/FindDadosLigacao', {
        id_ligacao_interacao,
        id_pessoa,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }
}

export default PageEditCadastroPessoaServices;
