import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { shade } from 'polished';
import { getColors } from '@styles/colorsTheme';

import {
  createStyles,
  Theme,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import { theme } from '@styles/Theme';
import { TextField } from '@material-ui/core';

//Components CSS

export const Container = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;

  background: #423B7D ;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;

  width: 100%;

  img {
    width: 200px;
  }

  form {
    margin: 40px 0;
    width: 340px;
    text-align: center;

    h1 {
      margin-bottom: 24px;
    }

    input {
      margin: 0;
    }

    a {
      color: #666;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: background-color 0.2s;

      &:hover {
        color: ${shade(0.2, '#666')};
      }
    }
  }

  > a {
    color: #3c445b;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: background-color 0.2s;

    &:hover {
      color: ${shade(0.2, '#82A2C1')};
    }
  }
`;

export const Background = styled.div`
  flex: 0.8;
  background: #423B7D ;
  box-shadow: 0px 4px 15px 20px rgba(0, 0, 0, 0.1);
`;
//Components CSS

export const LinkRoute = styled(Link)`
  color: ${getColors()?.branco + '80'} !important;
  font-size: 14px;
`;

// Material-UI
export const CssTextField = withStyles({
  root: {
    width: '100%',
    maxWidth: 400,
    minWidth: 280,
    '& label': {
      color: getColors()?.branco,
      opacity: 0.3,
    },

    '& .MuiFormHelperText-root': {
      color: getColors()?.azulClaro,
    },

    '& label.Mui-focused': {
      color: getColors()?.branco,
      opacity: 1,
    },
    '& .MuiOutlinedInput-input': {
      caretColor: getColors()?.azulClaro,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: getColors()?.branco,
    },

    '& .MuiInputAdornment-positionEnd': {
      color: getColors()?.azulClaro,
      opacity: 0.3,
      padding: 5,
    },

    '& .MuiInputAdornment-positionEnd .MuiIconButton-root': {
      padding: 0,
      color: getColors()?.azulClaro,
    },

    '& .MuiOutlinedInput-root': {
      color: getColors()?.branco,
      '& fieldset': {
        borderColor: getColors()?.branco,
        opacity: 0.2,
        borderWidth: 2,
        color: getColors()?.branco,
        borderRadius: 5,
      },

      '& placeholder': {
        color: 'red',
      },
      '&:hover fieldset': {
        borderColor: getColors()?.branco,
      },
      '&.Mui-focused': {
        '& .MuiInputAdornment-positionEnd': {
          color: getColors()?.azulClaro,
          opacity: 1,
        },
        '& .MuiInputAdornment-positionEnd .MuiIconButton-root': {
          color: getColors()?.azulClaro,
          opacity: 1,
        },
      },
      '&.Mui-focused fieldset': {
        borderColor: getColors()?.branco,
        opacity: 1,
      },
    },
  },
})(TextField);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
  }),
);
// Material-UI
