import api from '@http/api';

export interface IAproveCorrespDevolvida {
  id: number[];
}

class ComponentCorrespondenciaServices {
  public async AproveCorrespDevolvida({ id }: IAproveCorrespDevolvida) {
    return await api
      .post('/api/CorrespondenciaDevolvida/AprovarListaCorrespondeciaDevolvida', {
        id,
      })

      .then(response => {
        return {
          error: false,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default ComponentCorrespondenciaServices;
