import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';

import { Empty } from 'antd';
import '@styles/AntStyles.css';

import {
  DivLoading,
  SpinnerLoadingMaterial,
  StyledTableCell,
  StyledTableRow,
  useStyles,
  Container,
  DivVoid,
} from '../TableActions/styles';

interface ITableProps {
  data: object[];
  columns: Object[];
  loading: Boolean;
  Actions?: IActionObj[];
  Selected?: Function;
  ItemBottom?: React.ReactNode;
  FieldsBold?: string[];

}

interface IActionObj {
  Button: Function;
}

const TableActions: React.FC<ITableProps> = ({
  data,
  columns,
  loading,
  Actions,
  Selected,
  ItemBottom,
  FieldsBold,
}) => {
  const classes = useStyles();
  const keys = Object.keys(data[0] || data);
  const alterColumns = columns[0] || {};

  const Head = ({ keys, alterColumns }: any) => {
    return (
      <>
        {Selected && <StyledTableCell align="center">Selecionar</StyledTableCell>}

        {keys.map((key: any) => (
          <StyledTableCell key={key} align="center">
            {alterColumns[key] || key}
          </StyledTableCell>
        ))}

        {Actions && <StyledTableCell align="center">Ação</StyledTableCell>}
      </>
    );
  };

  const Rows = ({ row }: any) => {
    const colunas = Object.keys(row);

    return (
      <StyledTableRow>
        {Selected && (
          <StyledTableCell>
            <div>{Selected(row)}</div>
          </StyledTableCell>
        )}

        {colunas.map(coluna => {
          return (
            <StyledTableCell key={coluna}>
              {FieldsBold?.includes(coluna) ? (
                  <strong >
                    {row[coluna]}
                  </strong>
              ) : (
                  <p>
                    {row[coluna]}
                  </p>
              )}
            </StyledTableCell>
          );
        })}

        {Actions && (
          <StyledTableCell style={{ display: 'flex' }}>
            {Actions?.map((Item, index) => (
              <div key={index}>{Item.Button(row)}</div>
            ))}
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <Container>
      {loading && (
        <DivLoading>
          <SpinnerLoadingMaterial />
        </DivLoading>
      )}

      {!loading && data.length <= 0 && (
        <DivVoid>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum dado encontrado"
          />
        </DivVoid>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader size="medium">
          {!loading && (
            <TableHead>
              <TableRow>
                <Head keys={keys} alterColumns={alterColumns} />
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, index: number) => {
              return <Rows key={index} row={row} />;
            })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>
      {ItemBottom}
    </Container>
  );
};

export default TableActions;
