import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Grid } from '@material-ui/core';

import { useStyles, StyledMenuItem } from './styles';
import ItemMenu from './itemMenu';
import {
  PersonOutlineOutlined,
  // NotificationsActiveOutlined,
} from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';

import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import CreateIcon from '@material-ui/icons/Create';
import ListItemText from '@material-ui/core/ListItemText';

import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';

import ListMenu from '../ListMenuSelected';
import { useAuth } from '@hooks/AuthenticateContext';

import SelectEntidade from '@components/Amex/SelectEntidade';

import {
  IRouteMenu,
  routesAdminMenu,
} from '@router/routes/Cliente.admin.config.routes';
import { routesOperacionalMenu } from '@router/routes/Cliente.operacional.config.routes';
import { routesAdminMenu as routesAMEXAdminMenu } from '@router/routes/Amex.admin.config.routes';

import Teste, { Switch, useDarkreader } from 'react-darkreader';

const StyledMenu = withStyles({
  paper: {
    padding: '0px !important',
    border: '1px solid #F1F1F1',
    borderRadius: 0,
    width: 100,
    background: '#F1F1F1',
    '& .MuiListItem-root.MuiListItem-root:hover': { background: '#000023' },
    '& .MuiList-root': {
      padding: 0,
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    {...props}
  />
));

const MenuMobile: React.FC = ({ children }) => {
  const [isDark, { toggle }] = useDarkreader(
    localStorage.getItem('@amex:DarkMode') == 'true' ? true : false,
    {
      brightness: 140,
      contrast: 100,
      sepia: 0,
      grayscale: 10,
    },
  );
  const history = useHistory();

  const { user, signOut } = useAuth();
  const divRef = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [routes, setRoutes] = React.useState<IRouteMenu[]>();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const [value, setValue] = React.useState<any>(0);
  const location = useLocation();

  const handleChangeActiveAction = (value: number) => {
    setValue(value);
  };

  const handleClick = () => {
    setAnchorEl(divRef.current);
    handleChangeActiveAction(5);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setOpen(false);
  }, [location]);

  useEffect(() => {
    if (user.role === 'ADM CLIENTE I') {
      setRoutes(routesAdminMenu);
    } else if (user.role === 'OPERACIONAL CLIENTE I' && user.entidade_id === 7) {
      setRoutes(routesOperacionalMenu);
    } else if (user.role === 'ADM AMEX') {
      setRoutes(routesAMEXAdminMenu);
    }
  }, [user]);

  const handleEditUser = () => {
    handleChangeActiveAction(0);
    history.push('/usuario/editar');
  };

  return (
    <>
      {children}

      <Drawer
        className={classes.drawer}
        style={{ bottom: 55 }}
        open={open}
        onClose={() => setOpen(open ? false : true)}
      >
        <ListMenu isDesktop={false} menuOpen={open} />
      </Drawer>
      {user.role === 'ADM AMEX' && (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{
            bottom: 55,
            position: 'fixed',
            width: '100%',
            zIndex: 998,
            background: '#f1f1f1',
          }}
        >
          <SelectEntidade />
        </Grid>
      )}

      <div
        style={{
          position: 'absolute',
          bottom: 55,
          right: 0,
        }}
        ref={divRef}
      ></div>
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{
          width: '100%',
          background: '#f1f1f1',
          height: 44,
          zIndex: 1000,
          bottom: 0,
          position: 'fixed',
        }}
      >
        <BottomNavigation value={value} className={classes.root}>
          <BottomNavigationAction
            label="Menu"
            value="Menu"
            selected={open}
            icon={<MenuIcon />}
            onClick={() => setOpen(open ? false : true)}
          />

          {routes
            ?.filter(
              route =>
                route.menu === 'header' && route.roles.includes(user.role),
            )
            .map(routeMenuHeader => (
              <ItemMenu
                key={routeMenuHeader.index}
                Icon={routeMenuHeader.Icon as React.ReactElement}
                label={routeMenuHeader.label ? routeMenuHeader.label : ''}
                path={routeMenuHeader.path ? routeMenuHeader.path : ''}
                exact
              />
            ))}

          <ItemMenu
            label={user.nome}
            onClick={() => handleClick()}
            Icon={(<PersonOutlineOutlined />) as React.ReactElement}
            path="/usuario"
            exact={false}
          />

          <StyledMenu
            anchorEl={divRef.current}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem>
              <Switch
                checked={isDark}
                onChange={isDarkNew => {
                  localStorage.setItem('@amex:DarkMode', `${isDarkNew}`);
                  toggle();
                }}
                styling="fluent"
              />
            </StyledMenuItem>

            <StyledMenuItem onClick={handleEditUser}>
              <ListItemText primary="Editar" />
              <ListItemIcon>
                <CreateIcon fontSize="small" />
              </ListItemIcon>
            </StyledMenuItem>

            <StyledMenuItem onClick={signOut}>
              <ListItemText primary="Sairrrrrr" />
              <ListItemIcon>
                <ExitToAppOutlined fontSize="small" />
              </ListItemIcon>
            </StyledMenuItem>
          </StyledMenu>
        </BottomNavigation>
      </Grid>
    </>
  );
};

export default MenuMobile;
