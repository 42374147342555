import React, { useEffect, useState, useRef } from 'react';

import { Grid, MenuItem, Button } from '@material-ui/core';

import { Form, Input, Select as SelectAntd, Spin } from 'antd';
import { SaveOutlined, CloseOutlined } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ButtonComponent from '@components/Shared/Buttons/Button';

import Service, { IRecuseSolicitacao } from './services';

interface IProps {
  perfil_id: number;
  finishCreate?: Function;
  ids_solicitacao: any;
}

const SolicitacaoComponent: React.FC<IProps> = ({
  perfil_id,
  finishCreate,
  ids_solicitacao,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formRef] = Form.useForm();

  const [loadingCreate, setLoadingCreate] = useState(false);

  const [tipoPerfilSelected, setTipoPerfilSelected] = useState<number>(0);

  const [loading, setLoading] = useState(true);
  const [loadingRecuseSolicitacao, setLoadingRecuseSolicitacao] =
    useState(false);

  useEffect(() => {
    const init = async () => {
      const service = new Service();
    };
    init();
    setLoading(false);
  }, []);

  const recusaSolicitacao = async () => {
    const componentRecuseSolicitacaoServices = new Service();
    setLoadingRecuseSolicitacao(true);

    let id_solicitacao: number[] = [];

    id_solicitacao = ids_solicitacao;

    const recuseSolicitacaoResponse =
      await componentRecuseSolicitacaoServices.RecuseSolicitacao({
        id_solicitacao,
      });

    if (!recuseSolicitacaoResponse.error) {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Solicitação recusada com sucesso!`, {
        title: 'Solicitação recusada com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });

      if (finishCreate) {
        setLoadingCreate(false);
        await finishCreate();
      }
    } else {
      setLoadingCreate(false);
      setLoading(false);
      enqueueSnackbar(`Ocorreu um erro`, {
        title: 'Ocorreu um erro ',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
    }
    window.location.reload();
  };

  return (
    <Grid container>
      <Grid container item sm={12}>
        <Spin
          size="large"
          spinning={loadingRecuseSolicitacao}
          tip={loadingRecuseSolicitacao ? 'Enviando dados...' : ''}
        >
          <Grid
            container
            item
            xs={12}
            md={12}
            justify="center"
            alignItems="center"
          >
            <Form form={formRef} style={{ width: '100%' }} layout="vertical">
              <Grid item lg={12} xs={12} justify="center" alignItems="center">
              Clique em confirmar para recusar a(s) solicitação(ões) selecionada(s).
              </Grid>

              <Grid item lg={12} xs={12}></Grid>

              <Grid container justify="center" alignItems="center">
                <Grid item sm={6} xs={12} style={{ paddingTop: 10 }}>
                  <ButtonComponent
                    color="tertiary"
                    fullWidth
                    onClick={recusaSolicitacao}
                  >
                    Confirmar
                  </ButtonComponent>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Spin>
      </Grid>
    </Grid>
  );
};

export default SolicitacaoComponent;
