import api from '@http/api';
import Financeiro from '.';

export interface IRequestNameFunction {
  data: {
    example: string;
  };
}

interface IResponseNameFunction {
  error: boolean;
  response?: any;
}

interface IResponseAxiosNameFunction {
  example: any;
}

export interface IFormaContribuicao {
  id: string | number;
  descricao: string;
  data_cadastro: string;
  status_ativo: boolean;
}

export interface ITipoRemessa {
  id: number;
  codigo_movimento: string;
  descricao: string;
  tipo_registro: number;
}

export interface IConvenio {
  id: number;
  numero_convenio: string;
  nome_cedente: number | string | null;
  numero_agencia: number | string | null;
  digito_agencia: number | string | null;
  numero_conta: number | string | null;
  digito_cedente: number | string | null;
  id_banco: number | string;
  descricao: string;
}

export interface IDebitoResultTable {
  data: string;
  total_pessoas: number | string;
  valor_total: string;
}

export interface IDebitos {
  modelo: IDebitoResultTable[];
  id: number;
  quantidade_item_pagina: number;
  total_pagina: number;
  total_itens: number;
  pagina_anterior_existe: boolean;
  pagina_seguinte_existe: boolean;
  quantidade_cadastros_atualizar: number;
}

class Services {
  public async nameFunction({
    data,
  }: IRequestNameFunction): Promise<IResponseNameFunction> {
    return await api
      .get<IResponseAxiosNameFunction[]>('api/...')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }

  public async loadFormaContribuicao() {
    return await api
      .get<IFormaContribuicao[]>('/api/FormaContribuicao/GetFormasContribuicao')
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetTipoRemessa(formaContribuicaoID: string | number) {
    return await api
      .get<ITipoRemessa[]>(
        `/api/Financeiro/GetFinanceiroCodMovimentoByFormaContribuicao?formaContribuicaoId=${formaContribuicaoID}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetConvenios(formaContribuicaoID: string | number) {
    return await api
      .get<IConvenio[]>(
        `api/Financeiro/GetFinanceiroConveniosByFormaContribuicao?formaContribuicaoId=${formaContribuicaoID}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: [],
        };
      });
  }

  public async GetListDebitosAgrupados(
    pageNumber: number,
    pageSize: number,
    convenioId: string | number,
    codigoMovimentoId: string | number,
    tipoDados: string,
    dataInicio: string,
    dataFim: string,
  ) {
    return await api
      .get<IDebitos>(
        `/api/Financeiro/GetFinanceiroAgrupadosPaginados?pageNumber=${pageNumber}&pageSize=${pageSize}&convenioId=${convenioId}&codigoMovimentoId=${codigoMovimentoId}&dataInicio=${dataInicio}&dataFim=${dataFim}&tipoDados=${tipoDados}`,
      )
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

  public async GerarRemessaDebito(
    id_convenio: string | number,
    id_codigo_movimento: string | number,
    datas_selecionadas: string[],
    tipo_debito: string,
    ambiente: string,
  ) {
    return await api

      .post('api/Financeiro/RemessaDebito', {
        id_codigo_movimento: Number(id_codigo_movimento),
        id_convenio,
        datas_selecionadas,
        tipo_debito,
        ambiente,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }

  public async GerarRemessaBoleto(
    id_convenio: string | number,
    id_codigo_movimento: string | number,
    datas_selecionadas: string[],
    tipo_debito: string,
    ambiente: string,
  ) {
    return await api

      .post('api/Financeiro/RemessaBoleto', {
        id_codigo_movimento: Number(id_codigo_movimento),
        id_convenio,
        datas_selecionadas,
        tipo_debito,
        ambiente,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
          response: null,
        };
      });
  }
}

export default Services;
