import React, { useCallback, useEffect, useState } from 'react';

// import { Container } from './styles';
import { Grid, IconButton } from '@material-ui/core';

import { TitlePage } from '@styles/styles.global';
import Button from '@components/Shared/Buttons/Button';
import Modal from '@components/Shared/Modal/ModalPure';
import DataPicker from '@components/Shared/DatePicker';

import { Form, Input } from 'antd';
import { addDays } from 'date-fns';
import Services from './services';
import { useSnackbar } from 'notistack';
import { AddOutlined, SaveOutlined, Visibility } from '@material-ui/icons';
import TableActions from '@src/components/Shared/Tables/TableActions';

const proximoMes = addDays(new Date(), +30);

const columnsLabel = {
  id: 'ID',
  descricao: 'Campanha',
  data_fim_campanha: 'Encerra em:',
  quantidade_estrategias: 'Estratégias',
};

interface ICampanha {
  id: number;
  descricao: string;
  estrategias: number;
  data_fim_campanha: string;
}

const ListCampanhas: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ICampanha[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [, setTotalItems] = useState(0);

  const [showcreateCampanha, setShowCreateCampanha] = useState(false);
  const [date, setDate] = useState([new Date(), proximoMes]);
  const { enqueueSnackbar } = useSnackbar();

  const HandleCreateCampanha = () => {
    setShowCreateCampanha(!showcreateCampanha);
  };

  const setValuesOrError = (error: boolean, response: any) => {
    if (!error && response) {
      setTotalItems(response.total_itens);
      setTotalPages(response.total_pagina);
      setData(response.modelo);
      setLoading(false);
    } else {
      enqueueSnackbar('Ocorreu um erro.', {
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setLoading(false);
    }
  };

  async function createCampanha(values: any) {
    const services = new Services();
    const data_fim_campanha = date[1];
    const data_inicio_campanha = date[0];
    const descricao = values.CampanhaNome;
    const status_ativo = 1;
    const { error } = await services.createCampanha({
      data_fim_campanha,
      data_inicio_campanha,
      descricao,
      status_ativo,
    });

    if (!error) {
      enqueueSnackbar('Campanha criada com sucesso!', {
        title: 'Campanha criada com sucesso!',
        variant: 'success',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      setShowCreateCampanha(false);
      firstLoad();
      return true;
    } else {
      enqueueSnackbar('Erro ao criar campanha!', {
        title: 'Erro ao criar campanha!',
        variant: 'error',
        anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      });
      return false;
    }
  }

  function setPagination(page: number, itemsPage: number) {
    if (itemsPerPage !== itemsPage) {
      page = 1;
    }

    setItemsPerPage(itemsPage);
    onChangeSubmit(page, itemsPage);
  }

  async function onChangeSubmit(page: number, itemsPerPage: number) {
    setLoading(true);
    setPage(page);
    const services = new Services();

    const { error, response } = await services.getCampanhas({
      pageNumber: page,
      pageSize: itemsPerPage,
    });
    setValuesOrError(error, response);
  }

  const firstLoad = useCallback(async () => {
    const services = new Services();
    setLoading(true);

    const { error, response } = await services.getCampanhas({
      pageNumber: 1,
      pageSize: 10,
    });
    setValuesOrError(error, response);
  }, []);

  useEffect(() => {
    firstLoad();
    window.document.title = 'CRM - Lista de Campanhas';
  }, [firstLoad]);

  return (
    <>
      <Grid container>
        <Grid
          item
          sm={7}
          xs={12}
          style={{ paddingLeft: 10, alignSelf: 'center' }}
        >
          <TitlePage style={{ margin: 0 }}>Campanha</TitlePage>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item sm={2} xs={6} style={{ alignSelf: 'center', padding: 10 }}>
          <Button color="secondary" fullWidth>
            Em andamento
          </Button>
        </Grid>
        <Grid item sm={2} xs={6} style={{ alignSelf: 'center', padding: 10 }}>
          <Button color="default" fullWidth>
            Finalizadas
          </Button>
        </Grid>
      </Grid> */}
      <Grid container>
        <Grid item xs={12} lg={9} style={{ padding: 10 }}>
          <TableActions
            loading={loading}
            columns={[columnsLabel]}
            data={data}
            itemsPerPage={itemsPerPage}
            totalPages={totalPages}
            pageCurrent={page}
            fetchData={setPagination}
            Actions={[
              {
                Button: (row: ICampanha) => {
                  return (
                    <IconButton
                      onClick={() => window.open(`/campanha/${row.id}/`)}
                    >
                      <Visibility />
                    </IconButton>
                  );
                },
              },
            ]}
          />
        </Grid>
      </Grid>

      {/* Modal Create Campanha*/}

    </>
  );
};

export default ListCampanhas;
