import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@hooks/AuthenticateContext';

import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import InvisibleContent from '../InvisibleContent';

import { Container, StyledMenuItem, } from './styles';

import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined';
import CreateIcon from '@material-ui/icons/Create';
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import Teste, { Switch, useDarkreader } from 'react-darkreader';

const StyledMenu = withStyles({
  paper: {
    padding: '0px !important',
    border: '1px solid #F1F1F1',
    borderRadius: 0,
    width: 150,
    background: '#F1F1F1',
    '& .MuiListItem-root.MuiListItem-root:hover': { background: '#00000023' },
    '& .MuiList-root': {
      padding: 0,
    },
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const HeaderUser: React.FC = () => {
  const [isDark, { toggle, collectCSS }] = useDarkreader(
    localStorage.getItem('@amex:DarkMode') == 'true' ? true : false,
    {
      brightness: 140,
      contrast: 100,
      sepia: 0,
      grayscale: 10,
    },
  );

  const history = useHistory();

  const { signOut, user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditUser = () => {
    history.push('/usuario/editar');
  };

  return (
    <>
      <Container
        label={user.nomeFantasia}
        onClick={handleClick}
        icon={<PersonIcon />}
        selected={Boolean(anchorEl)}
        style={{
          background: Boolean(anchorEl) ? '#C7C7C7' : '#f1f1f1',
          fontWeight: Boolean(anchorEl) ? 'bold' : 'inherit',
        }}
      />

      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <Switch
            checked={isDark}
            onChange={isDarkNew => {
              localStorage.setItem('@amex:DarkMode', `${isDarkNew}`);
              toggle();
            }}
            styling="fluent"
          />

          {/* <Teste
            onChange={isDark => {
              localStorage.setItem('@amex:DarkMode', isDark.toString());
              // localStorage.getItem('@amex:DarkMode') == 'true' ? true : false
            }}
            defaultDarken
            theme={{
              brightness: 135,
              contrast: 100,
              sepia: 0,
              grayscale: 0,
            }}
          /> */}
        </StyledMenuItem>

        <StyledMenuItem onClick={handleEditUser}>
          <ListItemText primary="Editar" />
          <ListItemIcon>
            <CreateIcon fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>

        <StyledMenuItem onClick={signOut}>
          <ListItemText primary="Sair" />
          <ListItemIcon>
            <ExitToAppOutlined fontSize="small" />
          </ListItemIcon>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default HeaderUser;
