import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Pagination from '@material-ui/lab/Pagination';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CountDown from '@components/Shared/CountDown';

import { Empty } from 'antd';
import '@styles/AntStyles.css';

import {
  DivLoading,
  SpinnerLoadingMaterial,
  StyledTableCell,
  StyledTableRow,
  useStyles,
  ItemPaginate,
  BottomPagination,
  BootstrapInput,
  Container,
  DivVoid,
} from './styles';

import { IconButton, Grid } from '@material-ui/core';
// import ButtonExcel from '@components/Shared/ExportExcel';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

interface ITableProps {
  data: object[];
  columns: Object[];
  loading: Boolean;
  itemsPerPage: number;
  fetchData: Function;
  pageCurrent: number;
  totalPages: number;
  Actions?: IActionObj[];
  Selected?: Function;
  FieldsBold?: string[];
}

interface IActionObj {
  Button: Function;
}

const TableActions: React.FC<ITableProps> = ({
  data,
  columns,
  loading,
  itemsPerPage,
  fetchData,
  pageCurrent,
  totalPages,
  Actions,
  Selected,
  FieldsBold,
}) => {
  const classes = useStyles();
  const keys = Object.keys(data[0] || data);
  const alterColumns = columns[0] || {};

  const Head = ({ keys, alterColumns }: any) => {
    return (
      <>
        {Selected && <StyledTableCell>Selecionar</StyledTableCell>}

        {keys.map((key: any) => (
          <StyledTableCell key={key}>
            {alterColumns[key] || key}
          </StyledTableCell>
        ))}

        {Actions && <StyledTableCell align="center">Opções</StyledTableCell>}
      </>
    );
  };

  const Rows = ({ row }: any) => {
    const colunas = Object.keys(row);

    return (
      <StyledTableRow>
        {Selected && (
          <StyledTableCell>
            <div>{Selected(row)}</div>
          </StyledTableCell>
        )}

        {colunas.map(coluna => {
          return (
            <StyledTableCell key={coluna}>
              {FieldsBold?.includes(coluna) ? (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <strong
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </strong>
                </div>
              ) : (
                <div style={{ maxHeight: 46, maxWidth: 250 }}>
                  <p
                    style={{
                      maxWidth: '50ch',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {row[coluna]}
                  </p>
                </div>
              )}
            </StyledTableCell>
          );
        })}

        {Actions && (
          <StyledTableCell
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {Actions?.map((Item, index) => (
              <div key={index}>{Item.Button(row)}</div>
            ))}
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  const handleChangePage = async (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    await fetchData(value, itemsPerPage);
  };

  const handleChangeItemsPerPage = async (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as number;
    await fetchData(pageCurrent, value);
  };

  const reloadTable = async () => {
    await fetchData(pageCurrent, itemsPerPage);
  };

  return (
    <Container>
      <Grid item lg={12} xs={12}>
        <CountDown
          minutesDefault={1}
          changeFunction={reloadTable}
          title="Tempo para atualização dos dados"
        />
      </Grid>

      {/* <div
        style={{ height: 'auto', display: 'flex', justifyContent: 'flex-end ' }}
      >
        <ButtonExcel title="Tabela" data={data} keys={keys} labels={columns[0]}>
          <IconButton>
            <CloudDownloadIcon fontSize={'large'} />
          </IconButton>
        </ButtonExcel>
      </div> */}

      {loading && (
        <DivLoading>
          <SpinnerLoadingMaterial />
        </DivLoading>
      )}

      {!loading && data.length <= 0 && (
        <DivVoid>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Nenhum dado encontrado"
          />
        </DivVoid>
      )}

      <TableContainer className={classes.container}>
        <Table className={classes.table} stickyHeader size="medium">
          {!loading && (
            <TableHead>
              <TableRow>
                <Head keys={keys} alterColumns={alterColumns} />
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {data.map((row: any, index: number) => {
              return <Rows key={index} row={row} />;
            })}
          </TableBody>
          <TableFooter></TableFooter>
        </Table>
      </TableContainer>

      <BottomPagination>
        <ItemPaginate>
          <Pagination
            size="small"
            count={totalPages}
            page={pageCurrent}
            color="primary"
            onChange={handleChangePage}
          />
        </ItemPaginate>
        <ItemPaginate>
          <p>linhas por página:</p>

          <Select
            value={itemsPerPage}
            onChange={handleChangeItemsPerPage}
            defaultValue={itemsPerPage}
            input={<BootstrapInput />}
          >
            {/* <MenuItem selected value={5}>
              5
            </MenuItem> */}
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </ItemPaginate>
      </BottomPagination>
    </Container>
  );
};

export default TableActions;
