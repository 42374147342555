import api from '@http/api';
import moment from 'moment';

export interface IEstrategias {
  estrategia_id: number;
  estrategia_descricao: string;
  estrategia_data_criacao: any;
}
export interface ICampanha {
  campanha_id: number;
  campanha_descricao: string;
  estrategias: IEstrategias[];
}

interface IResponse {
  error: boolean;
  response?: ICampanha[];
}

interface IRequest {
  data_inicio_campanhas_estrategias: any;
  data_fim_campanhas_estrategias: any;
}

class DetalhesCampanhasServices {
  public async getCampanhas({
    data_inicio_campanhas_estrategias,
    data_fim_campanhas_estrategias,
  }: IRequest): Promise<IResponse> {
    data_inicio_campanhas_estrategias = moment
      .utc(new Date(data_inicio_campanhas_estrategias))
      .format('YYYY-MM-DD');

    data_fim_campanhas_estrategias = moment
      .utc(new Date(data_fim_campanhas_estrategias))
      .format('YYYY-MM-DD');
    return await api
      .post(`/api/Estrategia/FindCampanhaEstrategiaByPeriodo`, {
        data_inicio_campanhas_estrategias,
        data_fim_campanhas_estrategias,
      })
      .then(response => {
        return {
          error: false,
          response: response.data,
        };
      })
      .catch(error => {
        return {
          error: true,
        };
      });
  }
}

export default DetalhesCampanhasServices;
