import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';

import { useAuth } from '@hooks/AuthenticateContext';
import GetErrors from '@utils/GetErros';
import { getColors } from '@styles/colorsTheme';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { IconButton, InputAdornment, Grid } from '@material-ui/core';
import { VisibilityOff, Visibility, Person } from '@material-ui/icons';

import InputValidationOutlined from '@components/Shared/Inputs/InputValidationOutlined';
import ButtonComponent from '@components/Shared/Buttons/Button';
import LogoCLickDoBem from '@assets/Login/sociallis-logo-hor2.png';
import LogoAme from '@assets/Login/logo_amex_branco.svg';
import { Container, Content, LinkRoute } from './styles';

interface SignInFormData {
  email: string;
  senha: string;
}

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Email obrigatório')
    .email('Digite um e-mail válido'),
  senha: Yup.string().required('Senha obrigatória'),
});

const Login: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { signIn } = useAuth();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = useCallback(
    async (data: SignInFormData) => {
      try {
        setLoading(true);

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn(data);
        enqueueSnackbar('Bem-vindo', {
          variant: 'success',
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = GetErrors(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
          return;
        }

        enqueueSnackbar('E-mail ou senha inválidos', {
          title: 'Erro de autenticação',
          variant: 'error',
          // persist: true,
          // transitionDuration: 500,
          anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
        });
        setLoading(false);
      }
    },
    [signIn, enqueueSnackbar],
  );

  //Bloqueio inspecionar
  useEffect(() => {

      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'F12') {
          event.preventDefault();
          enqueueSnackbar('F12 está desativado.', {
            variant: 'error',
            anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
          });
          //alert('F12 está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'I') {
          event.preventDefault();
          alert('Ctrl+Shift+I está desativado.');
        }
        if (event.ctrlKey && event.shiftKey && event.key === 'J') {
          event.preventDefault();
          alert('Ctrl+Shift+J está desativado.');
        }
        if (event.ctrlKey && event.key === 'U') {
          event.preventDefault();
          alert('Ctrl+U está desativado.');
        }
      };

      const handleContextMenu = (event: MouseEvent) => {
        event.preventDefault();
        alert('O botão direito do mouse está desativado.');
      };

      document.addEventListener('keydown', handleKeyDown);
      document.addEventListener('contextmenu', handleContextMenu);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
        document.removeEventListener('contextmenu', handleContextMenu);
      };

  }, []);

  return (
    <Container>
      <Content>
        <Grid container direction="row" justify="center" alignItems="center">
          <img src={LogoCLickDoBem} style={{ width: 250 }} />
        </Grid>
        <Form ref={formRef} onSubmit={handleLogin}>
          <InputValidationOutlined
            name="email"
            label="E-mail"
            EndAdornment={
              <InputAdornment position="end">
                <Person fontSize="small" />
              </InputAdornment>
            }
          />
          <InputValidationOutlined
            label="Senha"
            name="senha"
            type={showPassword ? 'text' : 'password'}
            EndAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword}>
                  {showPassword ? (
                    <Visibility fontSize="small" />
                  ) : (
                    <VisibilityOff fontSize="small" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />

          <ButtonComponent
            disabled={loading}
            loading={loading}
            color="primary"
            type="submit"
            style={{ width: '100%', height: 43, marginTop: 5 }}
          >
            Login
          </ButtonComponent>

          {/* <LinkRoute to="/PasswordReset">Esqueceu a senha?</LinkRoute> */}
        </Form>

          {/*
        <Grid container justify="center" alignItems="center">
          <Grid
            item
            lg={12}
            style={{
              color: getColors()?.branco,
              textAlign: 'center',
              opacity: 0.5,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 10,
            }}
            justify="center"
            alignItems="center"
          >
            CRM desenvolvido por:{' '}
          </Grid>
          <Grid
            item
            lg={12}
            justify="center"
            alignItems="center"
            style={{
              color: getColors()?.branco,
              textAlign: 'center',
              padding: 10,
            }}
          >
            <img
              src={LogoAme}
              // style={{ opacity: 0.2 }}
              width="150"
              height="40"
              alt="Logo Amex"
              style={{ opacity: 0.5 }}
            />
          </Grid>
        </Grid>
        */}
      </Content>
    </Container>
  );
};

export default Login;
