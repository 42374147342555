import React from 'react';

import { AuthProvider } from './AuthenticateContext';
import { EntidadeProvider } from './EntidadeContext';

import { SnackbarProvider } from 'notistack';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <EntidadeProvider>
      <SnackbarProvider maxSnack={4}>{children}</SnackbarProvider>
    </EntidadeProvider>
  </AuthProvider>
);

export default AppProvider;
